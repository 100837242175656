import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Dialog, Tooltip, Typography } from "@mui/material";
import Translator from "../../components/Translator";

import useStores from "~/hooks";

import FacilityRender from "../facilities/FacilityRender";
import { Delete } from "@mui/icons-material";
import Form from "../../components/Form";

import { FacilityScanningPlan } from "~/modules/scanning/components";

const SchedulePlan = observer(({ plan, facility, height, top, canDeletePlan }) => {
  const { contractStore, rootStore } = useStores();
  // console.log(plan);
  const [open, setOpen] = useState(false);
  const [isModalPending, setModalPending] = useState(false);

  const facilityId = plan.facility.id;

  // TODO: Зачем так если можно передать план сверху?
  // const plan = useMemo(() => {
  //   return contractStore.getServiceByUid(planId);
  // }, [planId, contractStore]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        await contractStore.deleteScanPlanByIdAsync(data);
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setModalPending(false);
      setOpen(false);
    },
    [contractStore, rootStore]
  );

  const onOpenDialogDelete = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setOpen(false);
    },
    [setOpen]
  );

  const color = useMemo(() => {
    let color = "gray";
    if (plan) {
      if (plan.isStarted) {
        color = "orange";
      }
      if (plan.isFinished) {
        color = "blue";
      }
      // plan.result.rawCloud
      if (!!plan.result?.rawCloud) {
        color = "blue";
      }
      // plan.result.linkedCloud
      if (!!plan.result?.linkedCloud) {
        color = "green";
      }
    }
    return color;
  }, [plan]);

  // const isOutline = useMemo(() => {
  //   return plan && ((plan.isStarted && !plan.isFinished) || (plan.isFinished && !plan.hasRaw && !plan.hasLinked));
  // }, [plan]);

  const isOutline = false;

  if (!plan) {
    return null;
  }

  return (
    <Box
  sx={{
    position: "absolute",
    height: `${height}rem`,
    top: `${top}rem`,
    width: "100%",
    display: "flex",
    flexShrink: 0,
    alignItems: "flex-start", // Меняем на flex-start для выравнивания сверху
    justifyContent: "center",
    overflow: "hidden",
    margin: 0, // Убедитесь, что отступов нет
    padding: 0, // Убираем лишние отступы
  }}
>

      <Box
        sx={{
          position: "absolute",
          top: "0.125rem",
          left: "0.125rem",
          right: "0.125rem",
          bottom: "0.125rem",
          p: "0.25rem",
          display: "flex",
          borderRadius: "0.25rem",
          border: "0.0625rem solid transparent",
          backgroundColor: (theme) => {
            return isOutline ? theme.palette.background.paper : theme.palette[color].light;
          },
          borderColor: (theme) => {
            return theme.palette[color].light;
          },
          color: (theme) => {
            return isOutline ? theme.palette[color].light : theme.palette[color].contrastText;
          },
          alignItems: "stretch",
          justifyContent: "flex-start",
          overflow: "hidden",
        }}
      >
        {/* {"Я план сканирования"} */}
        {/* <FacilityRender color={isOutline ? color : undefined} isSmall={true} facility={plan.facility} /> */}
        <FacilityScanningPlan 
          plan={plan} 
          facility={facility} 
          start={plan.start} 
          finish={plan.finish} 
        />      
        {canDeletePlan && <Box sx={{ flex: 1 }} />}
        {canDeletePlan && (
          <Tooltip
            key={"tooltip"}
            arrow
            title={
              <Typography variant="body">
                <Translator text={"Delete plan"} />
              </Typography>
            }
          >
            <Delete
              className="delete-icon-holder"
              onClick={onOpenDialogDelete}
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
                ml: "0.25rem",
                mr: "0.25rem",
                flexShrink: 0,
                ":hover": {
                  color: (theme) => {
                    return theme.palette.error.light;
                  },
                },
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Dialog maxWidth={"xs"} open={open} onClose={onCloseDialog}>
        <Form
          isPending={isModalPending}
          config={{
            submitText: "Submit",
            cancelText: "Cancel",
            formTitle: "Delete plan",
            formText: "Are you shure you want to delete plan?",
            fields: [
              {
                name: "planId",
                type: "hidden",
                viewConfig: "input",
                initialValue: plan.id,
              },
              {
                name: "planUid",
                type: "hidden",
                viewConfig: "input",
                initialValue: plan.uid,
              },
            ],
          }}
          onSubmit={onSubmit}
          onCancel={onCloseDialog}
        />
      </Dialog>
    </Box>
  );
});

export default SchedulePlan;
