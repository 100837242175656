import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Fab, Zoom, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";

import useStores from "~/hooks";
import { ContractsBoardColumn, CreateContractDialog } from "../components";
import { Preloader } from "~/components";

/**
 * Доска проектов в работе.
 */
const ContractsBoard = observer(({ searchBy, refreshPls }) => {
  const { contractStore, branchStore } = useStores();
  const { branch } = branchStore;

  const [isPending, setIsPending] = useState(false);
  const [isCreateContractDialogVisible, setIsCreateContractDialogVisible] = useState(false);

  const fetchData = useCallback(async () => {
    if (!!branch) {
      setIsPending(true);
      await contractStore.getActiveContracts(branch.id, searchBy);
      setIsPending(false);
    }
  }, [branch, contractStore, searchBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshPls]);

  //
  const openCreateContractDialog = useCallback(() => {
    setIsCreateContractDialogVisible(true);
  }, [setIsCreateContractDialogVisible]);

  const { activeStatuses, groupedContracts } = contractStore;

  const columns = useMemo(() => {
    const columns = [];
    activeStatuses.forEach((status) => {
      columns.push(
        <ContractsBoardColumn
          key={status.id}
          status={status}
          searchBy={searchBy}
          contracts={groupedContracts[status.id] || []}
        />
      );
    });
    return columns;
  }, [activeStatuses, searchBy, groupedContracts]);

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  if (!branch) return <Preloader isPending={true} label="Loading branch" />;
  if (isPending) return <Preloader isPending={true} label="Loading active contracts" />;

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flex: 1,
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      {columns}
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{ transitionDelay: `${transitionDuration.exit}ms` }}
        unmountOnExit={true}
      >
        <Fab
          onClick={() => openCreateContractDialog()}
          key="fab"
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
          }}
          color="primary"
          aria-label="Add"
        >
          <Add />
        </Fab>
      </Zoom>
      <CreateContractDialog isVisible={isCreateContractDialogVisible} setIsVisible={setIsCreateContractDialogVisible} />
    </Box>
  );
});

export default ContractsBoard;
