import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import useStores from "~/hooks";
import { FormDialog } from "~/components/dialogs";
import Notify from "~/common/components/Notify";

const PaymentDialog = observer(({ open, onClose, invoice }) => {
  const { invoiceStore, rootStore } = useStores();
  const [isPending, setPending] = useState(false);
  const [isNotifyOpen, setNotifyOpen] = useState(false);

  const { id: invoiceId, amount, currency, contractNumber, clientInfo, createdAt } = invoice || {};

  const addPaymentFormConfig = {
    submitText: "Create",
    cancelText: "Cancel",
    fields: [
      {
        name: "amount",
        title: "Amount with VAT",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: 1,
        step: 0.01,
        startAdornment: currency,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "date",
        title: "Date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "isPayerIndividualPerson",
        title: "Payer is individual person",
        type: "boolean",
        isRequired: true,
        isReadOnly: false,
      },
      { name: "invoiceId", type: "hidden", viewConfig: "input", initialValue: invoiceId },
      { name: "currency", type: "hidden", viewConfig: "input", initialValue: currency },
      {
        name: "contractNumber",
        title: "Contract number",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: contractNumber,
      },
      {
        name: "clientName",
        title: "Client name",
        type: "string",
        viewConfig: "input",
        isReadOnly: true,
        initialValue: clientInfo?.name,
      },
      {
        name: "totalAmount",
        title: "Amount",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        startAdornment: currency,
        isReadOnly: true,
        initialValue: amount,
      },
      {
        name: "invoiceDate",
        title: "Invoice date",
        type: "date",
        disablePast: true,
        disableFuture: true,
        isReadOnly: true,
        disabled: true,
        initialValue: createdAt,
      },
    ],
  };

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setPending(true);
        data.date = moment(data.date, "L").format("YYYY-MM-DD");
        await invoiceStore.addPaymentAsync(data);
        setNotifyOpen(true); // Показать нотификацию
      } catch (error) {
        rootStore.setError("error", null, error?.message);
      } finally {
        setPending(false);
        onClose();
      }
    },
    [invoiceStore, rootStore, onClose]
  );

  return (
    <>
      <FormDialog
        isVisible={open}
        closeDialog={onClose}
        formConfig={addPaymentFormConfig}
        handleSubmit={handleSubmit}
        isPending={isPending}
        title="Creating payment"
        pendingText="Saving payment..."
      />
      <Notify
        isOpen={isNotifyOpen}
        onClose={() => setNotifyOpen(false)}
      >
        Payment successfully created!
      </Notify>
    </>
  );
});

export default PaymentDialog;
