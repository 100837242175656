import { computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { ContractInfo } from "~/modules/contracts/data/models";
import InvoiceInfo from "./InvoiceInfo";
import { UserInfo } from "~/modules/employees/data/models";

class Payment {
  @observable store = null;
  @observable invoiceId = null;
  @observable id = null;
  @observable amount = 0;
  @observable valueWithoutVAT = 0;
  @observable createdAt = null;
  @observable date = null;
  @observable currency = null;
  @observable isPayerIndividualPerson = false;
  @observable modifiedAt;

  //
  @observable invoiceInfo;
  @observable contractInfo;
  @observable editorInfo;

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = `${props.id}`;
    this.invoiceId = `${props.invoiceId}`;
    this.amount = Number(props.value) || 0;
    this.valueWithoutVAT = Number(props.valueWithoutVAT) || 0;
    this.createdAt = moment(props.createdAt);
    this.date = moment(props.date);
    this.currency = props.currency;
    this.isPayerIndividualPerson = props.isPayerIndividualPerson;

    if (props.modifiedAt) {
      this.modifiedAt = moment(props.modifiedAt);
    }

    //
    if (props.invoiceInfo) {
      this.invoiceInfo = new InvoiceInfo(props.invoiceInfo);
    }
    if (props.contractInfo) {
      this.contractInfo = new ContractInfo(props.contractInfo);
    }
    if (props.editorInfo) {
      this.editorInfo = new UserInfo(props.editorInfo);
    }
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get invoice() {
    return this.store.isPending ? { isPending: true } : this.store.getInvoiceById(this.invoiceId);
  }

  /**
   * Номер контракта через счёт или contractInfo.
   */
  @computed get contractNumber() {
    if (this.contractInfo) return this.contractInfo.number;
    return (this.invoice && this.invoice.contractNumber) || "Unknown contract";
  }

  /**
   * Сколько дней прошло от счета до платежа.
   *
   * Вернет целое число дней.
   */
  @computed
  get paymentDuration() {
    return this.date.diff(this.invoice.createdAt, "days");
  }

  /**
   * Сколько дней прошло между оплатой и её регистрацией.
   */
  @computed
  get registrationDuration() {
    return this.createdAt.diff(this.date, "days");
  }
}

export default Payment;
