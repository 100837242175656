import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { Expense, Category } from "../models";

import { Contract } from "~/modules/contracts/data/models";

import { ExpensesApiV0 } from "../api";
import Shift from "~/models/Shift";
import Facility from "~/models/Facility";
import { BranchExpense, SalaryExpense } from "../models";
import { Branch } from "../../branches/data/models";

import ScannerWorkingShift from "../../scanners_working_shifts/data/models/ScannerWorkingShift";

/**
 *  Стор расходов.
 */
class ExpenseStore {
  @observable categories = new Map();

  @observable expenses = [];
  @observable count = 0;


  @observable filteredExpenses = [];

  @observable searchBy = "";
  @observable selectedCategories = new Map();
  @observable selectedScopes = new Map();

  @observable pending = false;
  @observable root;

  @observable pendingRequests = 0; // Отслеживает активные запросы
  @observable pendingText = ""; // Сообщение для загрузки


  // расходы филиала
  // @observable branchExpenses = [];
  // @observable branchExpensesTotalCount = 0;

  // расходы на зарплату
  // @observable salaryExpenses = [];
  // @observable salaryExpensesTotalCount = 0;

  constructor(rootStore) {
    makeObservable(this);

    this.pendingRequests = 0;
    this.root = rootStore;
    this.api = new ExpensesApiV0(rootStore.authStore);
  }

  /**
   * Initially inits store.
   */
  @action async init() {
    this.setPending(true);
    const { branch } = this.root.branchStore;
    if (!branch) return;

    const categories = await this.api.getExpenseCategories(branch.id); // loads categories
    runInAction(() => {
      const branchKey = `${branch.id}`;
      this.categories.set(branchKey, new Map());

      // если у нас this.categories не массив, то мы его его преобразуем в массив
      const categoriesArray = Array.isArray(categories) ? categories : Array.from(categories);
      this.categories.get(branchKey).merge(
        categoriesArray.map((c) => [`${c.id}`, new Category({ id: c.id, name: c.name, scopes: c.scopes }, this)])
      );
    });
    this.setPending(false);
  }


  /**
   * Вычисляемое свойство для проверки состояния загрузки.
   */
  @computed get isPending() {
    return this.pendingRequests > 0 || this.api.isPending;
  }

  /**
   * Увеличивает количество активных запросов и устанавливает сообщение загрузки.
   */
  @action.bound increasePending(text) {
    this.pendingRequests++;
    this.pendingText = text || "";
  }

  @action.bound decreasePending() {
    if (this.pendingRequests > 0) this.pendingRequests--;
    if (this.pendingRequests === 0) this.pendingText = "";
  }


  /**
   * Создать расход в контексте.
   *
   * @param {object} payload данные расхода
   * @param {model} context контекст расхода
   *
   * @ai
   */

  @action async createExpense(payload, context) {
  try {
    // Загружаем документы, если они есть
    if (payload.documents?.length) {
        this.increasePending("Uploading documents");
        const documentIds = await this.root.uploadDocuments(payload.documents);
        payload.documents = documentIds;
        this.decreasePending();
    } else {
        payload.documents = [];
    }

      if (context instanceof Contract) {
        this.increasePending("Creating expense");
        const data = await this.api.createContractExpense(context.id, payload);
        let createdExpense;
        runInAction(() => {
            // Найти или добавить категорию
            let category = this.resolveCategory(data.categoryId);
            if (!category) {
                category = this.addCategory({
                    id: data.categoryId,
                    name: data.category.name || "New Category",
                    scopes: ["contract"],
                }, this.root.branchStore.branch.id);
            }

            // Создать новый объект расхода
            createdExpense = new Expense({
                ...data,
                scope: "contract",
                category: category,
                contract: context,
            }, this);

            // Добавить расход в контракт
            context.addExpense(createdExpense);
        });
        return createdExpense;
      }

  if (context instanceof Shift || context instanceof ScannerWorkingShift) {
        this.increasePending("Creating expense");
        const value = {
          amount: Number(payload.amount),
          currency: this.root.branchStore.branch.currency,
        };
        const data = await this.api.createShiftExpense(context.id, { ...payload, value });
        let created;
        runInAction(() => {
          created = new Expense(
            {
              ...data,
              scope: "shift",
              category: this.resolveCategory(data.categoryId),
              shift: context,
            },
            this
          );
          context.addExpense(created);
        });
        return created;
      }

      if (context instanceof Facility) {
        this.increasePending("Creating expense");
        const value = {
          amount: Number(payload.amount),
          currency: this.root.branchStore.branch.currency,
        };
        const data = await this.api.createFacilityExpense(context.id, { ...payload, value });
        let created;
        runInAction(() => {
          created = new Expense(
            {
              ...data,
              scope: "shift",
              category: this.resolveCategory(data.categoryId),
              shift: context,
            },
            this
          );
          context.addExpense(created);
        });
        return created;
      }

      if (context instanceof Branch) {
        this.increasePending("Creating expense");
        const value = {
          amount: Number(payload.amount),
          currency: this.root.branchStore.branch.currency,
        };
        const data = await this.api.createBranchExpense(context.id, { ...payload, value });
        let created;
        runInAction(() => {
          created = new BranchExpense({ ...data, scope: "branch" }, context, this);
          this.branchExpenses.unshift(created);
        });
        return created;
      }
    } catch (error) {
      console.error("Error in createExpense:", error);
      throw error;
    } finally {
      this.decreasePending();
    }
  }


  /**
   * Добавить категорию в стор, вернуть её.
   */
  @action addCategory({ id, name, scopes }, branchId) {
    const category = new Category({ id, name, scopes });
    const branchKey = `${branchId}`;
    if (!this.categories.has(branchKey)) {
      this.categories.set(branchKey, new Map());
    }
    this.categories.get(branchKey).set(`${id}`, category);
    return category;
  }


  /**
   * Вернет опционально отфильтрованный набор расходов.
   */
  @computed get expensesAsCommon() {
    let found = this.filteredExpenses.slice();

    // фильтр по строке поиска
    if (this.searchBy) {
      const regex = new RegExp(this.searchBy, "i");
      found = found.filter((e) => {
        return (
          e.author?.fullName.match(regex) ||
          e.contract?.number.match(regex) ||
          e.scope.match(regex) ||
          e.category.name.match(regex)
        );
      });
    }

    // фильтр по области расходов
    if (this.selectedScopes.size !== 0) {
      found = found.filter((e) => {
        return this.selectedScopes.has(e.scope);
      });
    }

    // фильтр по категории расходов
    if (this.selectedCategories.size !== 0) {
      found = found.filter((e) => {
        return this.selectedCategories.has(e.category.name);
      });
    }

    return found;
  }

  @computed get scopesArray() {
    return ["branch", "contract", "facility", "shift"];
  }

  // TODO: здесь должно быть за бранч
  @computed get categoriesArray() {
    return [];
    // const categories = [];
    // this.categories.forEach((category, id) => {
    //   categories.push(category);
    // });
    // return categories;
  }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  @action setFilterOptions(searchBy, selectedScopes, selectedCategories) {
    // Значение строки поиска
    this.searchBy = searchBy;

    // выбранные области расходов
    this.selectedScopes.replace(selectedScopes.map((s) => [`${s}`, true]));

    // выбранные категории
    this.selectedCategories.replace(selectedCategories.map((c) => [`${c}`, true]));
  }

  // TODO: Удалить метод.
  //** Это старый метод, он больше не нужен.  */
  @action async fetchExpensesForBranch(branchId) {
    this.setPending(true);
    const expenses = await this.api.getExpensesForBranch(branchId);
    const filteredExpenses = [];
    const contractIds = []; // ids контрактов которые нужно спросить в сторе

    expenses.contractExpenses.forEach((expense) => {
      contractIds.push(expense.contractId);
    });

    expenses.facilityExpenses.forEach((expense) => {
      contractIds.push(expense.contractId);
    });

    // TODO: Сделать пагинацию!
    // TODO: Заменить на contractInfo!
    // TODO: Заменить на facilityInfo!
    // Теперь нужно спросить контракты
    // await this.root.contractStore.getFoundContracts(branchId, contractIds);


    expenses.facilityExpenses.forEach((expense) => {
      filteredExpenses.push(
        new Expense(
          {
            ...expense,
            id: `facility-${expense.id}`,
            scope: "facility",
            category: this.resolveCategory(expense.categoryId),
            contract: this.root.contractStore.getFoundContractById(expense.contractId),
            facility: expense.facilityId,
          },
          this
        )
      );
    });

    expenses.workingShiftExpenses.forEach((expense) => {
      // а здесь не бывает прямой связи с контрактом
      filteredExpenses.push(
        new Expense(
          {
            ...expense,
            id: `shift-${expense.id}`,
            scope: "shift",
            category: this.resolveCategory(expense.categoryId),
            shift: expense.workingShift,
          },
          this
        )
      );
    });

    runInAction(() => {
      this.filteredExpenses.replace(filteredExpenses);
    });
    this.setPending(false);
  }

  /**
   * Returns category by id.
   */
  resolveCategory(id) {
    return this.categories.get(`${id}`);
  }

  /**
   * Вернуть список категорий для бранча и скоупа.
   * 
   * FYI: для бранча пока не поддерживается, - только для текущего.
   * Добавлю когда потребуется.
   * 
   * @param {*} branchId 
   * @param {*} scope 
   * @returns 
   */
  @action getCategories(branchId, scope) {
    const categories = [];
    const branchKey = `${branchId}`;

    if (!this.categories.has(branchKey)) return [];

    this.categories.get(branchKey).forEach((c) => {
      if (c.scopes.has(scope)) { categories.push(c); }
    });
    return categories;
  }

  /**
   * Запросить расходы филиала.
   * 
   * @param {*} branch 
   */
  @action async fetchBranchExpenses(branch, pagination) {
    runInAction(() => {
      this.pendingRequests++;
      this.pendingText = "Loading branch expenses";
    });
    const [expenses, count] = await this.api.getBranchExpenses(branch.id, pagination);
    runInAction(() => {
      this.count = Number.parseInt(count);
      // this.expenses.clear();
      this.expenses = expenses.map((e) => new BranchExpense(e, branch, this));
      this.pendingRequests--;
      this.pendingText = "";
    });
  }

  @computed get branchExpensesArray() {
    return this.branchExpenses.slice();
  }

  /**
   * Запросить расходы филиала.
   * 
   * @param {*} branch 
   */
  @action async fetchSalaryExpenses(branch, pagination, options = {}) {
    runInAction(() => {
      this.pendingRequests++;
      this.pendingText = "Loading salary expenses";
    });
    const [expenses, count] = await this.api.getSalaryExpenses(branch.id, pagination);
    runInAction(() => {
      this.count = Number.parseInt(count);
      this.expenses = expenses.map((e) => new SalaryExpense(e, branch, this));
      this.pendingRequests--;
      this.pendingText = "";
    });
  }

  /**
   * Зарплатные расходы списком.
   */
  @computed get salaryExpensesArray() {
    return this.salaryExpenses.slice();
  }


  /**
   * Запросить расходы по контрактам.
   * 
   * @param {*} branch 
   * @param {*} pagination 
   * @param {*} options 
   */
  @action async fetchContractExpenses(branch, pagination, options = {}) {
    runInAction(() => {
      this.pendingRequests++;
      this.pendingText = "Loading contract expenses";
      this.expenses.clear();
    });
    const [expenses, count] = await this.api.getContractExpenses(branch.id, pagination, options);
    runInAction(() => {
      this.count = Number.parseInt(count);
      this.expenses = expenses.map((e) => new Expense({ ...e, scope: "contract" }, this));
      this.pendingRequests--;
      this.pendingText = "";
    });
  }

  @computed get expensesArray() {
    return this.expenses.slice();
  }

  /**
   * Запросить расходы по объектам.
   * 
   * @param {*} branch 
   * @param {*} pagination 
   * @param {*} options 
   */
  @action async fetchFacilityExpenses(branch, pagination, options = {}) {
    runInAction(() => {
      this.pendingRequests++;
      this.pendingText = "Loading contract expenses";
      this.expenses.clear();
    });
    const [expenses, count] = await this.api.getFacilityExpenses(branch.id, pagination, options);
    runInAction(() => {
      this.count = Number.parseInt(count);
      this.expenses = expenses.map((e) => new Expense({ ...e, scope: "facility" }, this));
      this.pendingRequests--;
      this.pendingText = "";
    });
  }

  /**
   * Запросить расходы по объектам.
   * 
   * @param {*} branch 
   * @param {*} pagination 
   * @param {*} options 
   */
  @action async fetchShiftExpenses(branch, pagination, options = {}) {
    runInAction(() => {
      this.pendingRequests++;
      this.pendingText = "Loading contract expenses";
      this.expenses.clear();
    });
    const [expenses, count] = await this.api.getShiftExpenses(branch.id, pagination, options);
    runInAction(() => {
      this.count = Number.parseInt(count);
      this.expenses = expenses.map((e) => new Expense({ ...e, scope: "shift" }, this));
      this.pendingRequests--;
      this.pendingText = "";
    });
  }
}

export default ExpenseStore;
