import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";
import { Notify } from "~/components";
import { FormDialog } from "~/components/dialogs";

/**
 * Диалог создания или обновления плана сканирования.
 *
 * @param {*} props
 */
const CreateFacilityScanningPlanDialog = observer(
  ({
    facility,
    isOpen,
    onClose,
    // Для передачи данных о повторном визите
    initialData = {},
  }) => {
    const { facilityStore } = useStores();
    const [isPending, setIsPending] = useState(false);
    const [notify, setNotify] = useState({ open: false, message: "", severity: "info" });

    const handleNotify = useCallback((message, severity = "info") => {
      setNotify({ open: true, message, severity });
    }, []);

    const handleCloseNotify = useCallback(() => {
      setNotify({ open: false, message: "", severity: "info" });
    }, []);

    const handleSubmit = useCallback(
      async (data) => {
        if (!facility) return;
        setIsPending(true);
        const isRepeat = !!initialData.isRepeat;
        const payload = {
          contractId: facility.contract.id,
          facilityId: facility.id,
          finish: data.finish,
          scannerId: data.scannerId,
          start: data.start,
          isRepeat,
          ...(isRepeat && { repeatReason: data.reason || "No reason provided" }),
        };

        try {
          let updatedPlan;
          if (facility.scanningPlan && !isRepeat) {
            updatedPlan = await facilityStore.updateFacilityScanningPlan({
              ...payload,
              planId: facility.scanningPlan.id,
            });
            handleNotify("Scanning plan updated successfully.", "success");
          } else {
            updatedPlan = await facilityStore.createFacilityScanningPlan(payload);
            handleNotify("Scanning plan created successfully.", "success");
          }

          // Обновляем объект в контракте
          facility.scanningPlans = [
            ...(facility.scanningPlans || []).filter((p) => p.id !== updatedPlan.id),
            updatedPlan,
          ];

          // Вызываем обновление контракта
          facility.contract.updateFacility(facility);
        } catch (error) {
          console.error("Error in scanning plan creation/update:", error);
          handleNotify("Error while creating/updating the scanning plan.", "error");
        }

        setIsPending(false);
        onClose && onClose();
      },
      [onClose, facility, facilityStore, initialData, handleNotify]
    );

    const isRepeat = !!initialData.isRepeat;

    const formConfig = useMemo(() => {
      let fields = [
        {
          name: "schedule",
          type: "schedule",
          startName: "start",
          finishName: "finish",
          role: "scanner",
          employeeIdName: "scannerId",
          minimumPeriodInHours: 2,
          ...(initialData.start && { initialStart: initialData.start }),
          ...(initialData.finish && { initialFinish: initialData.finish }),
        },
      ];
      if (isRepeat) {
        fields = [
          {
            name: "reason",
            type: "textarea",
            title: "Reason for Repeat",
            required: true,
          },
          ...fields,
        ];
      }

      return {
        submitText: facility && facility.scanningPlan && !isRepeat ? "Update" : "Create",
        cancelText: "Cancel",
        fields,
      };
    }, [facility, initialData, isRepeat]);

    return (
      <>
        <FormDialog
          title={
            isRepeat
              ? "Repeating scanning plan"
              : facility?.scanningPlan
              ? "Updating scanning plan"
              : "Creating scanning plan"
          }
          formConfig={formConfig}
          isVisible={isOpen}
          closeDialog={onClose}
          handleSubmit={handleSubmit}
          isPending={isPending}
          size="large"
        />
        <Notify isOpen={notify.open} message={notify.message} severity={notify.severity} onClose={handleCloseNotify} />
      </>
    );
  }
);

export default CreateFacilityScanningPlanDialog;
