import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import ApplicationBar from "./ApplicationBar";
import Sidebar from "./Sidebar";

import useStores from "~/hooks";

/**
 * Рабочая область системы.
 */
const Workspace = observer(({ modules, appearance, renderError }) => {
  const { theme, mode, isMobile } = appearance;
  const { workspaceStore } = useStores();
  const { view } = workspaceStore;
  const currentModule = useMemo(() => modules[view], [modules, view]);

  let title = "Unknown module";
  let icon = <></>;
  let component = <></>;
  let isMultiBranch = false;
  if (currentModule) {
    title = currentModule.title;
    icon = currentModule.icon;
    component = currentModule.component;
    isMultiBranch = currentModule.isMultiBranch;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mt: 7,
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
          fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
        }}
      >
        <CssBaseline />
        {renderError}
        <ApplicationBar
          title={title}
          icon={icon}
          mode={mode}
          rootStore={workspaceStore.root}
          isMultiBranch={isMultiBranch}
        />
        <Sidebar modules={modules} isMobile={isMobile} />
        <Box component="main" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, p: 0, overflow: "hidden" }}>
          {/* <DrawerHeader sx={{ flexShrink: 0 }} /> */}
          <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
            {component}
            {/* {!isPendingCritical && modules[currentView]} */}
            {/* <Preloader isPending={isPendingCritical} /> */}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export default Workspace;
