import { ApiBase } from "~/api";

/**
 * API для работы с аналитическими данными.
 */
export default class AnalyticsAPI extends ApiBase {
  /**
   * Запросить данные аналитики.
   * 
   * @param {string} startDate - Начальная дата в формате YYYY-MM-DD
   * @param {string} endDate - Конечная дата в формате YYYY-MM-DD
   * @returns {Array} - Массив данных аналитики
   */
  async getAnalyticsData(startDate, endDate) {
    const url = `/api/v0/analytics`;
    const parameters = { startDate, endDate };

    const response = await fetch(url + `?startDate=${startDate}&endDate=${endDate}`);
    if (response.status === 404) {
      console.warn("using mock data");

      return [
        { id: 1, name: "Alisa", kpi: 9, sumKpi: 62450, contracts: 2, sumContracts: 13700, planCompletion: "19.5%", plan: 70000, createdAt: "2024-10-01" },
        { id: 2, name: "Maxim S.", kpi: 24, sumKpi: 234000, contracts: 18, sumContracts: 113000, planCompletion: "113%", plan: 100000, createdAt: "2024-10-15" },
        { id: 3, name: "Maksim M.", kpi: 3, sumKpi: 109400, contracts: 1, sumContracts: 1600, planCompletion: "3%", plan: 50000, createdAt: "2024-10-20" },
        { id: 4, name: "Viktor", kpi: 17, sumKpi: 130430, contracts: 11, sumContracts: 90400, planCompletion: "130%", plan: 70000, createdAt: "2024-10-25" },
        { id: 5, name: "Matvei", kpi: 1, sumKpi: 2500, contracts: 0, sumContracts: 0, planCompletion: "0%", plan: 15000, createdAt: "2024-10-30" },
        { id: 6, name: "Alex", kpi: 6, sumKpi: 21100, contracts: 4, sumContracts: 11600, planCompletion: "46%", plan: 25000, createdAt: "2024-10-31" },
      ];
    }

    if (!response.ok) {
      throw new Error(`failed: ${response.statusText}`);
    }
    
    const data = await response.json();
    return data;
  }
}
