import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";

import { Box, Tooltip } from "@mui/material";
import { DataGrid } from "~/components/grid";
import Translator from "../../../components/Translator";
import Preloader from "../../../components/Preloader";

import ContractLink from "~/modules/contracts/components/buttons/ContractLink";

import useStores from "~/hooks";

import "./datagrid.css";

/**
 * Таблица счетов и платежей контракта.
 */
const Payments = observer((props) => {
  const { languageStore, paymentStore, branchStore } = useStores();

  const { branch } = branchStore;

  const { daterange, searchBy, refreshPls } = props;

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const getPayments = useCallback(async () => {
    await paymentStore.getPayments(branch, searchBy, paginationModel, daterange);
  }, [paymentStore, branch, paginationModel, daterange, searchBy]);

  useEffect(() => {
    getPayments();
  }, [getPayments, refreshPls]);

  const columns = [
    {
      field: "contractNumber",
      type: "string",
      headerName: languageStore.translate({ text: "Contract Number" }),
      width: 200,
      renderCell: ({ row }) => (
        <ContractLink contractId={row.contractInfo.id} contractNumber={row.contractInfo.number} />
      ),
    },
    {
      field: "invoiceNumber",
      type: "string",
      headerName: languageStore.translate({ text: "Invoice Number" }),
      width: 200,
      renderCell: ({ row }) => <Translator text={row.invoiceInfo.number} />,
    },
    {
      field: "paymentDate",
      type: "date",
      headerName: languageStore.translate({ text: "Payment date" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator date={row.date} />;
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: languageStore.translate({ text: "Amount Gross" }),
      width: 150,
      renderCell: (params) => {
        return <Translator money={params.row.amount} />;
      },
    },
    {
      field: "valueWithoutVAT",
      headerName: languageStore.translate({ text: "Amount Net" }),
      type: "number",
      width: 150,
      renderCell: (params) => {
        return <Translator money={params.row.valueWithoutVAT} />;
      },
    },
    {
      field: "invoiceAmount",
      type: "number",
      headerName: languageStore.translate({ text: "Invoice amount Gross" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator money={row.amount} />;
      },
    },
    {
      field: "invoiceAmountWithoutVAT",
      type: "number",
      headerName: languageStore.translate({ text: "Invoice amount Net" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator money={row.valueWithoutVAT} />;
      },
    },
    {
      field: "Author",
      type: "string",
      headerName: languageStore.translate({ text: "Author" }),
      width: 200,
      renderCell: ({ row }) => <Translator text={row.editorInfo?.name} />,
    },
    {
      field: "Modified at",
      type: "date",
      headerName: languageStore.translate({ text: "Modified at" }),
      width: 150,
      renderCell: ({ row }) => {
        return <Translator date={row.date} />;
      },
    },
  ];

  /**
   * Динамически вычисляет стиль ячейки таблицы.
   */
  const getCellClassName = (params) => {
    let className = "";
    const { field, value } = params;
    if (field === "paymentDuration" || field === "registrationDuration") {
      if (value === 0) return className;
      // Счёт создали после оплаты
      else if (value < 0) {
        className += "black-cell";
      } else if (value < 7) {
        return "";
      } else if (value >= 7 && value <= 14) {
        return "orange-cell";
      } else {
        return "red-cell";
      }
    }
    return className;
  };

  // данные из стора
  const { payments, totalCount } = paymentStore;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", m: 1, justifyContent: "end" }}>
        <Tooltip arrow title={<Translator text="Amount Gross" />}>
          <Box sx={{ ml: 4, fontWeight: "bold", fontSize: "large", minWidth: 125 }}>
            <Translator number={paymentStore.summary.amount || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <Tooltip arrow title={<Translator text="Amount Net" />}>
          <Box sx={{ ml: 4, fontWeight: "bold", fontSize: "large", color: "#428c00", minWidth: 125 }}>
            <Translator number={paymentStore.summary.amountNet || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
      </Box>
      <DataGrid
        rows={payments}
        columns={columns}
        loading={false}
        getCellClassName={getCellClassName}
        stickyHeader={true}
        autoPageSize={false}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={[10, 15, 20, 25, 50, 100]}
        onPaginationModelChange={setPaginationModel}
        rowCount={totalCount}
      />
    </>
  );
});

export default Payments;
