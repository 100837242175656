import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { Dialog, DialogTitle } from "@mui/material";

import Form from "~/components/Form";
import { Notify } from "~/common/components";
import Translator from "~/components/Translator";
import Preloader from "~/components/Preloader";

import useStores from "~/hooks";

/**
 * Диалог редактирования договора.
 *
 * @returns
 */
const EditContractDialog = observer(({ contract, isOpen, onClose }) => {
  const [isPending, setIsPending] = useState(false);
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);

  const { contractStore, branchStore, clientStore } = useStores();
  const { branch } = branchStore;

  const formErrors = [];

  // Закрывать диалог только по клику в кнопку и никак не реагировать на клик в фон
  const handleClose = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && onClose();
    },
    [onClose]
  );

  // Сабмит формы и отправка данных на бекенд.
  const handleSubmit = useCallback(
    async (data) => {
      setIsPending(true);
      if (!data.clientId || data.clientId === "create new") {
        const clientData = {
          name: data.clientName,
          description: data.clientDescription,
        };
        const client = await clientStore.createClient(branch, clientData);
        data.client = client.name;
        data.clientId = client.id;
      }
      const newContractData = {
        ...data,
        date: moment(data.date, "L").format("YYYY-MM-DD"),
        value: {
          amount: Number(data.value),
          currency: branch.currency,
        },
        expensesPlan: {
          amount: Number(data.expensesPlan),
          currency: branch.currency,
        },
      };
      const updated = await contractStore.editContract(contract, newContractData);
      setIsNotifyOpen(true);
      setIsPending(false);
      onClose();
    },
    [contract, contractStore, onClose, branch, clientStore]
  );

  const formConfig = useMemo(() => {
    return {
      submitText: "Save",
      cancelText: "Cancel",
      fields: [
        {
          name: "changeReason",
          title: "Change reason",
          type: "string",
          isRequired: true,
          isReadOnly: false,
          isPending: isPending,
        },
        {
          name: "isLead",
          title: "Is contract comes from incoming lead?",
          type: "boolean",
          isRequired: true,
          initialValue: contract?.isLead,
        },
        {
          name: "number",
          title: "Contract #",
          type: "text",
          viewConfig: "input",
          initialValue: contract?.number,
          isRequired: true,
          isReadOnly: true,
          validate: true,
        },
        {
          name: "date",
          title: "Date",
          type: "date",
          initialValue: contract?.date,
          disablePast: false,
          disableFuture: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "value",
          title: "Value",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 1,
          step: 0.01,
          initialValue: contract?.valueTotal && contract?.valueTotal.amount,
          startAdornment: contract?.currency,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          initialValue: contract?.description,
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
        {
          name: "expensesPlan",
          title: "Expenses plan",
          type: "number",
          viewConfig: "input",
          inputMode: "decimal",
          min: 0,
          step: 1,
          initialValue: `${contract?.expensesPlanTotal}`,
          startAdornment: contract?.currency,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "clientId",
          fakeName: "client",
          title: "Client",
          type: "client",
          withCreate: true,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: clientStore.isPending,
          options: [],
          initialValue: contract?.client,
        },
        {
          id: "documents",
          name: `documents`,
          title: "Documents",
          fileType: "documents",
          type: "file",
          initialValue: contract?.documentsArray,
          min: 0,
          max: 4,
          multiple: true,
          isRequired: false,
          isReadOnly: false,
          validate: true,
        },
      ],
    };
  }, [contract, isPending, clientStore.isPending]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
          <Translator text={"Editing contract"} after={`${contract?.number}`} />
          <Preloader isPending={isPending} size={"2rem"} sx={{ justifyContent: "end" }} />
        </DialogTitle>
        <Form
          isPending={isPending}
          config={formConfig}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          errors={formErrors}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={`Contract ${contract?.number} updated`} />
      </Notify>
    </>
  );
});

export default EditContractDialog;
