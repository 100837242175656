import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";

import { Button, Card, CardActions, CardContent, Tooltip, Alert } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

import useStores from "~/hooks";
// import { Notify } from "~/common/components";
import { Translator as T } from "~/components";
import { FacilityInfoCard } from "~/modules/facilities/components";

/**
 * Карточка незапланированных ещё объектов моделирования.
 *
 * Отображается списком в нижней части экрана планирования отрисовки.
 */
const RenderingCard = observer(({ facility, onCreatingPlanPressed }) => {
  const { facilityStore } = useStores();
  const { isPending } = facilityStore;

  const handleCreatePlanClick = useCallback(() => {
    onCreatingPlanPressed && onCreatingPlanPressed(facility);
  }, [facility, onCreatingPlanPressed]);

  // const [notify, setNotify] = useState({ isOpen: false, message: "", severity: "success" });

  // const onSubmit = useCallback(
  //   async (data) => {
  //     setNotify({
  //       isOpen: true,
  //       message: "Modelling plan successfully created!",
  //       severity: "success",
  //     });
  //     setIsFormPending(true);
  //     try {
  //       const response = await facilityStore.addNewModellingPlan(data);
  //       if (response) {
  //         runInAction(() => {
  //           setNotify({
  //             isOpen: true,
  //             message: "Modelling plan successfully created!",
  //             severity: "success",
  //           });
  //         });
  //       }
  //     } catch (error) {
  //       runInAction(() => {
  //         setNotify({
  //           isOpen: true,
  //           message: "Failed to create modelling plan. Please try again.",
  //           severity: "error",
  //         });
  //       });
  //     } finally {
  //       setIsFormPending(false);
  //       onCloseModal();
  //     }
  //   },
  //   [onCloseModal, facilityStore]
  // );

  // const handleNotifyClose = useCallback(() => {
  //   setNotify((prev) => ({ ...prev, isOpen: false }));
  // }, []);

  const [isFullyScanned, isAnyScanned] = facility.isScanned;

  // Тултип кнопки создания плана сканирования
  const planButtonTooltip = useMemo(() => {
    if (!isFullyScanned) {
      return <T text={"Scanning in progress"} />;
    } else {
      return <T text={"Create modelling plan..."} />;
    }
  }, [isFullyScanned]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "25rem",
        mt: 1,
        p: 0,
        flexShrink: 0,
        backgroundColor: "#eee",
      }}
    >
      {!isFullyScanned && (
        <Alert severity="warning">
          <T text="Facility scanning in progress..." />
        </Alert>
      )}
      <CardContent sx={{ flex: 1, p: 1 }}>
        <FacilityInfoCard facility={facility} />
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Tooltip arrow title={planButtonTooltip}>
          <Button
            key={"planModelling"}
            variant="contained"
            startIcon={<CalendarMonth />}
            disabled={!isFullyScanned || isPending}
            onClick={handleCreatePlanClick}
          >
            <T text={"Plan modelling"} />
          </Button>
        </Tooltip>
      </CardActions>
      {/* Уведомления
      <Notify isOpen={Boolean(notify.isOpen)} onClose={handleNotifyClose} severity={notify.severity}>
        <Translator text={notify.message} />
      </Notify> */}
    </Card>
  );
});

export default RenderingCard;
