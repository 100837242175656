import { observable, action, computed, makeObservable } from "mobx";

import { HttpClientError } from "./errors";

export default class ApiBase {
  AUTHORIZATION_HEADER = "Authorization";

  @observable pendingRequests = 0; // Наблюдаемое свойство

  constructor(authStore) {
    makeObservable(this); // Убираем второй аргумент
    this.authStore = authStore;
  }

  /**
   * Обработать ошибки запроса.
   */
  async handleError(response) {
    // TODO: (ai): 500 это серверная ошибка, у нас же она клинетская в коде
    if (response.status === 401) {
      this.authStore.removeCredentials();
      throw new HttpClientError(response.status, "Unauthorized access.");
    }

    if (response.status >= 400 && response.status < 500) {
      const data = await response.json();
      let detail = data.detail || "Client error";

      // Если detail — массив, объединяем сообщения в строку
      if (Array.isArray(detail)) {
        detail = detail.map((err) => (typeof err === "string" ? err : JSON.stringify(err))).join(", ");
      }

      throw new HttpClientError(response.status, detail);
    }

    if (response.status >= 500) {
      const message = await response.text();
      // TODO: Это серверная ошибка, здесь нужно звать администратора.
      // Но и от сетевой нужно отличать.
      throw new HttpClientError(
        response.status,
        "Server error. Please contact support." +
        (message ? `\nDetails: ${message}` : ""));
    }

    return response;
  }

  /**
   * Вычисляемое свойство для проверки активных запросов.
   */
  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  /**
   * Увеличить количество активных запросов.
   */
  @action.bound
  increasePending() {
    this.pendingRequests++;
  }

  /**
   * Уменьшить количество активных запросов.
   */
  @action.bound
  decreasePending() {
    if (this.pendingRequests > 0) {
      this.pendingRequests--;
    } else {
      console.warn("below 0");
    }
  }



  /**
   * Выполнить GET запрос.
   *
   * @param {string} url УРЛ на который шлём запрос
   * @param {object} parameters аргументы url-строки
   * @param {object} headers дополнительные заголовк
   * @returns
   */
  async get(url, parameters = {}, headers = {}) {
    this.increasePending();
    const params = new URLSearchParams(parameters);
    try {
      const response = await fetch(`${url}?${params}`, {
        method: "get",
        headers: { ...headers, [this.AUTHORIZATION_HEADER]: this.authStore.token },
      });
      const handledResponse = await this.handleError(response);
      return [await handledResponse.json(), handledResponse.headers];
    } finally {
      this.decreasePending();
    }
  }

  /**
  * Выполнить POST запрос.
  *
  * @param {string} url УРЛ на который шлём запрос
  * @param {object} payload тело запроса
  * @param {object} parameters аргументы url-строки
  * @param {object} headers дополнительные заголовки
  */
  async post(url, payload = {}, parameters = {}, headers = {}) {
    this.increasePending();
    const params = new URLSearchParams(parameters);
    try {
      const response = await fetch(`${url}?${params}`, {
        method: "post",
        headers: {
          [this.AUTHORIZATION_HEADER]: this.authStore.token,
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(payload),
      });
      const handledResponse = await this.handleError(response);
      return [await handledResponse.json(), handledResponse.headers];
    } finally {
      this.decreasePending();
    }
  }


  async postFormData(url, data = {}, parameters = {}, headers = {}) {
    this.increasePending();
    const params = new URLSearchParams(parameters);
    const form = new FormData();
    for (const name in data) {
      form.append(name, data[name]);
    }
    try {
      const response = await fetch(`${url}?${params}`, {
        method: "POST",
        headers: {
          [this.AUTHORIZATION_HEADER]: this.authStore.token,
          ...headers,
        },
        body: form,
      });
      const handledResponse = await this.handleError(response);
      return [await handledResponse.json(), handledResponse.headers];
    } finally {
      this.decreasePending();
    }
  }

  /**
   * Выполнить PUT запрос.
   *
   * @param {string} url УРЛ на который шлём запрос
   * @param {object} payload тело запроса
   * @param {object} parameters аргументы url-строки
   * @param {object} headers дополнительные заголовки
   */
  async put(url, payload = {}, parameters = {}, headers = {}) {
    this.increasePending();
    const params = new URLSearchParams(parameters);
    try {
      const response = await fetch(`${url}?${params}`, {
        method: "put",
        headers: {
          [this.AUTHORIZATION_HEADER]: this.authStore.token,
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(payload),
      });
      const handledResponse = await this.handleError(response);
      return [await handledResponse.json(), handledResponse.headers];
    } finally {
      this.decreasePending();
    }
  }

  /**
   * Выполнить DELETE запрос.
   *
   * @param {string} url УРЛ на который шлём запрос.
   * @returns ответ сервера
   */
  async delete(url) {
    this.increasePending();
    try {
      const response = await fetch(url, {
        method: "delete",
        headers: { [this.AUTHORIZATION_HEADER]: this.authStore.token },
      });
      const handledResponse = await this.handleError(response);
      return handledResponse.headers;
    } finally {
      this.decreasePending();
    }
  }
}
