import { ApiBase } from "~/api";

/**
 * Запросы на платежи.
 */
class PaymentsApiV0 extends ApiBase {

  /**
   * Получить платежи филиала.
   * 
   * @param {*} branchId 
   * @param {*} searchBy 
   * @param {*} pagination 
   * @param {*} daterange 
   * @returns 
   */
  async getPayments(branchId, searchBy, pagination, daterange) {
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, searchBy };
    if (!!daterange[0]) {
      parameters["from"] = daterange[0].format("YYYY-MM-DD");
    }
    if (!!daterange[1]) {
      parameters["to"] = daterange[1].format("YYYY-MM-DD");
    }
    const url = `api/v0/branches/${branchId}/payments/payments`;
    const [data, headers] = await this.get(url, parameters);
    return [data, Number.parseInt(headers.get("Total-Count"))];
  }
}

export default PaymentsApiV0;