import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { Box, Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import PaymentDialog from "./components/PaymentDialog";
import Translator from "../../components/Translator";

const AddPayment = observer(({ invoice }) => {
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  return (
    <>
      <Tooltip title={<Translator text={"Add payment"} />} arrow>
        <Box>
          <Button
            variant="outlined"
            startIcon={<Add />}
            color="primary"
            onClick={() => setOpenPaymentDialog(true)}
          >
            <Translator text={"Payment"} />
          </Button>
        </Box>
      </Tooltip>
      <PaymentDialog open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)} invoice={invoice} />
    </>
  );
});

export default AddPayment;
