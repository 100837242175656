import { action, makeObservable, observable, runInAction } from "mobx";

import { PaymentsApiV0 } from "../api";

import { Payment } from "./models";

/**
 * Стор платежей.
 * 
 * TODO: Если что-то про платежи есть в Invoice-сторе -- надо перенести сюда.
 */
class PaymentStore {
  @observable payments = [];

  @observable totalCount = 0;
  @observable summary = {};

  constructor(root) {
    makeObservable(this);

    this.root = root;
    this.api = new PaymentsApiV0(root.authStore);
  }

  @action async getPayments(branch, searchBy, pagination, daterange) {
    const [paymentsData, totalCount] = await this.api.getPayments(branch.id, searchBy, pagination, daterange);
    runInAction(() => {
      this.payments.clear();
      paymentsData.payments.forEach((paymentData) => {
        const payment = new Payment(paymentData, this);
        this.payments.push(payment);
      });
      const { summary } = paymentsData;
      this.totalCount = totalCount;
      this.summary.amount = summary.amount;
      this.summary.amountNet = summary.amountNet;
    });
    return this.payments;
  }
}

export default PaymentStore;