import React, { useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";

/**
 * Компонет нотификации пользователя.
 *
 * Всплывает вверху по центру и сообщает пользователю переданное сообщение.
 */
const Notify = ({ children, isOpen, onClose, autoHideDuration, message, severity = "success" }) => {
  const closeCallback = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={autoHideDuration || 2000}
      onClose={closeCallback}
      sx={{ mt: "3rem" }}
    >
      <Alert onClose={closeCallback} severity={severity} variant="filled" sx={{ width: "100%" }}>
        {children || message}
      </Alert>
    </Snackbar>
  );
};

export default Notify;
