import { observer } from "mobx-react";
import { Box, Typography, Tooltip } from "@mui/material";

/**
 * Компонент отображения плана сканирования в расписании.
 */
const FacilityScanningPlan = observer(({ plan, facility, start, finish }) => {
 
const truncateText = (text, maxLength) => {
  
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

  const maxDescriptionLength = 20;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        fontSize: "1.25rem",
        borderRadius: "0.5rem",
        p: 0,
        border: "1px solid rgba(192, 192, 192, 0.5)",
        position: "relative",
        height: "100%",
        margin: 0,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Верхняя строка: время, имя и Repeat */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            padding: "0.125rem",
            boxSizing: "border-box",
          }}
        >
          {/* Время начала */}
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: "rgba(0, 0, 0, 0.6)",
              margin: 0,
              lineHeight: 1,
              whiteSpace: "nowrap", 
            }}
          >
            {start.format("HH:mm")}
          </Typography>

          {/* Имя объекта */}
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              marginLeft: "0.5rem",
              whiteSpace: "nowrap", 
              overflow: "hidden",
              textOverflow: "ellipsis", // троеточие, если имя слишком длинное
            }}
          >
            {facility.name || "Unnamed Facility"}
          </Typography>

          {/* Repeat (если есть) */}
          {plan.isRepeat && (
            <Tooltip title={plan.repeatReason || "No reason"} arrow>
              <Box
                sx={{
                  marginLeft: "auto", // Отодвигаем Repeat вправо
                  padding: "2px 6px",
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                  border: "1px solid red",
                  borderRadius: "0.25rem",
                  color: "red",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  whiteSpace: "nowrap", // Чтобы текст не переносился
                }}
              >
                Repeat
              </Box>
            </Tooltip>
          )}
        </Box>

        {/* Описание объекта */}
        {facility.description && (
        <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          fontSize: "0.875rem",
          padding: "0 0.125rem",
          marginTop: "0.125rem",
          marginLeft: "2.4rem",
        }}
      >
        <Tooltip title={facility.description} arrow>
          <span>{truncateText(facility.description, maxDescriptionLength)}</span>
        </Tooltip>
      </Typography>
      
      )}

      </Box>

      {/* Время завершения */}
      <Typography
        sx={{
          position: "absolute",
          bottom: "0.25rem", // Минимальное расстояние от нижнего края
          left: "0.125rem",
          fontSize: "0.75rem",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {finish.format("HH:mm")}
      </Typography>
    </Box>
  );
});

export default FacilityScanningPlan;
