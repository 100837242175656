import React, { useCallback, useState, useMemo } from "react";
import { FormDialog } from "~/components/dialogs";
import Notify from "~/common/components/Notify";
import useStores from "~/hooks";
import { ClientAlreadyExistsError } from "../../errors";

/**
 * Диалог создания клиента.
 *
 * @param {object} props пропсы диалога создания клиента
 * @param {boolean} isOpen открыт ли диалог
 * @param {function} onClose функция обратного вызова на закрытие
 * @param {function} onCreated функция обратного вызова на создание
 */
const CreateClientDialog = ({ isOpen, onClose, onCreated }) => {
  const { branchStore, clientStore } = useStores();
  const { branch } = branchStore;

  const [isPending, setIsPending] = useState(false);
  const [isNotifyOpen, setNotifyOpen] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Обработать форму
  const handleSubmit = useCallback(
    async (data) => {
      setIsPending(true);
      try {
        const payload = { ...data };
        const client = await clientStore.createClient(branch, payload);
        setNotifyOpen(true); // Показать нотификацию
        setErrorText("");
        onCreated && onCreated(client);
        onClose && onClose();
      } catch (error) {
        if (error instanceof ClientAlreadyExistsError) {
          setErrorText(error.message);
        }
      } finally {
        setIsPending(false);
      }
    },
    [branch, clientStore, onClose, onCreated]
  );

  // Конфиг формы
  const formConfig = useMemo(() => {
    return {
      submitText: "Create",
      cancelText: "Cancel",
      fields: [
        {
          name: "name",
          title: "Name",
          type: "text",
          multiline: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
        },
        {
          name: "description",
          title: "Description",
          type: "text",
          multiline: true,
          isRequired: false,
          isReadOnly: false,
          validate: false,
        },
      ],
    };
  }, []);

  return (
    <>
      <FormDialog
        title={"Creating client"}
        formConfig={formConfig}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isPending}
        errorText={errorText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setNotifyOpen(false)}>
        Client successfully created!
      </Notify>
    </>
  );
};

export default CreateClientDialog;
