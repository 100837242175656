import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import { Edit, FileDownload, Close, FileOpen } from "@mui/icons-material";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import ResultModellingPlanDialog from "../dialogs/ResultModellingPlanConfig";
import EditModellingPlanDialog from "../dialogs/EditModellingPlanDialog";
import Notify from "~/common/components/Notify";
import Translator from "../../../../components/Translator";

const ModellingPlanButtons = observer(({ plan, facility, planVisibleLength = 1000 }) => {
  const [isResultDialogOpen, setResultDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isNotifyOpen, setNotifyOpen] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);

  const handleSetResult = useCallback(() => {
    setResultDialogOpen(true);
  }, []);

  const handleEditPlan = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const handleNotifyClose = useCallback(() => {
    setNotifyOpen(false);
  }, []);

  const onSetShow = useCallback(() => {
    setFileVisible(true);
  }, []);

  const onCloseShow = useCallback(() => {
    setFileVisible(false);
  }, []);

  return (
    <>
      {planVisibleLength >= 4 && (
        <>
          {!plan.result && (
            <>
              <Tooltip arrow title="Finish modelling">
                <IconButton onClick={handleSetResult} aria-label="finish-modelling" color="inherit">
                  <SportsScoreIcon />
                </IconButton>
              </Tooltip>
              <ResultModellingPlanDialog
                isOpen={isResultDialogOpen}
                onClose={() => setResultDialogOpen(false)}
                facility={facility}
                plan={plan}
                setNotifyOpen={setNotifyOpen}
              />
              <Tooltip arrow title="Edit modelling plan">
                <IconButton onClick={handleEditPlan} aria-label="edit-modelling" color="inherit">
                  <Edit />
                </IconButton>
              </Tooltip>
              <EditModellingPlanDialog
                isOpen={isEditDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                facility={facility}
                plan={plan}
              />
            </>
          )}
          {!!plan.result && (
            <Tooltip arrow title="Download result">
              <IconButton onClick={onSetShow} aria-label="download-result" color="inherit">
                <FileDownload />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <Dialog maxWidth="sm" open={fileVisible} onClose={onCloseShow}>
        <DialogTitle>
          <Typography variant="h5">
            <Translator text={"Scan result"} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body">
            <Translator text={"Scan result link is presented below"} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!!plan.result && (
            <Button
              sx={{ overflowWrap: "anywhere", mr: "0.25rem" }}
              variant="outlined"
              href={plan.result.url}
              target="blank"
              color={"info"}
              startIcon={<FileOpen color={"info"} />}
            >
              <Typography variant="body">{plan.result.url}</Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={onCloseShow}
            target="blank"
            color={"error"}
            startIcon={<Close color={"error"} />}
          >
            <Typography variant="body">Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Notify
        isOpen={isNotifyOpen}
        onClose={handleNotifyClose}
        autoHideDuration={4000}
        message="Modelling result successfully set!"
      />
    </>
  );
});

export default ModellingPlanButtons;
