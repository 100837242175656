import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useResizeObserver } from "usehooks-ts";

/**
 * Заголовок диаграммы Ганта модуля планирования отрисовки.
 */
const GantHeader = observer(({ startOfPeriod, endOfPeriod, setDayWidth, borderTop = true }) => {
  const refHeaderDayCell = useRef();

  const { width = 0 } = useResizeObserver({
    ref: refHeaderDayCell,
    box: "border-box",
    // box: "content-box",
    // box: "device-pixel-content-box",
  });

  setDayWidth && setDayWidth(width);

  // TODO: не должен вычислять размеры, размеры вычислять надо снаружи
  const days = useMemo(() => {
    const days = [];
    const start = startOfPeriod.clone().startOf("day");
    const end = endOfPeriod.clone().endOf("day");
    const cursor = start.clone().startOf("day").set("hour", 12);
    let i = 0;
    while (cursor <= end) {
      const isWeekend = cursor.isoWeekday() > 5;
      const key = cursor.unix();
      const isFirst = i === 0;
      days.push(
        // Это заголовок колонки дня планирования
        <Box
          key={key}
          ref={isFirst ? refHeaderDayCell : null}
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            flexShrink: 1,
            minWidth: "5rem",
            width: width > 0 ? `${width}px` : "none",
            justifyContent: "center",
            borderRight: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
            borderTop: (theme) => {
              if (borderTop) {
                return `0.0625rem solid ${theme.palette.grey[300]}`;
              }
              return "none";
            },
            borderBottom: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
            backgroundColor: (theme) => {
              if (isWeekend) return `${theme.palette.grey[100]}`;
            },
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {cursor.clone().date()}
          </Typography>
        </Box>
      );
      i += 1;
      cursor.add(1, "days");
    }
    return days;
  }, [startOfPeriod, endOfPeriod, width, borderTop]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        minHeight: "2rem",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "12rem",
          minWidth: "12rem", // ширина первой ячейки
          borderRight: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
        }}
      ></Box>
      {/* Колонки дней */}
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}>{days}</Box>
    </Box>
  );
});

export default GantHeader;
