import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import ModellingResult from "./ModellingResult";

/**
 * Класс плана моделирования.
 */
export default class ModellingPlan {
  @observable id;
  @observable result; // добавил observable для result

  // связанные сущности
  @observable facility;
  @observable modeller;
  @observable start;
  @observable finish;

  constructor(facility, modeller, props) {
    makeObservable(this);
    const { id, start, finish, result } = props;

    this.id = id;

    // Это на случай если старт и финиш перепутаны местами
    const s = moment(start);
    const f = moment(finish);
    this.start = s.isBefore(f) ? s : f;
    this.finish = s.isBefore(f) ? f : s;

    this.facility = facility;
    this.modeller = modeller;

    if (result) {
      this.result = new ModellingResult(this, result);
    } else {
      this.result = null;
    }
  }

  /**
   * немедленное обновление результата, чтобы триггерить мобикс реактивность
   */
  @action setResult(result) {
    this.result = new ModellingResult(this, result);
  }

  /**
   * Начато ли сканирование.
   */
  @computed get isStarted() {
    if (!this.start) return false;
    return this.start.isBefore();
  }

  /**
   * Завершено ли моделирование объекта.
   */
  @computed get isFinished() {
    return this.result?.url !== "";
  }

  /**
   * Длина плана в днях.
   */
  @computed get length() {
    return moment(this.finish).diff(this.start, "days");
  }
}
