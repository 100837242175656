import { observable, computed, action, makeObservable, runInAction } from "mobx";
import CommercialOffer from "../models/CommercialOffer";
import Document from "~/modules/documents/models/Document";
import { CommercialOffersApiV0 } from "../api";

/**
 * Стор коммерческих предложений.
 */
class CommercialOffersStore {
  offersMap = new Map();
  totalValue = {};
  totalCount = 0;
  isPending = false;

  constructor(rootStore) {
    makeObservable(this, {
      offersMap: observable,
      totalValue: observable,
      totalCount: observable,
      isPending: observable,
      offers: computed,
      createOffer: action,
      readFromJSON: action,
      loadOffers: action,
    });

    this.root = rootStore;

    this.api = new CommercialOffersApiV0(this.root.authStore);
    this.isPending = false;
  }

  /**
   * Сходить на сервер за номером.
   */
  async makeNumber(branch) {
    return await this.api.makeNumber(branch.id);
  }

  /**
   * Дропнуть номер если пользователь его не использовал
   * (нажал Cancel без создания предложения).
   */
  async dropNumber(number) {
    await this.api.dropNumber(number);
  }

  /**
   * Creates a new offer.
   */
  async createOffer(branch, data) {
    runInAction(() => { this.isPending = true; });
    let clientId;

    if (data.clientId === "create new") {
      const newClientData = { name: data.clientName, description: data.clientDescription };
      const client = await this.root.clientStore.createClient(branch, newClientData);
      clientId = client.id;
    } else {
      clientId = data.clientId;
    }

    data = { ...data, client: clientId, clientId };

    // Загружаем документы
    if (data.documents?.length) {
      data.documents = await this.api.uploadDocuments(data.documents);
    } else {
      data.documents = [];
    }

    const created = await this.api.createOffer(branch.id, data);
    runInAction(() => { this.isPending = false; });
    return this.readFromJSON(created);
  }

  /**
   * Отредактировать коммерческое предложение.
   *
   * @param {CommercialOffer} offer экземпляр коммерческого предложения для редактирования
   * @param {object} data обновлённые данные коммерческого предложения
   */
  async editOffer(branch, offer, data) {
    runInAction(() => { this.isPending = true; });
    let clientId;

    if (data.clientId === "create new") {
      const newClientData = { name: data.clientName, description: data.clientDescription };
      const client = await this.root.clientStore.createClient(branch, newClientData);
      clientId = client.id;
    } else {
      clientId = data.clientId;
    }

    data = { ...data, client: clientId, clientId };

    // Загружаем документы
    if (data.documents?.length) {
      const docFiles = data.documents.filter((doc) => !doc.id);
      const existingDocIds = data.documents.filter((doc) => doc.id).map((doc) => doc.id);
      const uploadedIds = await this.api.uploadDocuments(docFiles);
      data.documents = [...existingDocIds, ...uploadedIds];
    } else {
      data.documents = [];
    }

    const updated = await this.api.editOffer(offer.id, data); // Вызываем API
    runInAction(() => { this.isPending = false; });
    return this.readFromJSON(updated);
  }

  /**
   * Reads Offer data from JSON.
   *
   * @param {object} data JSON data w/ Offer's data.
   */
  readFromJSON(data = {}) {  // делаем по дефолту пустым
    const read = new CommercialOffer(this, data);
    this.offersMap.set(`${data.id}`, read);
    return read;
  }

  /**
   * Loads offers from server.
   */
  async loadOffers(branchId, daterange, searchBy, selectedStates, selectedLeadOptions, pagination) {
    runInAction(() => { this.isPending = true; });

    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const params = { searchBy, states: selectedStates, leads: selectedLeadOptions, ...backendPagination };

    if (daterange[0]) {
      params["from"] = daterange[0].format("YYYY-MM-DD");
    }
    if (daterange[1]) {
      params["to"] = daterange[1].format("YYYY-MM-DD");
    }

    const [result, headers] = await this.api.loadOffers(branchId, new URLSearchParams(params));
    const loadedOffers = result["offers"] || [];
    const totalValue = result["total"] || { amount: 0 };

    runInAction(() => {
      this.offersMap.clear();
      this.totalCount = parseInt(headers?.get("Total-Count") || "0");
      this.totalValue = totalValue;

      loadedOffers.forEach((offer) => {
        if (offer && offer.documents) {
          offer.documents = offer.documents.map((d) => Document.create(d, this.root.documentStore));
        }
        this.readFromJSON(offer || {});
      });

      this.isPending = false;
    });
  }


  /**
   * Актуальное (отфильтрованное, найденное) представление коммерческих предложений.
   */
  get offers() {
    // return [
    //   {
    //     id: 1,
    //     createdAt: new Date(),
    //     value: { amount: 100, currency: "USD" },
    //     status: "created",
    //     documents: [],
    //   },
    // ];
    const array = [];
    this.offersMap.forEach((offer) => {
      array.push(offer);
    });
    array.sort((a, b) => b.id - a.id);
    return array;
  }

  async changeOfferState(offerId, data) {
    const result = await this.api.changeOfferState(offerId, data); // Вызываем API
    runInAction(() => {
      this.readFromJSON(result);
    });
  }

  // returns CommercialOffer by id
  offer(id) {
    return this.offersMap.get(`${id}`);
  }
}

export default CommercialOffersStore;
