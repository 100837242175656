import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";

import { FormDialog } from "~/components/dialogs";
import { Translator as T } from "~/components";
import Notify from "~/common/components/Notify";

import useStores from "~/hooks";
import moment from "moment";

/**
 * Диалог редактирования плана моделирования.
 */
const EditModellingPlanDialog = observer(({ isOpen, onClose, plan }) => {
  const [errorText, setErrorText] = useState("");
  const [isNotifyOpen, setNotifyOpen] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);

  const { facilityStore, employeesStore } = useStores();
  const { facilitiesToPlanModelling, isPending } = facilityStore;
  const { renderersArray, isPending: isEmployeesPending } = employeesStore;

  const handleSubmit = useCallback(
    async (data) => {
      setIsFormPending(true);
  
  
      const requestData = {
        planId: plan.id,
        facilityId: plan.facility.id,
        modellerId: data.modellerId,
        start: moment(data.start).format("YYYY-MM-DD"),
        finish: moment(data.finish).format("YYYY-MM-DD"),
      };
  
  
      try {
        await plan.facility.editModellingPlan(requestData);
        setNotifyOpen(true);
        setTimeout(() => onClose && onClose(), 200);
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Error:`, error);
        setErrorText("Failed to edit modelling plan. Please try again.");
      } finally {
        setIsFormPending(false);
      }
    },
    [plan, onClose]
  );
  

  const config = useMemo(() => {
    return {
      submitText: "Save",
      cancelText: "Cancel",
      fields: [
        {
          name: "modellerId",
          fakeName: "modeller",
          title: "Modeller",
          type: "select",
          canCreate: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: isEmployeesPending,
          options: renderersArray,
          initialValue: plan.modeller?.id,
        },
        {
          name: "dateRangePicker",
          title: "Date Range",
          type: "dateRangePicker",
          isRequired: true,
          initialValue: {
            start: plan.start,
            finish: plan.finish,
          },
          validate: (_, values) => {
            if (!values.start || !values.finish) {
              return "Both start and end dates are required.";
            }
            if (values.start > values.finish) {
              return "Start date cannot be after end date.";
            }
            return null;
          },
          helpText: "Select a date range for the modelling plan.",
        },
      ],
    };
  }, [facilitiesToPlanModelling, isPending, renderersArray, isEmployeesPending, plan]);

  return (
    <>
      <FormDialog
        title={"Edit Modelling Plan"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={isFormPending}
        errorText={errorText}
      />
      <Notify
        isOpen={isNotifyOpen}
        onClose={() => setNotifyOpen(false)}
        autoHideDuration={4000}
        message={<T text="Modelling plan successfully edited!" />}
      />
    </>
  );
});

export default EditModellingPlanDialog;
