import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Tooltip } from "@mui/material";
import FacilityRender from "../../../facilities/FacilityRender";
import { Close, Edit, FileDownload, FileOpen } from "@mui/icons-material";
import Form from "../../../../components/Form";
import moment from "moment";
import Translator from "../../../../components/Translator";
import { Notify } from "~/components";
import { ModellingPlanButtons } from "../buttons";

/**
 * Строчка - полоска диаграммы ганта на плане моделирования.
 */
const ModellingPlanGantLine = observer(({ facility, plan, startOfPeriod, endOfPeriod, dayWidth }) => {
  const { editPlanFormConfig, resultFormConfig } = facility;

  const [isOutside, setIsOutside] = useState([false, false]);

  const [formType, setFormType] = useState("edit");
  const [fileVisible, setFileVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);
  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);

  const onOpenModal = useCallback(() => {
    setFormType("edit");
    setModalVisible(true);
  }, [setModalVisible, setFormType]);

  const onSetResult = useCallback(() => {
    setFormType("result");
    setModalVisible(true);
  }, [setFormType, setModalVisible]);

  const onCloseModal = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setModalVisible(false);
    },
    [setModalVisible]
  );

  const onSetShow = useCallback(() => {
    setFileVisible(true);
  }, [setFileVisible]);

  const onCloseShow = useCallback(() => {
    setFileVisible(false);
  }, [setFileVisible]);

  const onSubmit = useCallback(
    async (data) => {
      setIsFormPending(true);
      if (formType === "edit") {
        await facility.editModellingPlan({
          ...data,
          start: moment(data.start, "L").format("YYYY-MM-DD"),
          finish: moment(data.finish, "L").format("YYYY-MM-DD"),
        });
      } else if (formType === "result") {
        await facility.setModellingPlanResult(data);
        plan.setResult(data); // сразу же обновляем
      }
      setIsFormPending(false);
      onCloseModal();
      setSuccessSnackbarVisible(true); // показываем снекбар
    },
    [onCloseModal, formType, facility, plan]
  );

  const handleCloseSnackbar = useCallback(() => {
    setSuccessSnackbarVisible(false);
  }, []);

  // план из чужого филиала, этому пользователю детали недоступны.
  const isPlanAccessible = !!facility.branch;

  // Сколько нужно отступить слева
  const left = useMemo(() => {
    const x = moment(plan.start).diff(startOfPeriod, "days");
    if (x < 0) {
      setIsOutside((value) => [true, value[1]]);
      return 0;
    }
    return x * dayWidth || 0;
  }, [plan.start, startOfPeriod, dayWidth]);

  // Сколько нужно отступить справа
  const right = useMemo(() => {
    const x = endOfPeriod.diff(moment(plan.finish), "days");
    if (x < 0) {
      setIsOutside((value) => [value[0], true]);
      return 0;
    }
    return x * dayWidth || 0;
  }, [plan.finish, endOfPeriod, dayWidth]);

  // Длина плана, не должна быть больше длины периода
  const planVisibleLength = useMemo(() => {
    let l = plan.start;
    let r = plan.finish;
    if (left === 0) {
      l = startOfPeriod;
    }
    if (right === 0) {
      r = endOfPeriod;
    }
    return moment(r).diff(l, "days");
  }, [plan, startOfPeriod, endOfPeriod, left, right]);

  const color = useMemo(() => {
    if (!isPlanAccessible) return "gray";
    if (!plan.result) return "blue"; // если результат отсутствует, показываем как "не начатый" (синий)
    if (plan.isFinished) return "green"; // завершен
    return "blue"; // дефолтное значение, если не попадает в другие категории
  }, [plan.isFinished, plan.result, isPlanAccessible]);

  // Используем Tooltip для кнопок, если длина плана слишком маленькая
  const buttonsInTooltip = planVisibleLength < 4;

  const modellingPlanButtons = buttonsInTooltip ? null : (
    <ModellingPlanButtons plan={plan} facility={facility} />
  );

  const modellingPlanButtonsTooltip = buttonsInTooltip ? (
    <ModellingPlanButtons plan={plan} facility={facility} />
  ) : null;

  // Иначе с 0 длиной дня есть 2 рендера, выглядит плохо: полосы планов рисуются на всю ширину и после
  // ужимаются вторым рендером.
  if (!dayWidth) return;

  return (
    <Box
      key={facility.id}
      sx={{
        width: "100%",
        boxSizing: "border-box",
        borderBottom: (theme) => {
          return `0.0625rem solid ${theme.palette.grey[300]}`;
        },
      }}
    >
      <Box
        sx={{
          marginLeft: left > 0 ? `${left}px` : "-0.25rem",
          marginRight: right > 0 ? `${right}px` : "-0.25rem",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          minHeight: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            flex: 1,
            m: "0.25rem",
            alignItems: "center",
            padding: "0 0.5rem",
            boxSizing: "border-box",
            backgroundColor: (theme) => {
              return `${theme.palette[color].light}`;
            },
            color: (theme) => {
              return `${theme.palette[color].contrastText}`;
            },
            borderRadius: () => {
              let radius = "1rem";
              const [prev, next] = isOutside;
              if (prev) radius = "0 1rem 1rem 0";
              if (next) radius = "1rem 0 0 1rem";
              if (next && prev) radius = "";
              return radius;
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexShrink: 0,
              alignItems: "center",
              justifyContent: planVisibleLength > 0 ? "flex-start" : "center",
              overflow: "hidden",
              mt: 0.25,
              mb: 0.5,
            }}
          >
            {planVisibleLength >= 2 && !isOutside[0] && (
              <Box sx={{ ml: 1, mr: 1, color: (theme) => theme.palette.grey[800] }}>{plan.start.date()}</Box>
            )}
            {isPlanAccessible && planVisibleLength >= 1 && <Box sx={{ mr: 1, ml: 0.5 }}>{facility.branch?.icon}</Box>}
            {isPlanAccessible && (
              <FacilityRender
                isMinimal={true}
                isSmall={true}
                isName={planVisibleLength > 2}
                facility={facility}
                buttons={modellingPlanButtons || modellingPlanButtonsTooltip}
              />
            )}
          </Box>
          {/* TODO: кнопки нужно уносить внутрь */}
          {isPlanAccessible && modellingPlanButtons}
          {planVisibleLength >= 2 && !isOutside[1] && (
            <Box sx={{ m: 1, color: (theme) => theme.palette.grey[800] }}>{plan.finish.date()}</Box>
          )}
        </Box>
      </Box>
      {/* Вынести в компонент */}
      <Dialog open={modalVisible} onClose={onCloseModal}>
        <Form
          initialValues={{
            planId: `${plan.id}`,
            modellerId: `${plan.modeller.id}`,
            start: moment(plan.start).toDate(),
            finish: moment(plan.finish).toDate(),
          }}
          isPending={isFormPending}
          config={formType === "edit" ? editPlanFormConfig : resultFormConfig}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
        />
      </Dialog>
      {/* Вынести в компонент */}
      <Dialog maxWidth="sm" open={fileVisible} onClose={onCloseShow}>
        <DialogTitle>
          <Typography variant="h5">
            <Translator text={"Scan result"} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body">
            <Translator text={"Scan result link is presented below"} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!!plan.result && (
            <Button
              sx={{ overflowWrap: "anywhere", mr: "0.25rem" }}
              variant="outlined"
              href={plan.result.url}
              target="blank"
              color={"info"}
              startIcon={<FileOpen color={"info"} />}
            >
              <Typography variant="body">{plan.result.url}</Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={onCloseShow}
            target="blank"
            color={"error"}
            startIcon={<Close color={"error"} />}
          >
            <Typography variant="body">Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Notify
        isOpen={successSnackbarVisible}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        sx={{ marginTop: "1rem" }}
      >
        <Translator text="Link Uploaded Successfully" />
      </Notify>
    </Box>
  );
});

export default ModellingPlanGantLine;
