import { makeObservable, observable, computed, action } from "mobx";
import moment from "moment";
import Money from "./Money";
import Document from "./Document";
import { UserInfo } from "~/modules/employees/data/models";
import Category from "./Category";

/**
 * Расход.
 */
class Expense {
  @observable id;
  @observable createdAt;
  @observable scope;
  @observable category;
  @observable documents = [];
  @observable author;

  @observable contract;
  @observable shift;
  @observable facility;

  @observable store;
  @observable value;

  constructor(
    { id, author, createdAt, scope, category, value, documentsMeta, contract, shift, facility },
    store
  ) {
    makeObservable(this);
    this.store = store;

    this.id = id;
    this.createdAt = moment(createdAt);
    this.scope = scope;
    this.documents = documentsMeta.map((d) => new Document(d));
    this.value = new Money({ amount: value.amount, currency: value.currency });
    this.contract = contract;
    this.shift = shift;
    this.facility = facility;
    this.category = category;
    
    this.setAuthor(author);
    this.setCategory(category, scope);
  }

  /**
   * Установить автора.
   * @param {Object} author
   */
  @action setAuthor(author) {
    const knownAuthor = this.store.root.employeesStore.getEmployeeById(author.id);
    if (!knownAuthor) {
      this.author = new UserInfo(author);
    } else {
      this.author = knownAuthor;
    }
  }

  /**
   * Установить категорию.
   * @param {Object} category
   * @param {string} scope
   */
  @action setCategory(category, scope) {
    this.category = new Category({ ...category, scopes: [scope] });
  }

  /**
   * Является ли расходом объекта.
   */
  @computed get isFacilityExpense() {
    return this.scope === "facility";
  }

  /**
   * Является ли расходом контракта.
   */
  @computed get isContractExpense() {
    return this.scope === "contract";
  }

  /**
   * Является ли расходом смены.
   */
  @computed get isShiftExpense() {
    return this.scope === "shift";
  }

  /**
   * Ссылка на объект, к которому относится расход.
   */
  @computed get context() {
    if (this.isFacilityExpense) return this.facility;
    if (this.isContractExpense) return this.contract;
    if (this.isShiftExpense) return this.shift;
    return null;
  }

  /**
   * Обновить данные расхода.
   * @param {Object} data
   */
  @action update(data) {
    if (data.category) {
      this.setCategory(data.category, this.scope);
    }
    if (data.author) {
      this.setAuthor(data.author);
    }
    if (data.value) {
      this.value = new Money({ amount: data.value.amount, currency: data.value.currency });
    }
    if (data.documentsMeta) {
      this.documents = data.documentsMeta.map((d) => new Document(d));
    }
    if (data.createdAt) {
      this.createdAt = moment(data.createdAt);
    }
  }
}

export default Expense;
