import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { Translator as T } from "~/components";

/**
 * Переключатель размера периода.
 *
 * Варинты:
 *  - неделя,
 *  - месяц.
 */
const PeriodSizeSwitcher = observer(({ period, setPeriod }) => {
  const onChanged = useCallback(
    (event, newPeriod) => {
      if (!newPeriod) return;
      setPeriod(newPeriod);
    },
    [setPeriod]
  );

  return (
    <ToggleButtonGroup
      sx={{ mr: 1 }}
      value={period}
      exclusive
      onChange={onChanged}
      size="small"
      aria-label="text alignment"
    >
      <ToggleButton fullWidth value="month" aria-label="centered">
        <CalendarMonthIcon />
        <T text={"Month"} />
      </ToggleButton>
      <ToggleButton fullWidth value="week" aria-label="left aligned">
        <DateRangeIcon />
        <T text={"Week"} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

export default PeriodSizeSwitcher;
