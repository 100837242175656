import { computed, makeObservable, observable } from "mobx";

class ReportTotal {
  @observable store = null;
  @observable section = null;
  @observable name = null;
  @observable value = null;
  @observable columnName = null;

  constructor(props, store, section) {
    makeObservable(this);

    this.store = store;
    this.section = section;
    this.name = props.name;
    this.value = props.value;
    this.columnName = props.column;
  }

  @computed
  get column() {
    return this.section.getColumnByName(this.columnName);
  }

  @computed
  get output() {
    return {
      value: this.value,
      type: this.column.fieldType,
      name: this.name,
    };
  }
}

export default ReportTotal;
