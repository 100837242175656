import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { Invoice, Payment, PaymentMethod } from "./models";
import { InvoicesApiV0 } from "../api";

/**
 * Стор cчетов.
 *
 * Хранит счета отображаемые таблицей счетов и предоставляет методы работы со счетами.
 *
 */
class InvoiceStore {
  @observable methods = new Map(); // методы платежа
  @observable invoicesMap = new Map();
  @observable paymentsMap = new Map();

  @observable root = null;
  @observable api = null;
  @observable oldApi = null;

  @observable fetchInvoicesAbortController = null;
  @observable fetchPaymentsAbortController = null;

  @observable pending = false;
  @observable pendingPayments = false;
  @observable pendingInvoicesMap = new Map();

  @observable invoiceTotalCount = 0;
  @observable summary = {};

  constructor(root) {
    makeObservable(this);

    this.root = root;
    this.api = new InvoicesApiV0(root.authStore);

    // this.oldApi = this.root.api;
  }

  /**
   * Запросить возможные методы оплаты в филиале.
   */
  @action async fetchPaymentMethods(branch) {
    if (!branch) return;
    const data = await this.api.getPaymentMethods(branch.id);
    if (!data) return;
    this.methods.clear();
    runInAction(() => {
      data.forEach((m) => this.methods.set(`${m.id}`, new PaymentMethod(m, this)));
    });
    return this.methods;
  }

  /**
   * Вернуть экземпляр метода платежа по id.
   *
   * @param {integer} id идентификатор метода.
   */
  @action getPaymentMethod(id) {
    return this.methods.get(`${id}`);
  }

  /**
   * Создать новый счёт на оплату.
   *
   * @param {Conract} contract экземпляр контракта
   * @param {object} payload данные счёта
   * @returns
   */
  @action async createInvoice(contract, payload) {
    const created = await this.api.createInvoice(contract.id, payload);
    const invoice = new Invoice(created, this);
    contract.addInvoice(invoice);
    return invoice;
  }

  /**
   * Получить счета с пагинацией.
   *
   * @param {Branch} branch текущий филиал
   * @param {string} searchBy строка поиска
   * @param {object} pagination объект пагинации
   */
  @action async getInvoices(branch, searchBy, pagination, states, daterange) {
    const [invoicesData, totalCount] = await this.api.getInvoices(branch.id, searchBy, pagination, states, daterange);
    let invoices = [];
    runInAction(() => {
      invoices = this.processInvoices(invoicesData.invoices);
      this.invoiceTotalCount = totalCount;
      const { summary } = invoicesData;
      this.summary.amount = summary.amount;
      this.summary.paid = summary.paid;
      this.summary.debt = summary.debt;
    });
    return invoices;
  }

  /**
   * Вернуть методы платежа массивом.
   */
  @computed get paymentMethods() {
    return Array.from(this.methods.values());
  }

  // ----- старые методы

  @action addPayment(data) {
    const payment = new Payment(data, this);
    this.paymentsMap.set(payment.id, payment);
    return payment;
  }


  @action addInvoice(data) {
    const invoice = new Invoice(data, this);
    this.invoicesMap.set(invoice.id, invoice);
    return invoice;
  }



  @action getInvoiceById(id) {
    const invoice = this.invoicesMap.get(`${id}`);
    return invoice;
  }

  @action async addPaymentAsync(data) {
    const invoice = this.invoicesMap.get(`${data.invoiceId}`);
    if (invoice) {
      invoice.setPending(true);
      const paymentData = await this.api.addPayment(data);
      invoice.addPayment(paymentData);
      invoice.setPending(false);
    }
    return invoice;
  }

  @action getPaymentById(id) {
    const payment = this.paymentsMap.get(`${id}`);
    return payment;
  }

  // deprecated
  @action async fetchInvoices(branch, ids) {
    if (branch) {
      if (this.fetchInvoicesAbortController) {
        this.fetchInvoicesAbortController.abort();
        this.fetchInvoicesAbortController = null;
      }
      try {
        this.setPending(true);
        const invoicesData = await this.fetchInvoicesWithAbortControll(branch, ids);
        await this.processInvoices(invoicesData);
      } catch (error) {
        console.warn(error);
      }
      this.setPending();
    }
  }

  /**
   * Обработать в стор полученные от бекенда инвойсы.
   *
   * @param {Array} invoicesData
   */
  @action processInvoices(invoicesData) {
    this.invoicesMap.clear();
    const invoices = [];
    invoicesData.forEach((invoiceData) => {
      const invoice = this.addInvoice(invoiceData);
      invoices.push(invoice);
    });
    return invoices;
  }

  @action async fetchInvoicesWithAbortControll(branch, ids) {
    if (branch) {
      this.fetchInvoicesAbortController = new AbortController();
      this.fetchInvoicesAbortController.signal.addEventListener("abort", () => {
        throw new Error("Fetch aborted by the user", "AbortError");
      });
      return await this.api.getInvoicesForBranch(branch, ids);
    }
  }

  @action async fetchPayments(branch) {
    if (branch) {
      if (this.fetchPaymentsAbortController) {
        this.fetchPaymentsAbortController.abort();
        this.fetchPaymentsAbortController = null;
      }
      try {
        this.setPendingPayment(true);
        const paymentsData = await this.fetchPaymentsWithAbortControll(branch);
        await this.processPayments(paymentsData, branch);
      } catch (error) {
        console.warn(error);
      }
      this.setPendingPayment();
    }
  }

  @action async processPayments(paymentsData, branch) {
    this.paymentsMap.clear();
    const invoiceIds = [];
    paymentsData.forEach((paymenteData) => {
      const payment = this.addPayment(paymenteData);
      if (payment.invoiceId && !payment.invoice) {
        invoiceIds.push(payment.invoiceId);
      }
    });
    if (invoiceIds.length) {
      await this.fetchInvoices(branch, invoiceIds);
    }
  }

  @action async fetchPaymentsWithAbortControll(branch) {
    if (branch) {
      this.fetchPaymentsAbortController = new AbortController();
      this.fetchPaymentsAbortController.signal.addEventListener("abort", () => {
        throw new Error("Fetch aborted by the user", "AbortError");
      });
      return await this.api.getPaymentsForBranch(branch);
    }
  }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  @action async downloadInvoice(id) {
    try {
      const blob = await this.api.downloadInvoice({ id });
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = `invoice-${id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.warn(error);
    }
  }


  @action async deleteInvoiceByIdAsync(data) {
    try {
      await this.api.deleteInvoice(data);
      this.deleteInvoiceById(data.invoiceId);
    } catch (error) {
      throw error;
    }
  }

  @action
  async deleteInvoiceById(id) {
    this.invoicesMap.delete(`${id}`);
  }

  @action
  setPendingPayment(pending = false) {
    this.pendingPayment = pending;
  }

  @computed
  get isPending() {
    return this.pending || this.root.contractStore.isPending;
  }

  @computed
  get isPendingPayment() {
    return this.pending || this.pendingPayment;
  }

  @computed get invoicesArray() {
    return Array.from(this.invoicesMap.values()).slice(); // slice создаёт новый массив
  }

  @computed
  get paymentsArray() {
    return Array.from(this.paymentsMap.values());
  }

  @computed
  get paymentsArrayLength() {
    return this.paymentsArray.length;
  }

  @computed
  get contractIdsArray() {
    const ids = new Set();
    this.invoicesMap.forEach((invoice) => {
      ids.add(invoice.contractId);
    });
    return Array.from(ids);
  }

  @action async updateInvoiceAsync(invoiceId, payload) {
    const updatedInvoiceData = await this.api.updateInvoice(invoiceId, payload);

    runInAction(() => {
      const existingInvoice = this.invoicesMap.get(`${invoiceId}`);
      if (existingInvoice) {
        existingInvoice.update(updatedInvoiceData);
      } else {
        this.addInvoice(updatedInvoiceData);
      }

      this.invoicesMap = new Map(this.invoicesMap);
    });
  }

}

export default InvoiceStore;
