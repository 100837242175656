import React from "react";
import { observer } from "mobx-react";
import { alpha, styled } from "@mui/material/styles";
import { DataGridPremium as MuiGrid, gridClasses } from "@mui/x-data-grid-premium";

/**
 * Общая таблица для использования в системе.
 */
const DataGrid = observer(
  ({
    columns,
    rows,
    rowCount,
    loading,
    pagination,
    paginationMode,
    paginationModel,
    onPaginationModelChange,
    ...props
  }) => {
    const ODD_OPACITY = 0.2;

    const StyledMuiGrid = styled(MuiGrid)(({ theme }) => ({
      [`& .${gridClasses.row}.even`]: {
        // backgroundColor: theme.palette.grey[100],
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
        "&.Mui-selected": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          "&:hover": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
            ),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
            },
          },
        },
      },
      [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.grey[100],
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
        "&.Mui-selected": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          "&:hover": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
            ),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
            },
          },
        },
      },
    }));

    return (
      <StyledMuiGrid
        disableColumnMenu // TODO: убрать когда всегде уберу x-data-grid. Из-за двух гридов не работает
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        stickyHeader={true}
        loading={loading}
        sx={{
          ".MuiDataGrid-columnHeader": {
            background: "rgba(128,128,128,0.1)",
          },
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
        // autoPageSize={true}
        pageSizeOptions={[10, 20, 25, 50, 100]}
        pagination={pagination}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        autoHeight={false}
        showColumnVerticalBorder={true}
        showCellVerticalBorder={true}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
        {...props}
      />
    );
  }
);

export default DataGrid;
