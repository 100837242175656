import { ApiBase } from "~/api";

class InvoicesApiV0 extends ApiBase {
  /**
   * Запросить возможнные методы оплаты филиала.
   *
   * @param {integer} branchId идентификатор филиала.
   */
  async getPaymentMethods(branchId) {
    const url = `api/v0/branches/${branchId}/payments/methods`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Создать новый счёт к конракту.
   */
  async createInvoice(contractId, payload) {
    const url = `api/v0/contracts/${contractId}/payments/invoices`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Запрос инвойсов постранично.
   */
  async getInvoices(branchId, searchBy, pagination, states, daterange) {
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };
    const parameters = { ...backendPagination, searchBy, states };
    if (!!daterange[0]) {
      parameters["from"] = daterange[0].format("YYYY-MM-DD");
    }
    if (!!daterange[1]) {
      parameters["to"] = daterange[1].format("YYYY-MM-DD");
    }
    const url = `/api/v0/branches/${branchId}/payments/invoices`;
    const [data, headers] = await this.get(url, parameters);
    return [data, Number.parseInt(headers.get("Total-Count"))];
  }


  /**
   * Редактировать существующий счёт.
   *
   * @param {integer} invoiceId идентификатор инвойса
   * @param {object} payload данные для обновления
   */
  async updateInvoice(invoiceId, payload) {
    const url = `/api/v0/payments/invoices/${invoiceId}`;
    const [data] = await this.put(url, payload);
    return data;
  }

  async addPayment(data) {
    const url = `api/v0/payments/invoices/${data.invoiceId}/payments`;
    const [paymentData] = await this.post(url, data);
    return paymentData;
  }

  async getInvoicesForBranch(branchId, ids = []) {
    const url = `api/v0/branches/${branchId}/payments/invoices`;
    const params = ids.length ? { ids: JSON.stringify(ids) } : {};
    const [data] = await this.get(url, params);
    return data;
  }

  async getPaymentsForBranch(branchId) {
    const url = `api/v0/branches/${branchId}/payments/payments`;
    const [data] = await this.get(url);
    return data;
  }

  async downloadInvoice({ id }) {
    const url = `api/v0/payments/invoices/${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        [this.AUTHORIZATION_HEADER]: this.authStore.token,
        Accept: "application/pdf",
      },
    });

    const handledResponse = await this.handleError(response);
    return await handledResponse.blob();
  }

  async deleteInvoice({ invoiceId }) {
    const url = `api/v0/payments/invoices/${invoiceId}`;
    await this.request({
      method: "DELETE",
      url,
    });
  }
}

export default InvoicesApiV0;
