import React, { useState, useMemo, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Module } from "~/components";
import { ModellingViewsSwitcher } from "./components";

import { GantSchedule, ArchitectReport } from "./views";

/**
 * Модуль моделирования - рабочее место архитекторов.
 */
const ModellingModule = observer(() => {
  const [view, setView] = useState("schedule");
  const { employeesStore } = useStores();

  // Здесь нужно всех запросить по всем филиалам.
  const fetchAllSuboardinates = async () => {
    await employeesStore.fetchEmployees();
  };
  useLayoutEffect(() => {
    fetchAllSuboardinates();
  });

  const currentView = useMemo(() => {
    switch (view) {
      case "schedule":
        return <GantSchedule />;
      case "report":
        return <ArchitectReport />;
      default:
        return <GantSchedule />;
    }
  }, [view]);

  return (
    <Module>
      <ModellingViewsSwitcher view={view} setView={setView} />
      {currentView}
    </Module>
  );
});

export default ModellingModule;
