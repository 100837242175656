import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";

import { FormDialog } from "~/components/dialogs";
import { Translator as T } from "~/components";
import moment from "moment";

/**
 * Диалог завершения плана моделирования.
 */
const ResultModellingPlanDialog = observer(({ isOpen, onClose, facility, plan, setNotifyOpen }) => {
  const [errorText, setErrorText] = useState("");

  const handleSubmit = useCallback(
    async (data) => {
      if (!data.area || !/^[1-9]\d*$/.test(data.area)) {
        setErrorText("Area must be a positive integer.");
        return;
      }
      try {
        const requestData = {
          id: plan.id, // тут была проблема что у нас в ModellingResult используется поле id, а в Api.js используется planId, поэтому я везде сделал id
          url: data.url,
          area: data.area,
        };

        await facility.setModellingPlanResult(requestData);

        // Обновляем план результатом
        if (requestData.id) {
          plan.setResult(requestData);
        } else {
          throw new Error("Plan ID is missing in the request.");
        }

        setNotifyOpen(true);
        onClose && onClose();
      } catch (error) {
        console.error(error);
        setErrorText("Failed to set modelling result. Please try again.");
      }
    },
    [facility, plan, onClose, setNotifyOpen]
  );

  const config = useMemo(() => {
    return {
      submitText: "Submit",
      cancelText: "Cancel",
      fields: [
        {
          name: "planId",
          type: "hidden",
          initialValue: plan.id || 0,
        },
        {
          name: "url",
          title: "Result URL",
          type: "string",
          isRequired: true,
          validate: (value) => {
            if (!value) return "URL is required.";
            if (!/^https?:\/\//.test(value)) return "URL must start with http:// or https://.";
            return null;
          },
        },
        {
          name: "area",
          title: "Actual Area ",
          type: "string",
          isRequired: true,
          // initialValue: plan.area || 0,
          validate: (value) => {
            if (value === undefined || value === null) return "Area is required.";
            if (value <= 0) return "Area must be a positive number.";
            if (!Number.isInteger(Number(value))) return "Area must be an integer.";
            return null;
          },
        },
      ],
    };
  }, [plan]);

  return (
    <>
      <FormDialog
        title={"Set Modelling Result"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={facility?.isPending || false}
        errorText={errorText}
      />
    </>
  );
});

export default ResultModellingPlanDialog;
