import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { Dialog, DialogTitle } from "@mui/material";
import Translator from "~/components/Translator";
import Preloader from "~/components/Preloader";
import { Notify } from "~/common/components";

import Form from "~/components/Form";

import useStores from "~/hooks";

/**
 * Диалог создания нового контракта.
 */
const CreateContractDialog = observer(({ isVisible, setIsVisible, initialValues, postCreateFunc }) => {
  const [isPending, setIsPending] = useState(false);
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [created, setCreated] = useState(null);

  const { contractStore, branchStore, clientStore, rootStore } = useStores();

  // TODO: оба стейте свернуть в один
  const [formErrors, setFormErrors] = useState({});

  const { branch } = branchStore;

  const formConfig = {
    submitText: "Create",
    cancelText: "Cancel",
    fields: [
      {
        name: "quotationId",
        type: "hidden", // TODO: (ai) хидден должен быть не через тип, тип это тип данных. А это свойство
        initialValue: initialValues?.quotationId || 0,
      },
      {
        name: "isLead",
        title: "Is contract comes from incoming lead?",
        type: "boolean",
        isRequired: true,
        isReadOnly: initialValues?.isLead,
        initialValue: initialValues?.isLead || true,
      },
      {
        name: "number",
        title: "Contract #",
        type: "text",
        viewConfig: "input",
        isRequired: true,
        isReadOnly: false,
        validate: true,
        initialValue: initialValues?.number,
      },
      {
        name: "date",
        title: "Date",
        type: "date",
        disablePast: false,
        disableFuture: false,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "value",
        title: "Value without VAT",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: 1,
        step: 0.01,
        startAdornment: branch?.currency,
        isRequired: true,
        isReadOnly: false,
        validate: true,
        initialValue: initialValues?.value?.amount,
      },
      {
        name: "description",
        title: "Description",
        type: "text",
        multiline: true,
        isRequired: false,
        isReadOnly: false,
        validate: false,
        initialValue: initialValues?.description,
      },
      {
        name: "expensesPlan",
        title: "Expenses plan",
        type: "number",
        viewConfig: "input",
        inputMode: "decimal",
        min: 0,
        step: 1,
        startAdornment: branch?.currency,
        isRequired: true,
        isReadOnly: false,
        validate: true,
      },
      {
        name: "clientId",
        fakeName: "client",
        title: "Client",
        type: "client",
        withCreate: true,
        isRequired: true,
        isReadOnly: false,
        validate: true,
        loading: clientStore.isPending,
        options: [],
        initialValue: initialValues?.clientId,
      },
      {
        id: "documents",
        name: `documents`,
        title: "Documents",
        type: "file",
        multiple: true,
        isRequired: false,
        isReadOnly: false,
        validate: true,
      },
    ],
  };

  // Закрывать диалог только по клику в кнопку и никак не реагировать на клик в фон
  const closeDialog = useCallback(
    (e, reason) => {
      reason !== "backdropClick" && setIsVisible(false);
    },
    [setIsVisible]
  );

  // Сабмит формы и отправка данных на бекенд.
  const onSubmit = useCallback(
    async (data) => {
      setIsPending(true);
      setFormErrors({});
      try {
        if (!data.clientId || data.clientId === "create new") {
          const clientData = {
            name: data.clientName,
            description: data.clientDescription,
          };
          const client = await clientStore.createClient(branch, clientData);
          data.client = client.name;
          data.clientId = client.id;
        }
        const newContract = {
          ...data,
          branchId: branch.id,
          date: moment(data.date, "L").format("YYYY-MM-DD"),
          value: {
            amount: Number(data.value),
            currency: branch.currency,
          },
          expensesPlan: {
            amount: Number(data.expensesPlan),
            currency: branch.currency,
          },
        };
        const created = await contractStore.createContract(newContract);
        postCreateFunc && (await postCreateFunc(created));
        setCreated(created);
      } catch (error) {
        rootStore.setError("error", null, error && error.message);
      }
      setIsNotifyOpen(true);
      setIsPending(false);
      closeDialog();
    },
    [branch, clientStore, contractStore, rootStore, closeDialog, postCreateFunc]
  );

  return (
    <>
      <Dialog open={isVisible} onClose={closeDialog}>
        <DialogTitle sx={{ display: "flex", dlexDirection: "row", justifyContent: "space-between" }}>
          <Translator text={"Creating contract"} />
          <Preloader isPending={isPending} size={"2rem"} sx={{ justifyContent: "end" }} />
        </DialogTitle>
        <Form
          isPending={isPending}
          errors={formErrors}
          config={formConfig}
          onSubmit={onSubmit}
          onCancel={closeDialog}
          initialValues={{ ...initialValues, branchId: branch?.id }}
        />
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={`Contract created: `} /> {created?.number}
      </Notify>
    </>
  );
});

export default CreateContractDialog;
