const points = {
  branches: () => {
    return "api/v0/branches";
  },
  renderFacilities: ({ branchId }) => {
    return `api/v0/branches/${branchId}/modelling/facilities`;
  },
  scanningPlans: ({ branchId, withFinished, withResult, from, to }) => {
    const urlParams = new URLSearchParams();
    withFinished && urlParams.set("withFinished", withFinished);
    withResult && urlParams.set("withResult", withResult);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/branches/${branchId}/scanning/facilities?${urlParams}`;
  },

  // планы отрисовки
  renderingPlans: ({ branchId, from, to, withResulted, withPlanned }) => {
    const urlParams = new URLSearchParams();
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    withResulted && urlParams.set("withResulted", withResulted);
    withPlanned && urlParams.set("withPlanned", withPlanned);
    return `api/v0/branches/${branchId}/modelling/plans?${urlParams}`;
  },

  deleteScanPlan: ({ planId }) => {
    return `api/v0/facilities/scanning/plans/${planId}`;
  },
  setScanPlanStart: ({ planId }) => {
    return `api/v0/facilities/scanning/plans/${planId}/start`;
  },
  setScanPlanFinish: ({ planId }) => {
    return `api/v0/facilities/scanning/plans/${planId}/finish`;
  },
  translations: ({ lang }) => {
    return `api/v0/i18n/translations/${lang}`;
  },
  employeesForBranch: ({ branchId }) => {
    return `api/v0/branches/${branchId}/employees`;
  },
  client: ({ id }) => {
    return `api/v0/clients/${id}`;
  },
  facility: ({ id }) => {
    return `api/v0/facilities/${id}`;
  },
  addFacility: ({ contractId }) => {
    return `api/v0/contracts/${contractId}/facilities`;
  },
  invoice: ({ id }) => {
    return `api/v0/payments/invoices/${id}`;
  },
  invoices: ({ branchId, ids }) => {
    const urlParams = new URLSearchParams();
    ids && urlParams.set("ids", JSON.stringify(ids));
    return `api/v0/branches/${branchId}/payments/invoices?${urlParams}`;
  },
  deleteInvoice: ({ invoiceId }) => {
    return `api/v0/payments/invoices/${invoiceId}`;
  },
  payments: ({ branchId }) => {
    return `api/v0/branches/${branchId}/payments/payments`;
  },

  addPayment: ({ invoiceId }) => {
    return `api/v0/payments/invoices/${invoiceId}/payments`;
  },
  addScanPlan: ({ facilityId }) => {
    return `api/v0/facilities/${facilityId}/scanning-plan`;
  },

  getResultHistory: ({ resultId }) => {
    return `api/v0/facilities/scanning/results/${resultId}/history`;
  },
  getReport: ({ branchId, managerId, from, to }) => {
    const urlParams = new URLSearchParams();
    from && urlParams.set("from", from.toISOString());
    to && urlParams.set("to", to.toISOString());
    return `api/v0/branches/${branchId}/employees/managers/${managerId}/report?${urlParams}`;
  },
  getScannerReport: ({ branchId, scannerId, from, to }) => {
    const urlParams = new URLSearchParams();
    from && urlParams.set("from", from.toISOString());
    to && urlParams.set("to", to.toISOString());
    return `api/v0/branches/${branchId}/employees/scanners/${scannerId}/report?${urlParams}`;
  },
  getArchitectReport: ({ branchId, scannerId, from, to }) => {
    const urlParams = new URLSearchParams();
    from && urlParams.set("from", from.toISOString());
    to && urlParams.set("to", to.toISOString());
    return `api/v0/branches/${branchId}/employees/architects/${scannerId}/report?${urlParams}`;
  },
  getExpensesCategories: ({ branchId, category }) => {
    return `api/v0/branches/${branchId}/expenses/${category}-categories`;
  },
  setFacilityExpenses: ({ facilityId }) => {
    return `api/v0/facilities/${facilityId}/expenses`;
  },
  setShiftExpenses: ({ shiftId }) => {
    return `api/v0/working-shifts/${shiftId}/expenses`;
  },
  uploadDocument: () => {
    return `api/v0/documents`;
  },
  getDocumentInfo: ({ id }) => {
    return `api/v0/documents/${id}`;
  },
  getPhotoInfo: ({ id }) => {
    return `api/v0/photos/${id}`;
  },
  getDocumentBlob: ({ id }) => {
    return `api/v0/document-blobs/${id}`;
  },
  getPhotoBlob: ({ id }) => {
    return `api/v0/photo-blobs/${id}`;
  },
  setModelingManager: ({ contractId }) => {
    return `api/v0/contracts/${contractId}/modelling/modelling-manager`;
  },
  setNewModellingPlan: () => {
    return `api/v0/modelling/plans`;
  },
  editModellingPlan: ({ planId }) => {
    return `api/v0/modelling/plans/${planId}`;
  },
  setModellingPlanResult: ({ id }) => {
    return `api/v0/modelling/plans/${id}/result`;
  },
};

export default class Api {
  constructor(store) {
    this.rootStore = store;
    this.setErrorFunc = this.rootStore.setError;
    this.domain = "/";
  }

  getMiddlewareToken(html) {
    const regex = /"csrfmiddlewaretoken".+value="(.+)"/i;
    const match = html.match(regex);
    const token = match && match[1];
    return token;
  }

  async processResponse(response, type, accept, redirectError, refreshOnFail = false, authorization) {
    if (response.status === 401) {
      // this.logoutFunc(true);
      this.rootStore.authStore.removeCredentials();
      this.setErrorFunc("error", "Authentication", "Token expired. Please login again.");
      throw new Error("Token expired");
    } else if (response.status === 500) {
      throw new Error("Internal server error 500");
    }
    const headersObj = response && response.headers && Object.fromEntries(response.headers);
    if (type === "raw") {
      return response;
    }
    if (type === "text") {
      return await response.text();
    }
    try {
      const json = await response.json();
      if (authorization) {
        return { ...json, token: headersObj && headersObj.authorization };
      }
      return json;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async doFetch(url, method, fetchData, authorization, requestType = "json", accept, redirectError = false) {
    let contentType;
    let data = fetchData;
    if (requestType === "json") {
      contentType = "application/json";
      data = JSON.stringify(fetchData);
    }
    if (requestType === "form") {
      contentType = "application/x-www-form-urlencoded";
      data = new URLSearchParams();
      for (const name in fetchData) {
        data.append(name, fetchData[name]);
      }
    }
    if (requestType === "formData") {
      data = new FormData();
      for (const name in fetchData) {
        data.append(name, fetchData[name]);
      }
    }
    try {
      const formData = {
        credentials: "include",
        method,
        redirect: redirectError ? "error" : "follow",
        body: data,
      };

      if (contentType) {
        formData.headers = {
          "Content-Type": contentType,
          Accept: accept,
        };
      }
      if (authorization) {
        formData.headers = {
          Authorization: authorization,
        };
      } else {
        if (formData.headers) {
          formData.headers["Authorization"] = this.rootStore.authStore.token;
        } else {
          formData.headers = { Authorization: this.rootStore.authStore.token };
        }
      }

      const fetchedData = await fetch(url, formData);
      return fetchedData;
    } catch (error) {
      console.warn(error);
      throw error;
      // return error;
    }
  }

  async request({
    method,
    point,
    urlParams,
    data,
    requestType,
    type,
    accept,
    authorization,
    redirectError,
    refreshOnFail,
  }) {
    const pointUrl = points[point];
    const url = `${this.domain}${pointUrl(urlParams)}`;
    try {
      const response = await this.doFetch(url, method, data, authorization, requestType, accept, redirectError);
      return this.processResponse(response, type, accept, redirectError, refreshOnFail, authorization);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteInvoice({ invoiceId }) {
    return this.request({
      method: "DELETE",
      point: "deleteInvoice",
      type: "raw",
      urlParams: {
        invoiceId,
      },
    });
  }

  async setModellingPlanResult(data) {
    return this.request({
      method: "PUT",
      point: "setModellingPlanResult",
      type: "json",
      urlParams: {
        id: data.id,
      },
      data,
    });
  }

  async editModellingPlan(data) {
    return this.request({
      method: "PUT",
      point: "editModellingPlan",
      type: "json",
      urlParams: {
        planId: data.planId,
      },
      data,
    });
  }

  // TODO: в API модуля modelling
  async createModellingPlan(branchId, data) {
    return this.request({
      method: "POST",
      point: "setNewModellingPlan",
      type: "json",
      data,
    });
  }

  async setModelingManager(data) {
    const { contractId } = data;
    return this.request({
      method: "PUT",
      point: "setModelingManager",
      type: "json",
      urlParams: { contractId },
      data,
    });
  }

  async getExpensesCategories(branchId, category = "facility") {
    return this.request({
      method: "GET",
      point: "getExpensesCategories",
      type: "json",
      urlParams: { branchId, category },
    });
  }

  async setFacilityExpenses(data) {
    const { facilityId } = data;
    return this.request({
      method: "POST",
      point: "setFacilityExpenses",
      type: "json",
      urlParams: { facilityId },
      data,
    });
  }

  async getFacilityExpenses(facilityId) {
    return this.request({
      method: "GET",
      point: "setFacilityExpenses",
      type: "json",
      urlParams: { facilityId },
    });
  }

  async setShiftExpenses(data) {
    const { shiftId } = data;
    return this.request({
      method: "POST",
      point: "setShiftExpenses",
      type: "json",
      urlParams: { shiftId },
      data,
    });
  }

  async getShiftExpenses(shiftId) {
    return this.request({
      method: "GET",
      point: "setShiftExpenses",
      type: "json",
      urlParams: { shiftId },
    });
  }

  async setScanPlanStart({ planId, point, datetime }) {
    return this.request({
      method: "POST",
      point: "setScanPlanStart",
      type: "json",
      urlParams: { planId },
      data: {
        point,
        datetime,
      },
    });
  }

  async uploadDocument(data) {
    return this.request({
      method: "POST",
      point: "uploadDocument",
      requestType: "formData",
      data,
    });
  }

  async getDocument({ id }) {
    return this.request({
      method: "GET",
      point: "getDocumentBlob",
      type: "raw",
      urlParams: { id },
    });
  }

  async getPhoto({ id }) {
    return this.request({
      method: "GET",
      point: "getPhotoBlob",
      type: "raw",
      urlParams: { id },
    });
  }

  async getDocumentInfo({ id }) {
    return this.request({
      method: "GET",
      point: "getDocumentInfo",
      requestType: "json",
      urlParams: { id },
    });
  }

  async getPhotoInfo({ id }) {
    return this.request({
      method: "GET",
      point: "getPhotoInfo",
      requestType: "json",
      urlParams: { id },
    });
  }

  async setScanPlanFinish({ planId, point, datetime, stationsCount, mileage }) {
    return this.request({
      method: "POST",
      point: "setScanPlanFinish",
      type: "json",
      urlParams: { planId },
      data: {
        point,
        datetime,
        stationsCount,
        mileage,
      },
    });
  }

  async deleteScanPlan({ planId }) {
    return this.request({
      method: "DELETE",
      point: "deleteScanPlan",
      type: "raw",
      urlParams: { planId },
    });
  }

  async setLinkingResult(data) {
    return this.request({
      method: "PUT",
      point: "setScanResult",
      type: "json",
      urlParams: { planId: data.planId },
      data,
    });
  }

  async getShiftForRole(branchId, employeeId, role) {
    return this.request({
      method: "GET",
      point: "getShiftForRole",
      type: "json",
      urlParams: {
        branchId,
        employeeId,
        role,
      },
    });
  }

  async downloadReport({ branchId, managerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getReport",
      type: "raw",
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      urlParams: {
        branchId,
        managerId,
        from,
        to,
      },
    });
  }

  async downloadScannerReport({ branchId, scannerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getScannerReport",
      type: "raw",
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      urlParams: {
        branchId,
        scannerId,
        from,
        to,
      },
    });
  }
  async downloadArchitectReport({ branchId, scannerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getArchitectReport",
      type: "raw",
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      urlParams: {
        branchId,
        scannerId,
        from,
        to,
      },
    });
  }

  async downloadInvoice({ id }) {
    return this.request({
      method: "GET",
      point: "invoice",
      type: "raw",
      accept: "application/pdf",
      urlParams: {
        id,
      },
    });
  }

  async getReport({ branchId, managerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getReport",
      type: "json",
      urlParams: {
        branchId,
        managerId,
        from,
        to,
      },
    });
  }

  async getScannerReport({ branchId, scannerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getScannerReport",
      type: "json",
      urlParams: {
        branchId,
        scannerId,
        from,
        to,
      },
    });
  }

  async getArchitectReport({ branchId, scannerId, from, to }) {
    return this.request({
      method: "GET",
      point: "getArchitectReport",
      type: "json",
      urlParams: {
        branchId,
        scannerId,
        from,
        to,
      },
    });
  }

  async getResultHistory(resultId) {
    return this.request({
      method: "GET",
      point: "getResultHistory",
      type: "json",
      urlParams: {
        resultId,
      },
    });
  }

  async getScanningPlans({ branchId, from, to, withResult, withFinished }) {
    return this.request({
      method: "GET",
      point: "scanningPlans",
      type: "json",
      urlParams: {
        branchId,
        from,
        to,
        withResult,
        withFinished,
      },
    });
  }

  async getRenderingPlans({ branchId, from, to }) {
    return this.request({
      method: "GET",
      point: "renderingPlans",
      type: "json",
      urlParams: {
        branchId,
        from,
        to,
        withResulted: true,
        withPlanned: true,
      },
    });
  }

  async addContract(branchId, data, isLocal = false) {
    if (isLocal) {
      await this.sleep(1000);
      return {
        ...data,
        id: `contract-${Math.random()}`,
        branchId,
        state: "Created",
      };
    }

    return this.request({
      method: "POST",
      point: "contracts",
      type: "json",
      urlParams: {
        branchId,
      },
      data,
    });
  }

  async addPayment(data, isLocal = false) {
    if (isLocal) {
      await this.sleep(1000);
      return {
        ...data,
        id: `payment-${Math.random()}`,
      };
    }
    return this.request({
      method: "POST",
      point: "addPayment",
      type: "json",
      urlParams: {
        ...data,
      },
      data,
    });
  }

  async getClientById(id) {
    // await this.sleep(Math.random() * 3000);
    return this.request({
      method: "GET",
      point: "client",
      type: "json",
      urlParams: {
        id,
      },
    });
  }

  async getFacilityById(id) {
    // await this.sleep(Math.random() * 3000);
    return this.request({
      method: "GET",
      point: "facility",
      type: "json",
      urlParams: {
        id,
      },
    });
  }

  async getInvoiceById(id) {
    // await this.sleep(Math.random() * 3000);
    return this.request({
      method: "GET",
      point: "invoice",
      type: "json",
      urlParams: {
        id,
      },
    });
  }

  // TODO: вынести в АПИ-файл
  async getRenderFacilities({ branchId }) {
    return this.request({
      method: "GET",
      point: "renderFacilities",
      type: "json",
      urlParams: { branchId: 0, withPlanned: true, withResulted: true },
    });
  }

  async getBranches(isLocal = false) {
    return this.request({
      method: "GET",
      point: "branches",
      type: "json",
    });
  }

  // async getClientsForBranch(branchId, isLocal = false) {
  //   return this.request({
  //     method: "GET",
  //     point: "clientsForBranch",
  //     type: "json",
  //     urlParams: {
  //       branchId,
  //     },
  //   });
  // }

  async getInvoicesForBranch(branchId, ids, isLocal = false) {
    return this.request({
      method: "GET",
      point: "invoices",
      type: "json",
      urlParams: {
        branchId,
        ids,
      },
    });
  }

  async getPaymentsForBranch(branchId, isLocal = false) {
    return this.request({
      method: "GET",
      point: "payments",
      type: "json",
      urlParams: {
        branchId,
      },
    });
  }

  async getEmployeesForBranch(branchId) {
    return this.request({
      method: "GET",
      point: "employeesForBranch",
      type: "json",
      urlParams: { branchId }
    });
  }

  // TODO: ai: это не add, это create
  async addClientToBranch(branchId, data, isLocal = false) {
    if (isLocal) {
      await this.sleep(1000);
      return {
        id: `client-${Math.random()}`,
        ...data,
      };
    }
    return this.request({
      method: "POST",
      point: "clientsForBranch",
      type: "json",
      urlParams: {
        branchId,
      },
      data,
    });
  }

  async addScanPlan(data) {
    return this.request({
      method: "POST",
      point: "addScanPlan",
      type: "json",
      urlParams: {
        facilityId: data.facilityId,
      },
      data,
    });
  }

  async addFacility(data, isLocal = false) {
    if (isLocal) {
      await this.sleep(1000);
      return {
        id: `client-${Math.random()}`,
        ...data,
      };
    }
    return this.request({
      method: "POST",
      point: "addFacility",
      type: "json",
      urlParams: {
        contractId: data.contractId,
      },
      data,
    });
  }

  async getTranslationsForLang(lang, isLocal = false) {
    try {
      const data = this.request({
        method: "GET",
        point: "translations",
        type: "json",
        urlParams: {
          lang,
        },
      });
      return data;
    } catch (e) {
      console.warn(e);
      return {};
    }
  }

  async sleep(m) {
    return new Promise((r) => {
      return setTimeout(r, m);
    });
  }
}
