import { computed, makeObservable, observable } from "mobx";
import moment from "moment";
import Translator from "../components/Translator";

const TYPES = {
  "scanm2.clients.Client": "ReportClient",
  "scanm2.structure.Branch": "ReportBranch",
  "scanm2.users.Employee": "ReportUser",
  "compound.date": "date",
  "compound.datetime": "datetime",
  "compound.decimal": "number",
  "scalar.integer": "number",
  "scalar.string": "string",
  "scalar.boolean": "boolean",
};

class ReportColumn {
  @observable store = null;
  @observable index = null;
  @observable name = null;
  @observable type = null;

  constructor(props, store, index) {
    makeObservable(this);

    this.store = store;
    this.index = index;
    this.name = props.name;
    this.type = props.type;
  }

  @computed get fieldType() {
    return TYPES[this.type] || "string";
  }

  // TODO: этого не должно быть в модели!
  @computed get output() {
    if (this.fieldType === "boolean") {
      return {
        field: this.name,
        headerName: <Translator text={this.name} />,
        type: "boolean",
        minWidth: 75,
        valueGetter: (value, row) => value.value
        // renderCell если переопределить -- надо свой городить, просто value.value таблица не покажет
      };
    }

    return {
      field: this.name,
      flex: 1,
      headerName: <Translator text={this.name} />,
      type: this.fieldType,
      minWidth: 100,
      valueGetter: (value, row) => {
        if (this.fieldType === "date" || this.fieldType === "datetime") {
          return moment(row[this.name].value).toDate();
        }
        return row[this.name].value;
      },
      renderCell: ({ row }) => {
        if (this.fieldType === "date" || this.fieldType === "datetime") {
          return <Translator date={row[this.name].value} variant="compact" />;
        }
        return row[this.name].value;
      },
    };
  }
}

export default ReportColumn;
