import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

import Translator from "../components/Translator";
import Field from "./Field";
import { useTheme } from "@emotion/react";
import { ClientAutocompleteField } from "./fields";

const Form = observer((props) => {
  const {
    initialValues,
    noCancel = false,
    errors = {},
    alert,
    isPending = false,
    sx = {},
    payload,
    config,
    variant = "column",
    additionalContent,
    onSubmit,
    onCancel,
  } = props;

  const { submitText = "Submit", cancelText = "Cancel", formTitle, formText, fields = [], content } = config;

  const [values, setValues] = useState({});
  const [fileValues, setFileValues] = useState({});
  const [externalErrors, setExternalErrors] = useState(errors || {});

  // Синхронизация ошибок
  useEffect(() => {
    setExternalErrors(errors || {});
  }, [errors]);

  // Обработка отмены формы
  const onCancelForm = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onCancel) onCancel(payload);
    },
    [payload, onCancel]
  );

  const validateFields = useCallback(() => {
    const newErrors = {};

    fields.forEach((field) => {
      // проверяем, является ли поле обязательным
      if (field.isRequired && field.isRevealed) {
        if (!values[field.name] && !fileValues[field.name]) {
          newErrors[field.name] = `${field.title} is required.`;
        }
      }

      // чек validate
      if (typeof field.validate === "function") {
        const error = field.validate(values[field.name], values);
        if (error !== true && error !== null && error !== undefined) {
          newErrors[field.name] = error;
        }
      }
    });
    setExternalErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [fields, values, fileValues]);

  const onSubmitForm = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validateFields()) return;
      const data = new FormData(e.currentTarget);
      const formData = {
        ...values,
        ...Object.fromEntries(data.entries()),
        ...fileValues,
      };

      // Проверка: если это повтор, поле "reason" должно быть заполнено
      if (fields.some((f) => f.name === "reason" && f.required)) {
        if (!formData.reason?.trim()) {
          setExternalErrors((prevErrors) => ({
            ...prevErrors,
            reason: "Reason is required for a repeat plan.",
          }));
          return; // Прерываем отправку формы
        }
      }

      try {
        onSubmit({ ...formData, ...fileValues }, payload);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    [values, fileValues, payload, onSubmit, validateFields, fields]
  );

  // Обновление значений полей
  const setFieldValue = useCallback((name, value) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    setExternalErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  }, []);

  // Обновление значений файлов
  const setFileValue = useCallback((name, value) => {
    setFileValues((prevValues) => ({ ...prevValues, [name]: value }));
    setExternalErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  }, []);

  // Рендер полей формы
  const fieldsRender = useMemo(() => {
    const fieldsArray = [];
    fields.forEach((field) => {
      if (field.type === "client") {
        fieldsArray.push(
          <ClientAutocompleteField
            key={field.name}
            name={field.name}
            label={field.title}
            setValue={setFieldValue}
            withCreate={field.withCreate}
            isRequired={field.isRequired}
            initialValue={field.initialValue}
          />
        );
      } else {
        fieldsArray.push(
          <Field
            key={field.name}
            externalValidationError={externalErrors[field.name]}
            values={values}
            setFieldValue={setFieldValue}
            setFileValue={setFileValue}
            payload={initialValues}
            config={field}
            isDisabled={isPending}
            initialValue={field.initialValue}
          />
        );
      }
    });

    return fieldsArray;
  }, [isPending, values, fields, initialValues, externalErrors, setFieldValue, setFileValue]);

  const additionalStyle = useMemo(() => {
    return variant === "row" ? { display: "flex", flexDirection: "row" } : {};
  }, [variant]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        component="form"
        sx={{
          minWidth: "15rem",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          ...additionalStyle,
        }}
        onSubmit={onSubmitForm}
      >
        {formTitle && (
          <DialogTitle>
            <Translator text={formTitle} />
          </DialogTitle>
        )}
        <DialogContent sx={{ ...sx, ...additionalStyle }} dividers>
          {formText && (
            <DialogContentText>
              <Translator text={formText} />
            </DialogContentText>
          )}
          {additionalContent}
          <Box sx={{ mt: 1.5 }}>{content}</Box>
          {fieldsRender}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row" }}>
            {alert}
            {!mobile && <Box sx={{ minWidth: "0.25rem", flex: 1 }} />}
            <Box sx={{ display: "flex", mt: mobile ? "0.25rem" : 0, flexDirection: "row" }}>
              {!noCancel && (
                <Button color="error" variant="outlined" onClick={onCancelForm} disabled={isPending}>
                  <Translator text={cancelText} />
                </Button>
              )}
              <Button sx={{ ml: "0.25rem" }} color="success" variant="contained" type="submit" disabled={isPending}>
                <Translator text={submitText} />
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </>
  );
});

export default Form;
