import React, { useCallback } from "react";

import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import { Translator as T } from "~/components";

/**
 * Переключатель видов модуля счетов и платежей.
 */
const InvoicesPaymentsViewSwitcher = ({ view, setView }) => {
  const onViewChanged = useCallback(
    (event, newView) => {
      if (!newView) return;
      setView(newView);
    },
    [setView]
  );

  return (
    <ToggleButtonGroup
      fullWidth
      sx={{ margin: ".5rem 0 .5rem 0" }}
      value={view}
      exclusive
      onChange={onViewChanged}
      size="small"
      aria-label="text alignment"
    >
      <ToggleButton fullWidth value="invoices" aria-label="centered">
        <Tooltip title="Invoices">
          <>
            <RequestQuoteIcon />
            <T text={"Invoices"} />
          </>
        </Tooltip>
      </ToggleButton>
      <ToggleButton fullWidth value="payments" aria-label="left aligned">
        <Tooltip title="Payments">
          <>
            <PaidIcon />
            <T text={"Payments"} />
          </>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default InvoicesPaymentsViewSwitcher;
