import { action, computed, makeObservable, observable, toJS } from "mobx";
import ReportColumn from "./ReportColumn";
import ReportTotal from "./ReportTotal";

class ReportSection {
  @observable store = null;
  @observable name = null;
  @observable totals = [];
  @observable columns = [];
  @observable columnsByName = new Map();
  @observable values = [];

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.name = props.name;

    this.setTotals(props.totals);
    this.setColumns(props.columns);
    this.setValues(props.values);
  }

  @action setTotals(totalsArray = []) {
    this.totals.clear();
    totalsArray.forEach((totalData) => {
      const total = new ReportTotal(totalData, this.store, this);
      this.totals.push(total);
    });
  }

  @action getColumnByName(name) {
    return this.columnsByName.get(name);
  }

  @action getColumnByIndex(index) {
    return this.columns[index];
  }

  @action setColumns(columnsArray = []) {
    this.columns.clear();
    this.columnsByName.clear();
    columnsArray.forEach((columnData, index) => {
      const column = new ReportColumn(columnData, this.store, index);
      this.columns.push(column);
      this.columnsByName.set(column.name, column);
    });
  }

  @action setValues(linesArray = []) {
    this.values.clear();
    linesArray.forEach((line, key) => {
      const lineObject = {};
      line.forEach((cell, index) => {
        const column = this.getColumnByIndex(index);
        if (column) {
          lineObject[column.name] = {
            ...cell,
            type: column.type,
          };
        }
      });
      lineObject["id"] = key;
      this.values.push(lineObject);
    });
  }

  @computed get columnsConfig() {
    const columns = [];
    this.columns.forEach((column) => {
      columns.push(column.output);
    });
    return columns;
  }

  @computed get valueArray() {
    return toJS(this.values);
  }

  @computed get totalsArray() {
    return this.totals.map((total) => {
      return total.output;
    });
  }
}

export default ReportSection;
