import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Box, Card, Typography, IconButton, Tooltip } from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import moment from "moment";
import { Notify } from "~/common/components";

import { FacilityOnContractCard } from "~/modules/facilities/components";
import { CreateFacilityScanningPlanDialog } from "~/modules/scanning/components/dialogs";
import { ContractAreasInfo } from "~/modules/contracts/components";

import Translator from "~/components/Translator";

/**
 * Виджет списка объектов на карточке контракта.
 */
const FacilitiesWidget = observer(({ contract, openCreateContractFacilityDialog }) => {
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [currentFacility, setCurrentFacility] = useState(null);
  const [onDateSelect, setOnDateSelect] = useState(null); // Новый state для колбэка выбора даты

  // Контроль над диалогом добавления плана сканирования
  const [isCreateFacilityScanningPlanDialogOpen, setIsCreateFacilityScanningPlanDialogOpen] = useState(false);

  const { state, canAddFacility, allFacilities, totalArea, realArea } = contract;

  const openCreateFacilityScanningPlanDialog = useCallback((facility, dateSelectCallback = null) => {
    setCurrentFacility(facility);
    setOnDateSelect(() => dateSelectCallback);
    setIsCreateFacilityScanningPlanDialogOpen(true);
  }, []);

  const closeCreateFacilityScanningPlanDialog = useCallback((updatedFacility) => {
    setCurrentFacility(null);
    setOnDateSelect(null);
    setIsCreateFacilityScanningPlanDialogOpen(false);
  }, []);

  const content = useMemo(() => {
    if (allFacilities.length <= 0) {
      return (
        <Typography color="error" sx={{ fontSize: "1rem", marginTop: "1rem" }}>
          <Translator text={"No facilities"} />
        </Typography>
      );
    }
    const items = [];
    allFacilities.forEach((f, index) => {
      items.push(
        <FacilityOnContractCard
          status={state}
          facility={f}
          key={`${f.id}-${index}`} // Генерация уникального ключа
          openCreateScanningPlanDialog={openCreateFacilityScanningPlanDialog}
        />
      );
    });

    return items;
  }, [allFacilities, state, openCreateFacilityScanningPlanDialog]);

  return (
    <Card sx={{ m: 1, p: 1, pr: 0, display: "flex", flex: 1, flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>{content}</Box>
      <Box
        sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
      >
        <ContractAreasInfo total={totalArea} actual={realArea} />
        <Tooltip title={"Add facility"} arrow>
          <Box>
            <IconButton
              key={"addFacility"}
              disabled={!canAddFacility}
              variant="contained"
              color="info"
              onClick={() => openCreateContractFacilityDialog()}
            >
              <AddBusinessIcon fontSize={"large"} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      {/* TODO: не здесь он должен быть */}
      <CreateFacilityScanningPlanDialog
        facility={currentFacility} // Передаем текущий объект facility из состояния
        isOpen={isCreateFacilityScanningPlanDialogOpen} // Управление открытием/закрытием
        onClose={closeCreateFacilityScanningPlanDialog} // Закрытие диалога
        initialData={{
          isRepeat: !!currentFacility?.latestScanningPlan, // Если есть планы - это повторный выезд
          start: moment().add(1, "day").startOf("hour").toISOString(),
          finish: moment().add(1, "day").add(2, "hours").toISOString(),
        }}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setIsNotifyOpen(false)}>
        <Translator text={"Facility created"} />
      </Notify>
    </Card>
  );
});

export default FacilitiesWidget;
