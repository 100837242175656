import { observer } from "mobx-react";
import { useState, useCallback } from "react";

import { Box, Button, Autocomplete, TextField, FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useStores from "~/hooks";
import { useDebounceCallback } from "usehooks-ts";
import { CreateClientDialog } from "~/modules/clients/components/dialogs";

/**
 * Инпут клиента с поиском на бекенде и опциональной кнопкой создания
 * нового.
 *
 * @param {object} initialValue начальное значение, здесь объект т.к. нужны id и label
 */
const ClientAutocompleteField = observer(
  ({ label, isRequired = false, withCreate, name, setValue: setFormValue, initialValue = {} }) => {
    const { clientStore, branchStore, languageStore } = useStores();

    let client;
    const { id, label: clientLabel } = initialValue;
    if (!!id && !!clientLabel) {
      client = { id, label: clientLabel };
      setFormValue(name, id);
    }
    const [value, setValue] = useState(client);

    const [isCreatingDialogOpen, setIsCreatingDialogOpen] = useState(false);

    const onChange = useCallback(
      (event, newValue) => {
        if (newValue) {
          setValue(newValue);
          setFormValue(name, newValue.id);
        } else {
          setValue();
        }
      },
      [name, setFormValue]
    );

    // Поискать на бекенде
    const onInputChange = useDebounceCallback((event, inputValue) => {
      if (inputValue.length < 3) return;
      const dofetch = async () => {
        await clientStore.findClientsInBranch(branchStore.branch, inputValue);
      };
      dofetch();
    }, 500);

    // обработка кнопки открытия диалога
    const handleClickNewClientButton = useCallback((event) => {
      setIsCreatingDialogOpen(true);
    }, []);

    // Закрыть диалог
    const closeCreateClientDialog = useCallback(() => {
      setIsCreatingDialogOpen(false);
    }, []);

    // Callback на создание клиента
    const onClientCreated = useCallback((client) => {
      setValue({ label: client.label, id: client.id });
    }, []);

    const isOptionEqualToValue = useCallback((option, value) => {
      if (!value) return false;
      return option.id === value.id;
    }, []);

    const options = clientStore.foundClientsArray || [];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 0.25,
          mt: 0.25,
        }}
      >
        <FormControl margin="dense" fullWidth>
          <Autocomplete
            value={value || null}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={(x) => x}
            disablePortal
            sx={{ pr: 1 }}
            fullWidth
            renderInput={(params) => <TextField {...params} required={isRequired} id={name} label={label} />}
            noOptionsText={languageStore.translate({
              text: "Nothing found, or there are too many results. Please refine your search query.",
            })}
          />
          <TextField value={value?.id || ""} sx={{ display: "none" }} hidden name={name} id={name} />
        </FormControl>
        {withCreate && (
          <>
            <Button
              onClick={handleClickNewClientButton}
              variant="contained"
              startIcon={<AddIcon />}
              size="small"
              sx={{ mt: 0.5 }}
            >
              New client
            </Button>
            <CreateClientDialog
              isOpen={isCreatingDialogOpen}
              onClose={closeCreateClientDialog}
              onCreated={onClientCreated}
            />
          </>
        )}
      </Box>
    );
  }
);

export default ClientAutocompleteField;
