import React, { useCallback, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { TextField } from "@mui/material";

/**
 * Field component for selecting a date range in Form using DateRangePicker
 *
 * если нет начального значения нужно передавать [null, null]
 */
const DateRangeField = ({ setValue, isRequired, initialValue = { start: null, finish: null } }) => {
  const [error, setError] = useState(null);

  const handleAccept = useCallback(
    (newValue) => {
      const [start, finish] = newValue;

      if (isRequired) {
        if (!start || !finish) {
          setError("Both dates are required");
          return;
        }
        setError(null);
      }

      setValue("start", start?.isValid() ? start.toDate() : null);
      setValue("finish", finish?.isValid() ? finish.toDate() : null);
    },
    [isRequired, setValue]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        value={[initialValue.start, initialValue.finish]}
        onChange={handleAccept}
        localeText={{ start: "Start Date", end: "End Date" }}
        sx={{ width: "100%", mt: 1, zIndex: 3000 }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} error={!!error} helperText={error} fullWidth margin="dense" />
            <TextField {...endProps} error={!!error} helperText={error} fullWidth margin="dense" />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangeField;
