import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";

import { Box, Toolbar, ToggleButtonGroup, ToggleButton, Tooltip, IconButton } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Module, DateRangePicker, SearchField, Translator } from "~/components";
import useStores from "~/hooks";

import { ContractsBoard, ContractsTable } from "./views";
import { StatesSelect, IsLeadSelect } from "./components";

/**
 * Модуль контрактов.
 *
 * Есть переключение между таблицей всех контрактов и доской текущих.
 */
const ContractsModule = observer(() => {
  const { contractStore, branchStore } = useStores();
  const { branch } = branchStore;

  const [searchBy, setSearchBy] = useState("");
  const [view, setView] = useState("table");
  const [daterange, setDaterange] = useState([null, null]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedLeadOptions, setSelectedLeadOptions] = useState([]);
  const [refreshPls, setRefreshPls] = useState(false);

  useEffect(() => {
    const init = async () => {
      await contractStore.getStatuses(branch.id);
    };
    if (branch) init();
  }, [branch, contractStore, searchBy]);

  // Сделать поиск по активным контрактам
  const onSearch = useCallback((value) => {
    if (value.length > 2) setSearchBy(value);
    else setSearchBy("");
  }, []);

  // изменить представление, по умолчанию - таблица
  const onViewChanged = useCallback(
    (event, newView) => {
      if (newView === view) return;
      if (newView === null) return;
      setView(newView);
    },
    [view]
  );

  const onAcceptDaterange = useCallback((value) => setDaterange(value), []);

  const onSelectStates = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedStates(value);
  }, []);

  const onSelectLeadOptions = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedLeadOptions(value);
  }, []);

  const handleRefresh = useCallback((e) => {
    setRefreshPls((prev) => !prev);
  }, []);

  return (
    <Module>
      <Toolbar disableGutters={true} sx={{ mt: 0.5 }}>
        <Tooltip arrow title={<Translator text="Summary amount" />}>
          <Box sx={{ ml: 1, mr: 1, fontWeight: "bold", fontSize: "large", minWidth: 125 }}>
            <Translator number={contractStore.summary.amount || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <SearchField label={<Translator text={"Search contracts"} />} onSearch={onSearch} />
        {view === "table" && (
          <IsLeadSelect options={["Lead", "Not lead"]} value={selectedLeadOptions} onSelect={onSelectLeadOptions} />
        )}
        {view === "table" && (
          <StatesSelect
            options={contractStore.statuses.map((s) => [s.slug, s.label])}
            value={selectedStates}
            onSelect={onSelectStates}
          />
        )}
        {view === "table" && <DateRangePicker onAccept={onAcceptDaterange} />}
        <ToggleButtonGroup
          sx={{ marginLeft: "1rem" }}
          value={view}
          exclusive
          onChange={onViewChanged}
          size="large"
          aria-label="text alignment"
        >
          <ToggleButton value="table" aria-label="centered">
            <Tooltip title="View as table">
              <TableRowsOutlinedIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="board" aria-label="left aligned">
            <Tooltip title="View as board">
              <DashboardOutlinedIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton color="primary" onClick={handleRefresh} disabled={contractStore.isPending}>
          <Tooltip title="Refresh">
            <RefreshIcon />
          </Tooltip>
        </IconButton>
      </Toolbar>
      {view === "board" && <ContractsBoard searchBy={searchBy} refreshPls={refreshPls} />}
      {view === "table" && (
        <ContractsTable
          searchBy={searchBy}
          daterange={daterange}
          selectedStates={selectedStates}
          selectedLeadOptions={selectedLeadOptions}
          refreshPls={refreshPls}
        />
      )}
    </Module>
  );
});

export default ContractsModule;
