import React, { useCallback, useState } from "react";
import { observer } from "mobx-react"; 
import { Button, Popover, Box } from "@mui/material"; 
import { styled } from "@mui/material/styles";
import FacilityIcon from "./FacilityIcon"; 
import FacilityInfoCard from "./FacilityInfoCard"; 

// Позже в тикете перепишу чтобы использовался Notify при успешном рендеринге
const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#edf4fb",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #1177d3",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}));

const FacilityButton = observer(({ facility, variant, disableClick, buttons }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCompact = variant === "compact";

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        size="small"
        startIcon={<FacilityIcon kind={facility.kind} />}
      >
        {!isCompact && facility.label}
      </Button>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 1 }}>
          <FacilityInfoCard disableClick={disableClick} facility={facility} buttons={buttons} />
        </Box>
      </StyledPopover>
    </>
  );
});

export default FacilityButton;
