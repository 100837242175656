import React, { useMemo, useCallback, useEffect } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, IconButton, Collapse, Alert, AlertTitle } from "@mui/material";

import Translator from "./components/Translator";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

import GlobalErrorBoundary from "./workspace/errors/GlobalErrorBoundary";
import {
  RequestPage,
  CalendarMonth,
  Close,
  ManageAccountsRounded,
  Payments as PaymentsIcon,
  Assessment,
  ShoppingCartCheckoutOutlined,
  ViewInAr,
} from "@mui/icons-material";
import { observer } from "mobx-react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./App.css";
import ContractsModule from "./modules/contracts/ContractsModule";
import Employees from "./views/Employees";

import useStores from "~/hooks";

// import Preloader from "./components/Preloader";
import Scanner from "./modules/scanning/ScanningWorkspace";
import Reports from "./views/Reports";
import ScannerReport from "./views/ScannerReport";
import ScannerSchedule from "./views/ScannerSchedule";
import { ExpensesModule } from "./modules/expenses";
import { AnalyticsModule } from "./modules/analytics/views";
import { CommercialOffersModule } from "./modules/commercial_offers";
import { ScannersWorkingShiftsModule } from "./modules/scanners_working_shifts";
import { ModellingModule } from "~/modules/modelling";
import { InvoicesPaymentsModule } from "~/modules/invoices_payments";

// ----

import { LoginPage } from "~/modules/auth/pages";
import { Workspace } from "~/common/components";

import magic from "~/magic";
// ----

const App = observer(() => {
  magic();

  const { rootStore, authStore, workspaceStore, languageStore, employeesStore } = useStores();
  const { error, errorText, errorTitle } = rootStore;

  const { theme, mode, availableModules } = workspaceStore;
  const { branch } = rootStore.branchStore;

  useEffect(() => {
    const effect = async () => {
      await languageStore.init();
      if (!branch) return;
      await rootStore.init();
      await employeesStore.fetchEmployees(branch.id);
    };
    effect();
  }, [branch, languageStore, rootStore, employeesStore]);

  // Модули доступные текущему пользователю. Чтобы добавить новый модуль в систему нужно:
  // - Добавить его здесь в карту
  // - Добавить его в WorkspaceStore в modules и указать требуемое разрешение
  const mobuleBySlugMap = useMemo(() => {
    return {
      // analytics: {
      //   title: <Translator text={"Analytics"} />,
      //   icon: <AnalyticsIcon fontSize="large" />,
      //   component: <AnalyticsModule slug="analytics-dashboard" />,
      // },
      "commercial-offers": {
        title: <Translator text={"Commercial Offers"} />,
        icon: <ShoppingCartCheckoutOutlined fontSize="large" />,
        component: <CommercialOffersModule slug={"Commercial offers"} />,
      },
      contracts: {
        title: <Translator text={"Contracts"} />,
        icon: <RequestPage fontSize="large" />,
        component: <ContractsModule slug={"contracts"} />,
      },
      "scanner-schedule": {
        title: <Translator text={"Scanner schedule"} />,
        icon: <CalendarMonth fontSize="large" />,
        component: <ScannerSchedule slug="scanner-schedule" />,
      },
      invoices: {
        title: <Translator text={"Invoices and payments"} />,
        icon: <PaymentsIcon fontSize="large" />,
        component: <InvoicesPaymentsModule slug="invoices and payments" />,
      },
      // facilities: {
      //   title: <Translator text={"Facilities"} />,
      //   icon: <></>,
      //   component: <Facilities slug={"facilities"} />,
      // },
      // clients: {
      //   title: <Translator text={"Clients"} />,
      //   icon: <Groups fontSize="large" />,
      //   component: <ClientsTable slug={"clients"} />,
      // },
      workers: {
        title: <Translator text={"Workers"} />,
        icon: <ManageAccountsRounded fontSize="large" />,
        component: <Employees slug={"workers"} />,
      },
      reports: {
        title: <Translator text={"Sales manager report"} />,
        icon: <Assessment fontSize="large" />,
        component: <Reports slug="reports" />,
      },
      "scanner-report": {
        title: <Translator text={"Scanner report"} />,
        icon: <Assessment fontSize="large" />,
        component: <ScannerReport slug="scanner-report" />,
      },
      "scanners-working-shifts": {
        title: <Translator text={"Scanner's working shifts"} />,
        icon: <EngineeringIcon fontSize="large" />,
        component: <ScannersWorkingShiftsModule slug="scanners-working-shifts" />,
      },
      scanner: {
        title: <Translator text={"Scanning workspace"} />,
        icon: <CameraswitchIcon fontSize="large" />,
        component: <Scanner slug="scanner" />,
      },
      "modeller-workspace": {
        title: <Translator text={"Modeller's workspace"} />,
        icon: <ViewInAr fontSize="large" />,
        component: <ModellingModule slug="modeller-schedule" />,
        isMultiBranch: true,
      },
      expenses: {
        title: <Translator text={"Expenses"} />,
        icon: <MoneyOffIcon fontSize="large" />,
        component: <ExpensesModule slug="expenses-list" />,
      },
    };
  }, []);

  const modules = useMemo(() => {
    const modules = {};
    for (const m of availableModules) {
      if (m.slug in mobuleBySlugMap) modules[m.slug] = { slug: m.slug, ...mobuleBySlugMap[m.slug] };
    }
    return modules;
  }, [availableModules, mobuleBySlugMap]);

  // на тёмной системной теме покажи тймную сразу
  // const isDark = useMediaQuery("(prefers-color-scheme: dark)");

  // На мобилке ли мы?
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDismissError = useCallback(() => rootStore.setError(), [rootStore]);

  const renderError = useMemo(() => {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "0.25rem",
          zIndex: 2000,
        }}
      >
        <Collapse in={!!error}>
          <Alert
            severity={error || "error"}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={onDismissError}>
                <Close />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {!!errorTitle && (
              <AlertTitle>
                <Translator text={errorTitle} />
              </AlertTitle>
            )}
            <Translator text={errorText} />
          </Alert>
        </Collapse>
      </Box>
    );
  }, [error, onDismissError, errorTitle, errorText]);

  // Если вход не выполнени - отдать страницу логина
  if (!authStore.isLoggedIn) {
    return <LoginPage store={authStore} theme={theme} renderError={renderError} rootStore={rootStore} />;
  }

  // TODO: А вот на мобилке надо показывать меню и Drawer
  return (
    <GlobalErrorBoundary>
      <Workspace modules={modules} appearance={{ theme, mode, isMobile }} renderError={renderError} />;
    </GlobalErrorBoundary>
  );
});

export default App;
