import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";

import { Box, Button, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { AttachMoney, PriceCheck } from "@mui/icons-material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import PaymentsIcon from "@mui/icons-material/Payments";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

import Translator from "~/components/Translator";

// TODO написать свой и заменить
// import { PaymentsGrid } from "../../../modules/payments";

/**
 * Цветной индикатор состояния расчётов по контракту.
 *
 * По клику откроет информацию о платежах.
 */
const PaymentsBar = observer(({ contract, openCreateInvoiceDialog }) => {
  const { invoices, paymentData, canAddInvoice } = contract;
  const { cost, invoiceTotal, payedTotal, currency } = paymentData;

  const [isPaymentsHistoryVisible, setIsPaymentsHistoryVisible] = useState(false);

  const showPaymentsHistory = useCallback(() => {
    setIsPaymentsHistoryVisible(true);
  }, []);

  const hidePaymentsHistory = useCallback(() => {
    setIsPaymentsHistoryVisible(false);
  }, []);

  // TODO: ради одного раза styled? можно проще
  const PaymentsHistoryDialog = styled(Dialog)({
    ".MuiPaper-root": { maxWidth: "80vw", height: "80vh" },
  });

  return (
    <>
      <Box sx={{ flexDirection: "row", display: "flex", alignItems: "end", cursor: "pointer" }}>
        <Box sx={{ flexDirection: "column", display: "flex", flex: 1 }}>
          <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <Tooltip arrow title={<Translator text={"View payments history"} />}>
              <LinearProgress
                sx={{
                  flex: 1,
                  height: "1rem",
                  borderRadius: "0.25rem",
                  [`& .${linearProgressClasses.bar1Buffer}`]: {
                    backgroundColor: (theme) => {
                      return theme.palette.success.light;
                    },
                  },
                  [`& .${linearProgressClasses.bar2Buffer}`]: {
                    backgroundColor: (theme) => {
                      return theme.palette.warning.light;
                    },
                  },
                  [`& .${linearProgressClasses.dashed}`]: {
                    backgroundColor: (theme) => {
                      return theme.palette.grey["400"];
                    },
                    opacity: 1,
                    animation: "none",
                    backgroundImage: "none",
                  },
                }}
                variant="buffer"
                color={"info"}
                valueBuffer={(invoiceTotal / cost) * 100}
                value={(payedTotal / cost) * 100}
                onClick={showPaymentsHistory}
              />
            </Tooltip>
          </Box>
          <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between", mt: 0.5 }}>
            {/* Сколько оплачено */}
            <Tooltip arrow title={<Translator text="Payed" />}>
              <Box sx={{ flexDirection: "row", display: "flex", alignItems: "flex-end" }}>
                <PriceCheck color="success" sx={{ mr: 0.5 }} />
                {payedTotal !== undefined && <Translator number={payedTotal} currency={currency} />}
              </Box>
            </Tooltip>
            {/* Объём контракта */}
            <Tooltip arrow title={<Translator text="Contract value without VAT" />}>
              <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                <Box sx={{ flexDirection: "column", display: "flex", mr: 0.5 }}>
                  <AttachMoney color="info" />
                </Box>
                <Box>{cost !== undefined && <Translator number={cost} currency={currency} />}</Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {/* Кнопка добавления счёта к контракту */}
        <Tooltip arrow title={<Translator text="Add invoice" />}>
          <IconButton
            variant="contained"
            color="info"
            disabled={!canAddInvoice}
            onClick={() => openCreateInvoiceDialog()}
          >
            <PaymentsIcon fontSize={"large"} />
          </IconButton>
        </Tooltip>
        {/* </Box> */}
      </Box>

      {/* Payments modal  */}
      <PaymentsHistoryDialog open={isPaymentsHistoryVisible} onClose={hidePaymentsHistory}>
        <DialogTitle
          sx={{
            display: "flex",
            dlexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Translator text="Payments under the contract" />
          <IconButton onClick={hidePaymentsHistory}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <PaymentsGrid invoices={invoices} /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={hidePaymentsHistory}>
            <Translator text="Close" />
          </Button>
        </DialogActions>
      </PaymentsHistoryDialog>
    </>
  );
});

export default PaymentsBar;
