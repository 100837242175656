import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, ListItem, ListItemAvatar, ListItemText, useTheme, Tooltip } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

import Translator from "../../../components/Translator";
import { CalendarMonth, DocumentScanner, House, Work } from "@mui/icons-material";
import { DataGrid } from "~/components";
import FileList from "~/components/FileList";

/**
 * Отображение расходов по контракту.
 *
 * Показывает диаграмму и список расходов списком. Доступен через контекстное меню контракта.
 */
const ContractExpenses = observer(({ contract, isTiny, onClick }) => {
  const theme = useTheme();

  const { allExpenses: expenses, currency, expensesPlanTotal } = contract;

  const [[contractTotalExpense, facilityTotalExpense, shiftTotalExpense]] = useMemo(() => {
    let contractTotalExpense = 0;
    let facilityTotalExpense = 0;
    let shiftTotalExpense = 0;
    const shiftExpenses = [];
    const contractExpenses = [];
    const facilityExpenses = [];
    for (const expense of expenses) {
      if (expense.isContractExpense) {
        contractExpenses.push(expense);
        contractTotalExpense += expense.value.amount;
      } else if (expense.isFacilityExpense) {
        facilityExpenses.push(expense);
        facilityTotalExpense += expense.value.amount;
      } else if (expense.isShiftExpense) {
        shiftExpenses.push(expense);
        shiftTotalExpense += expense.value.amount;
      }
    }
    return [
      [contractTotalExpense, facilityTotalExpense, shiftTotalExpense],
      [contractExpenses, facilityExpenses, shiftExpenses],
    ];
  }, [expenses]);

  const data = useMemo(() => {
    const array = [];
    const result = [];

    if (contractTotalExpense) {
      array.push({
        x: "Contract",
        colorSlug: "success",
        itemIcon: <DocumentScanner color="success" />,
        label: <Translator text={"Contract"} />,
        y: contractTotalExpense,
        color: theme.palette.success.light,
      });
    }
    if (shiftTotalExpense) {
      array.push({
        x: "Shift",
        colorSlug: "warning",
        itemIcon: <Work color="warning" />,
        label: <Translator text={"Shift"} />,
        y: shiftTotalExpense,
        color: theme.palette.warning.light,
      });
    }
    if (facilityTotalExpense) {
      array.push({
        x: "Facility",
        colorSlug: "error",
        itemIcon: <House color="error" />,
        label: <Translator text={"Facility"} />,
        y: facilityTotalExpense,
        color: theme.palette.error.light,
      });
    }

    result.push({ id: <Translator text={"Fact"} />, data: array });
    if (expensesPlanTotal > 0) {
      result.push({
        id: "",
        label: <Translator text={"Plan"} />,
        data: [
          {
            x: <Translator text={"Plan"} />,
            colorSlug: "info",
            itemIcon: <CalendarMonth color="info" />,
            label: <Translator text={"Plan"} />,
            y: Number(expensesPlanTotal),
            color: theme.palette.info.light,
          },
        ],
      });
    }
    return result;
  }, [expensesPlanTotal, theme, contractTotalExpense, facilityTotalExpense, shiftTotalExpense]);

  // Суммарный расход числом
  const expenseFact = useMemo(
    () => contractTotalExpense + facilityTotalExpense + shiftTotalExpense,
    [contractTotalExpense, facilityTotalExpense, shiftTotalExpense]
  );

  const CenteredMetric = ({ center }) => {
    return (
      <text
        x={center[0]}
        y={center[1]}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          fill: theme.palette.text.primary,
        }}
      >
        {<Translator number={expenseFact} currency={currency} />}
      </text>
    );
  };

  if (isTiny) {
    return (
      <Tooltip arrow title={<Translator text={"View contract expenses"} />}>
        <ListItem dense={false} sx={{ p: 0 }} onClick={onClick}>
          <ListItemAvatar>
            <Box sx={{ width: "3.5rem", height: "3.5rem" }}>
              <ResponsiveRadialBar
                data={data}
                innerRadius={0.3}
                radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                circularAxisOuter={{
                  tickSize: 5,
                  tickPadding: 12,
                  tickRotation: 0,
                }}
                padAngle={0}
                endAngle={360}
                cornerRadius={0}
                colors={{ datum: "data.color" }}
                layers={["bars"]}
              />
            </Box>
          </ListItemAvatar>
          <Box sx={{ flex: 1, cursor: "pointer" }}>
            <ListItemText
              primaryTypographyProps={{ whiteSpace: "nowrap" }}
              primary={
                <>
                  <Translator text="Expense Fact" />: <Translator number={expenseFact} currency={currency} />
                </>
              }
              secondary={
                <>
                  <Translator text="Expense Plan" />: <Translator number={expensesPlanTotal} currency={currency} />
                </>
              }
            />
          </Box>
        </ListItem>
      </Tooltip>
    );
  }
  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 75,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "createdAt",
        headerName: <Translator text="Created At" />,
        width: 120,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => <Translator date={params.value} />,
      },
      {
        field: "value",
        headerName: <Translator text="Value" />,
        width: 150,
        headerAlign: "center",
        align: "right",
        renderCell: ({ row }) => <Translator number={row.value.amount} currency={row.value.currency} />,
      },
      {
        field: "category",
        headerName: <Translator text="Category" />,
        width: 200,
        headerAlign: "center",
        align: "left",
        valueGetter: (value) => value?.name,
        renderCell: ({ row }) => <Translator text={row.category?.name || "Unknown Category"} />,
      },
      {
        field: "author",
        headerName: <Translator text="Author" />,
        width: 150,
        headerAlign: "center",
        align: "left",
        valueGetter: (value) => value?.name,
        renderCell: ({ row }) => row.author?.name || "Unknown Author",
      },
      {
        field: "scope",
        headerName: <Translator text="Scope" />,
        width: 150,
        headerAlign: "center",
        align: "left",
        valueGetter: (value) => value,
        renderCell: ({ row }) => {
          let icon;
          if (row.isContractExpense) {
            icon = <DocumentScanner color="success" />;
          } else if (row.isFacilityExpense) {
            icon = <House color="error" />;
          }
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {icon}
              <Translator text={row.scope} />
            </Box>
          );
        },
      },
      {
        field: "documents",
        headerName: <Translator text="Documents" />,
        minWidth: 400,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => <FileList type="documents" files={params.value} />,
      },
    ],
    []
  );

  return (
    <Box>
      <Box sx={{ height: "23rem", position: "relative", margin: "0 auto" }}>
        <ResponsiveRadialBar
          data={data}
          margin={{ top: 10, right: 10, bottom: 100, left: 10 }}
          radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
          innerRadius={0.6}
          padAngle={1}
          endAngle={360}
          cornerRadius={5}
          colors={{ datum: "data.color" }}
          activeOuterRadiusOffset={8}
          layers={["grid", "bars", "legends", CenteredMetric]}
          enableLabels={true}
          enableCircularGrid={false}
          isInteractive={true}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
      <Box sx={{ height: "100%", overflow: "hidden" }}>
        <Box sx={{ height: "50vh", overflowY: "auto", padding: "1rem" }}>
          <DataGrid columns={columns} rows={expenses} rowCount={expenses.length} loading={false} />
        </Box>
      </Box>
    </Box>
  );
});

export default ContractExpenses;
