import React from "react";

import { observer } from "mobx-react";

import { Box, Tooltip } from "@mui/material";

import { Translator } from "~/components";

/**
 * Отображает планируемую и фактическую площадь.
 */
const ContractAreasInfo = observer(({ total, actual }) => {
  return (
    <Box sx={{ display: "flex", flex: 1, mr: 1, flexDirection: "row", justifyContent: "space-between" }}>
      <Box>
        <Tooltip arrow title={<Translator text="The sum of the areas of all facilities" />}>
          <Translator text={"Total area:"} />
          &nbsp;
          <Translator number={total} /> <Translator text={"m2"} />
        </Tooltip>
      </Box>
      <Box>
        <Tooltip arrow title={<Translator text="The actual area of all facilities" />}>
          <Translator text={"Actual area:"} />
          &nbsp;
          <Translator number={actual} /> <Translator text={"m2"} />
        </Tooltip>
      </Box>
    </Box>
  );
});

export default ContractAreasInfo;
