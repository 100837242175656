import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, CalendarMonth } from "@mui/icons-material";
import moment from "moment";
import { Translator, Module } from "~/components";

import AnalyticsGrid from "./AnalyticsGrid";

const AnalyticsModule = observer(() => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [start, setStart] = useState(currentDate.clone().startOf("month"));
  const [end, setEnd] = useState(currentDate.clone().endOf("month"));

  useEffect(() => {
    setStart(currentDate.clone().startOf("month"));
    setEnd(currentDate.clone().endOf("month"));
  }, [currentDate]);

  const onNextMonth = useCallback(() => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  }, [currentDate]);

  const onPrevMonth = useCallback(() => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  }, [currentDate]);

  return (
    <Module>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 2, mb: 2 }}>
        <ButtonGroup variant="contained" aria-label="month navigation">
          <Button
            sx={{
              p: "0.5rem 0.75rem",
              minWidth: "40px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            startIcon={<ChevronLeft />}
            onClick={onPrevMonth}
          />
          <Button
            startIcon={<CalendarMonth />}
            variant="outlined"
            sx={{
              px: 2,
              py: 1,
              backgroundColor: "white",
              color: "primary.main",
              borderRadius: 0,
              borderColor: "primary.main",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                boxShadow: "none",
              },
            }}
          >
            <Typography variant="body1">
              <Translator date={start} /> {" - "} <Translator date={end} />
            </Typography>
          </Button>
          <Button
            sx={{
              p: "0.5rem 0.75rem",
              minWidth: "40px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            endIcon={<ChevronRight />}
            onClick={onNextMonth}
          />
        </ButtonGroup>
      </Box>

      <AnalyticsGrid startDate={start} endDate={end} />
    </Module>
  );
});

export default AnalyticsModule;
