import ApiBase from "~/api/ApiBase";

/**
 * API контрактов.
 *
 * Работаем с JSON: отправляем JSON, принимаем JSON. С файлами и формами работает отдельными
 * методами.
 *
 * Методы:
 *  - getStatuses - список возможных статусов контрактов.
 *
 *  - getActiveContracts - список активных контрактов.
 *  - findContracts - найти контракты.
 *  - createContract - создать новый контракт.
 */
export default class ContractsApiV0 extends ApiBase {
  /**
   * Запрос на найденные по параметрам контракты.
   */
  async findContracts(branchId, searchBy, states, daterange, leads, pagination) {
    const url = `/api/v0/branches/${branchId}/contracts/found-contracts`;

    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };

    const parameters = { ...backendPagination, searchBy, states, leads };

    if (!!daterange[0]) {
      parameters["from"] = daterange[0].format("YYYY-MM-DD");
    }
    if (!!daterange[1]) {
      parameters["to"] = daterange[1].format("YYYY-MM-DD");
    }

    const payload = {};
    const [data, headers] = await this.post(url, payload, parameters);
    return [data, Number.parseInt(headers.get("Total-Count"))];
  }

  /**
   * Создать новый контракт.
   *
   * @param {integer} branchId идентификатор филиала
   * @param {object} payload данные контракта
   */
  async createContract(branchId, payload) {
    const url = `/api/v0/branches/${branchId}/contracts`;
    const [data] = await this.post(url, payload);
    return data;
  }

  /**
   * Изменить статус договора.
   *
   * @param {integer} contractId идентификатор договора
   * @param {string} statusName название статуса
   */
  async changeContractStatus(contractId, statusName) {
    const url = `api/v0/contracts/${contractId}/state`;
    const payload = { state: statusName };
    const [data] = await this.put(url, payload);
    return data;
  }

  /**
   * Изменить контракт.
   *
   * @param {integer} contractId идентификатор контракта
   * @param {*} payload данные контракта
   */
  async editContract(contractId, payload) {
    const url = `api/v0/contracts/${contractId}`;
    const [data] = await this.put(url, payload);
    return data;
  }

  /**
   * Получить список активных контрактов.
   *
   * @param {integer} branchId идентификатор филиала
   */
  async getActiveContracts(branchId, searchBy, leads) {
    const url = `api/v0/branches/${branchId}/contracts`;
    const [data] = await this.get(url, { searchBy, leads });
    return data;
  }

  /**
   * Получить возможные статусы договоров филиала.
   *
   * @param {integer} branchId
   * @returns
   */
  async getStatuses(branchId) {
    const url = `api/v0/branches/${branchId}/contracts/states`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Запросить записи истории изменений контракта.
   *
   * @param {integer} contractId идентификатор договора
   * @returns
   */
  async getContractHistoryRecords(contractId) {
    const url = `api/v0/contracts/${contractId}/history`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Получить контракт по его ID.
   *
   * @param {integer} contractId идентификатор контракта
   * @returns {object} данные контракта
   */
  async getContractById(contractId) {
    const url = `api/v0/contracts/${contractId}`;
    const [data] = await this.get(url);
    return data;
  }

  /**
   * Загрузить документ.
   * 
   * @param {*} file файл документа.
   * @returns 
   */
  async uploadDocument(file) {
    const [data] = await this.postFormData("api/v0/documents", { file });
    return data;
  }

  async uploadDocuments(filesArray = []) {
    const promises = filesArray.map((file) => this.uploadDocument(file));
    const resolvedPromises = await Promise.all(promises);
    return resolvedPromises.map((item) => item.id);
  }
  async getDocumentInfo(id) {
    const url = `api/v0/documents/${id}`;
    const [data] = await this.get(url);
    return data;
  }

  async getDocument(id) {
    const url = `api/v0/document-blobs/${id}`;
    return this.request({
      method: "GET",
      url,
      type: "raw",
    });
  }
  async fetchRenderings(branchId, employeeId) {
    const url = `api/v0/branches/${branchId}/modelling/renderings?employeeId=${employeeId}`;
    const [data] = await this.get(url);
    return data;
  }

  async deleteScanPlan(planId) {
    const url = `api/v0/facilities/scanning/plans/${planId}`;
    return await this.delete(url);
  }
}
