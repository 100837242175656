import React, { useState, useCallback, useRef } from "react";
import { observer } from "mobx-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Add, Edit } from "@mui/icons-material";

import { Translator, Form, Preloader } from "~/components";
import Notify from "~/common/components/Notify";
import useStores from "~/hooks";
import moment from "moment";
import { getAddPaymentFormConfig, getEditInvoiceFormConfig } from "../../data/invoiceFormConfigs";

const InvoiceContextMenu = observer(({ isOpen, position, handleClose, selectedInvoice }) => {
  const { invoiceStore, rootStore, branchStore } = useStores();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openEditInvoiceDialog, setOpenEditInvoiceDialog] = useState(false);
  const [isModalPending, setModalPending] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [isNotifyOpen, setNotifyOpen] = useState(false);

  const { permissionsSet } = rootStore.authStore;

  const [x, y] = position;
  const isVisible = !!x && !!y;

  const branchId = branchStore?.currentBranch?.id || selectedInvoice?.branchId;

  const {
    id: invoiceId,
    valueWithoutVAT,
    currency,
    contractNumber,
    clientInfo,
    createdAt,
    method,
  } = selectedInvoice || {};
  const clientName = clientInfo?.name || "";
  const vat = method?.vat || "";

  const onSubmitPayment = useCallback(
    async (data) => {
      try {
        setModalPending(true);
        data.date = moment(data.date, "L").format("YYYY-MM-DD");
        await invoiceStore.addPaymentAsync(data);
        setNotifyMessage("Payment successfully added!");
        setNotifyOpen(true);
      } catch (error) {
        rootStore.setError("error", null, error?.message);
      } finally {
        setModalPending(false);
        setOpenPaymentDialog(false);
      }
    },
    [invoiceStore, rootStore]
  );

  const onSubmitEditInvoice = useCallback(
    async (data) => {
      console.log(data);
      try {
        setModalPending(true);
        const payload = {
          number: data.number, // Номер счета
          changeReason: data.reason, // Причина изменения
          methodId: data.methodId, // ID метода оплаты
          amount: data.grossAmount, // Сумма
          currency: currency, // Валюта
        };
        await invoiceStore.updateInvoiceAsync(invoiceId, payload); // PUT запрос
        setNotifyMessage("Invoice successfully updated!");
        setNotifyOpen(true);
      } catch (error) {
        rootStore.setError("error", null, error?.message);
      } finally {
        setModalPending(false);
        setOpenEditInvoiceDialog(false);
      }
    },
    [invoiceId, invoiceStore, rootStore, currency]
  );

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
    handleClose();
  };
  const handleClosePaymentDialog = () => setOpenPaymentDialog(false);

  const handleOpenEditInvoiceDialog = async () => {
    if (branchId) {
      await invoiceStore.fetchPaymentMethods({ id: branchId });
    }
    setOpenEditInvoiceDialog(true);
    handleClose();
  };

  const handleCloseEditInvoiceDialog = () => setOpenEditInvoiceDialog(false);

  const handleDownloadInvoice = useCallback(() => {
    invoiceStore.downloadInvoice(invoiceId);
    setNotifyMessage("Invoice download started!");
    setNotifyOpen(true);
    handleClose(); // Закрыть меню после выполнения действия
  }, [invoiceId, invoiceStore, handleClose]);

  const canEditInvoice = Array.from(permissionsSet).some(([permission]) => permission === "payments.change_invoice");

  const menuTriggerRef = useRef(null);

  return (
    <>
      <Menu
        open={isOpen}
        onClose={() => {
          handleClose();
          menuTriggerRef.current?.focus();
        }}
        anchorReference="anchorPosition"
        anchorPosition={isVisible ? { top: y, left: x } : undefined}
        disableAutoFocus={false}
        disableEnforceFocus={false}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
            "aria-hidden": false,
          },
        }}
      >
        <MenuItem onClick={handleOpenPaymentDialog}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <Translator text="Add payment" />
        </MenuItem>

        <MenuItem onClick={handleDownloadInvoice}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Translator text="Download invoice" />
        </MenuItem>

        {canEditInvoice && (
          <MenuItem onClick={handleOpenEditInvoiceDialog}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <Translator text="Edit invoice" />
          </MenuItem>
        )}
      </Menu>

      <Dialog open={openPaymentDialog} onClose={handleClosePaymentDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          {!isModalPending && (
            <Form
              config={getAddPaymentFormConfig(
                currency,
                invoiceId,
                contractNumber,
                clientName,
                valueWithoutVAT,
                createdAt
              )}
              onSubmit={onSubmitPayment}
              onCancel={handleClosePaymentDialog}
            />
          )}
          <Preloader isPending={isModalPending} />
        </DialogContent>
      </Dialog>

      <Dialog open={openEditInvoiceDialog} onClose={handleCloseEditInvoiceDialog} maxWidth="sm" fullWidth>
        <DialogContent sx={{ padding: 0 }}>
          {!isModalPending && (
            <Form
              config={getEditInvoiceFormConfig(
                currency,
                valueWithoutVAT,
                createdAt,
                contractNumber || "No Contract Number",
                clientName,
                vat,
                branchId,
                invoiceStore.paymentMethods,
                selectedInvoice
              )}
              onSubmit={onSubmitEditInvoice}
              onCancel={handleCloseEditInvoiceDialog}
            />
          )}
          <Preloader isPending={isModalPending} />
        </DialogContent>
      </Dialog>
      <Notify isOpen={isNotifyOpen} onClose={() => setNotifyOpen(false)}>
        {notifyMessage}
      </Notify>
    </>
  );
});

export default InvoiceContextMenu;
