import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { Translator } from "~/components";
import FacilityIcon from "./FacilityIcon";
// import useStores from "~/hooks";

const FacilityOnContractCard = observer(({ facility, openCreateScanningPlanDialog }) => {
  const isScanningPlanned = !!facility.scanningPlans && facility.scanningPlans.length > 0;
  const { latestScanningPlan, scanningPlans, hasUnfinishedPlans, hasMultiplePlans } = facility;

  const handleClick = useCallback(() => {
    openCreateScanningPlanDialog(facility);
  }, [openCreateScanningPlanDialog, facility]);

  const color = useMemo(() => {
    if (!scanningPlans || scanningPlans.length === 0) {
      return "red"; // Красный - если нет ни одного плана (только добавили facility)
    }

    if (hasUnfinishedPlans) {
      return "orange"; // Оранжевый - есть планы, но они не завершены
    }

    const hasNoResult = scanningPlans.some(
      (plan) => !plan.result // Проверяем, есть ли планы без результата
    );

    if (hasNoResult) {
      return "blue"; // Синий - планы завершены, но результат отсутствует
    }

    return "green"; // Зеленый - все завершено и есть результат
  }, [scanningPlans, hasUnfinishedPlans]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        justifyContent: "flex-start",
        display: "flex",
        flex: 1,
        width: "100%",
        overflow: "hidden",
        ml: 0,
        mb: 1,
      }}
    >
      <Chip
        sx={{ p: "0.5rem", borderRadius: "4px" }}
        color={color}
        size="medium"
        icon={<FacilityIcon kind={facility.kind} />}
        label={
          <>
            <Translator number={facility.area} /> <Translator text="m2" />
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          ml: "0.5rem",
          flex: 1,
          width: "100%",
          alignContent: "center",
        }}
      >
        {facility.name}
      </Box>
      <Box sx={{ pr: 0.5, display: "flex", alignItems: "flex-start" }}>
        {isScanningPlanned && (
          <>
            <Tooltip
              title={
                <>
                  <Translator text={`Scanner: ${latestScanningPlan?.employee?.name || "Unknown"}`} />
                </>
              }
              arrow
            >
              <Box
                sx={{
                  mr: 0.5,
                  p: "0.25rem 0.5rem",
                  borderRadius: "0.25rem",
                  backgroundColor: hasMultiplePlans ? "rgba(255, 0, 0, 0.1)" : "",
                  border: hasMultiplePlans ? "1px solid red" : "",
                  color: "red",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                <Box>
                  <Translator date={latestScanningPlan?.start} format="DD.MM.YYYY" />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip title={<Translator text="Repeat Visit" />}>
              <IconButton color="primary" onClick={() => openCreateScanningPlanDialog(facility, (selectedDate) => {})}>
                <EventRepeatIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {!isScanningPlanned && (
          <Tooltip title={<Translator text={"Add scanner"} />}>
            <Box>
              <IconButton color="info" onClick={handleClick}>
                <MoreTimeIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
});

export default FacilityOnContractCard;
