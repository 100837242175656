import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react";

import { CssBaseline, Box, Card, CardHeader, Alert } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import packageJson from "../../../../package.json";

import { FormDialog } from "~/components/dialogs";

/**
 * Страница входа в приложение.
 */
const LoginPage = observer(({ store, theme, renderError, rootStore }) => {
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false); // состояние для блокировки полей
  const [loginData, setLoginData] = useState({ login: "", password: "" });
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  // Обработать логин, useCallback нужен чтобы стор успел создаться
  // const { isPending } = store;

  const version = rootStore?.appVersion || "beta";

  const handleLogin = useCallback(
    async (data) => {
      setIsDisabled(true);

      try {
        await store.login(data);
        setError(null);
        setIsDisabled(false);
        setIsDialogOpen(false);
      } catch (err) {
        if (err.message === "401") {
          setError("Invalid login or password.");
        } else {
          setError("Something went wrong. Please try again.");
        }
        setIsDisabled(false);
        setLoginData({ login: "", password: "" });
      }
    },
    [store]
  );

  const config = {
    submitText: "Login",
    fields: [
      {
        name: "login",
        title: "Login",
        type: "text",
        viewConfig: "input",
        isRequired: true,
        isReadOnly: isDisabled,
        validate: true,
        value: loginData.login,
        onChange: (e) => setLoginData({ ...loginData, login: e.target.value }),
      },
      {
        name: "password",
        title: "Password",
        type: "password",
        isRequired: true,
        isReadOnly: isDisabled,
        validate: false,
        value: loginData.password,
        onChange: (e) => setLoginData({ ...loginData, password: e.target.value }),
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <CssBaseline />
        {renderError}
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            p: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <CardHeader
              title="ScanM2 ERP 11"
              subheader={`Version: ${version}`}
              sx={{
                backgroundColor: theme.palette.darkblue.main,
                color: theme.palette.darkblue.contrastText,
              }}
            />
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2, marginTop: 2 }}>
                {error}
              </Alert>
            )}
            <FormDialog
              size={"small"}
              isVisible={isDialogOpen}
              closeDialog={() => setIsDialogOpen(false)}
              title={`ScanM2 ERP ${version}`} // Заголовок с версией
              formConfig={config}
              handleSubmit={handleLogin}
              isPending={isDisabled}
              pendingText={"Logging in ..."}
              formErrors={error}
              errorText={error}
              noCancel
              headerSx={{ backgroundColor: theme.palette.darkblue.main, color: theme.palette.darkblue.contrastText }}
              preloaderColor={"white"}
            />
          </Card>
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export default LoginPage;
