import { action, computed, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";
import ContractHistoryEntry from "./ContractHistoryEntry";

import { Expense } from "~/modules/expenses/models";

class Contract {
  @observable store = null;
  @observable id = null;

  @observable branchId = null;

  @observable pending = false;
  @observable state = "";
  @observable serviceIds = [];

  @observable number = null;
  @observable date = null;
  @observable modellingCost = null;
  @observable modellingBudget = null;

  @observable description = "";
  @observable valueTotal = null;

  @observable expensePlan = null;
  @observable payedTotal = 0;

  @observable isLead = true;


  // ссылки на связанные модели
  @observable client = null;
  @observable facilities = [];
  @observable documents = [];
  @observable invoices = [];
  @observable participants = new Map();
  @observable expenses = []; // расходы к контракту
  @observable historyRecords = null; // Записи истории измененйи контракта

  @observable pendingRequests = 0;

  SALES_MANAGER_ROLE = "sales-manager";
  MODELLING_MANAGER_ROLE = "modelling-manager";

  constructor(props, store, shallow = false) {
    makeObservable(this);
    this.store = store;
    this.id = `${props.id}`;
    this.update(props, shallow);
  }

  // TODO: осталось обработать расходы полученные с контрактом и объектом.
  @action async update(props, shallow = false) {
    this.branchId = props.branchId || null; // TODO: заменить на бранч

    const { facilities, documents, invoices, participants } = props;
    const { facilityStore, clientStore, invoiceStore, employeesStore, expenseStore } = this.store.rootStore;

    // Данные контракта
    this.number = props.number || "";
    this.date = moment(props.date) || null;
    this.description = props.description || "";
    this.valueTotal = props.value || null;

    // Связанные сущности
    this.client = clientStore.addClient(props.client);
    this.isLead = props.isLead;

    // TODO: а зачем оно в сторе?
    // Расписание сканирования за каждым из facility
    this.facilities = facilities.map((f) => facilityStore.addFacility(this, f));

    // Добавляет инвойс в стор инвойсов. Насколько оно надо?
    this.invoices = invoices.map((i) => invoiceStore.addInvoice(i)); //

    // TODO: сделать модель документа Document
    this.documents = documents.map((d) => d);

    // Есть через facilities все данные менеджер и отрисовщик, но по разным объектам 
    // могут быть разные исполнители.
    // А здесь, в participants - архитектор
    this.participants.clear();
    for (const [role, users] of Object.entries(participants)) {
      this.participants.set(
        role, // Нужно проверять что роль известна фронтенду
        users.map((u) => employeesStore.getEmployeeById(u))
      );
    }

    this.modellingCost = props.modellingCost;
    this.modellingBudget = props.modellingBudget;

    this.payedTotal = props.payedTotal || 0;

    this.state = props.state;

    if (props.expensesPlan) {
      this.expensePlan = props.expensesPlan;
    }

    // Создать все расходы к Объекту
    props.expenses.forEach((expense) => {
      employeesStore.ensureEmployee(expense.author); // убедимся что автор есть в сторе
      this.expenses.push(
        new Expense({ ...expense, scope: "contract", contract: this, documentsMeta: expense.documents }, expenseStore)
      ); // создадим расходы
    });

    return this;
  }

  // Используется при назначении работников
  @action addParticipantByIdRole(id, role) {
    const roleArray = this.participants.get(role);
    if (!roleArray) {
      this.participants.set(role, [id]);
    } else {
      roleArray.push(id);
    }
  }

  // Не понятно зачем оно нужно
  @action processServiceIds() {
    this.serviceIds.clear();
    this.facilities.forEach((facility) => {
      facility &&
        facility.services.forEach((service) => {
          this.addServiceId(service.uid);
        });
    });
  }

  /**
   * Запросить историю контракта.
   *
   * Работаем через стор, справшиваем там сервер, обновляем записи уже здесь.
   *
   */
  @action async retrieveHistory() {
    try {
      const records = await this.store.retrieveContractHistory(this);
      runInAction(() => {
        this.historyRecords = records.map(
          (item, index) => new ContractHistoryEntry(item, index, this)
        );
      });
    } catch (error) {
      console.error("Error retrieving contract history:", error);
    }
  }
  

  @action async addScannerAsync({ facilityId, finish, scannerId, start }) {
    const planData = await this.api.addScanPlan({
      facilityId,
      employeeId: scannerId,
      start: moment(start).utcOffset(0, true).toISOString(),
      finish: moment(finish).utcOffset(0, true).toISOString(),
    });
    if (planData) {
      this.store.addService({ ...planData, kind: "scanning" });
    }
  }

  /**
   * Добавить новый расход по контракту.
   */
  @action addContractExpense(expenseData) {
    const expense = new Expense(expenseData, this.store.rootStore.expenseStore);
    runInAction(() => {
      this.expenses.push(expense);
      if (expenseData.category) {
        expense.setCategory(expenseData.category, expenseData.scope);
      }
    });
  }
  
  

  @action async setModelingManager(data) {
    const result = await this.store.rootStore.api.setModelingManager(data);
    this.addModelingManager(result);
  }

  // это назначение менеджера отрисовки
  @action addModelingManager(manager) {
    this.addParticipantByIdRole(manager.id, "modelling-manager");
  }

  @action
  addServiceId(id) {
    this.serviceIds.push(id);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get label() {
    return this.number;
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get documentsArray() {
    return [];
    // return toJS(this.documents);
  }

  /**
   * План расходов по контракту.
   */
  @computed get expensesPlanTotal() {
    return (this.expensePlan && this.expensePlan.amount && Number(this.expensePlan.amount)) || 0;
  }

  // @computed get expensesTotal() {
  //   return this.expensesContractTotal + this.expensesFacilitiesTotal + this.expensesShiftTotal;
  // }

  @computed
  get serviceIdsArray() {
    return this.serviceIds.slice();
  }

  @computed
  get facilitiesToPlan() {
    if (this.isPending) {
      return [];
    }
    const array = [];
    this.facilities.forEach((facility) => {
      if (facility && !facility.scanPlan) {
        array.push(facility);
      }
    });
    return array;
  }

  /**
   * Суммарная общая площадь контракта
   */
  @computed get totalArea() {
    let area = 0;
    this.facilities.forEach((facility) => {
      if (facility && facility.area) {
        area += Number(facility.area);
      }
    });
    return area;
  }

  /**
   * Фактическая площадь договора.
   */
  @computed get realArea() {
    let area = 0;
    this.facilities.forEach((facility) => {
      if (facility && facility.realArea) {
        area += Number(facility.realArea);
      }
    });
    return area;
  }

  @computed
  get statusIndex() {
    const index = this.store.statusesArray.findIndex((status) => {
      return status === this.status;
    });
    return index >= 0 ? index : null;
  }

  @computed get paymentData() {
    //  TODO: неправильно, вижу здесь undefined в таблице до рендера
    if (this.isPending) {
      return;
      return {
        currency: this.valueTotal.currency,
        cost: this.valueTotal.amount && Number(this.valueTotal.amount),
        invoiceTotal: 0,
        payedTotal: 0,
        percentage: 0,
      };
    }

    let invoiceTotal = 0;
    let payedTotal = 0;
    this.invoices.forEach((invoice) => {
      if (invoice) {
        invoiceTotal += invoice.valueWithoutVAT;
        payedTotal += invoice.payedAmountWithoutVAT;
      }
    });
    return {
      currency: this.valueTotal.currency,
      cost: this.valueTotal.amount && Number(this.valueTotal.amount),
      invoiceTotal,
      payedTotal,
      percentage: Math.floor((payedTotal * 100) / this.valueTotal.amount),
    };
  }

  @computed get invoicesById() {
    return this.invoiceIds.map((id) => this.store.rootStore.invoiceStore.getInvoiceById(id));
  }

  @computed
  get services() {
    return this.serviceIds.map((id) => {
      return this.store.getServiceById(id);
    });
  }

  /**
   * Менеджер договора.
   */
  @computed get manager() {
    const participants = this.participants.get(this.SALES_MANAGER_ROLE) || [];
    return participants.length ? participants[0] : null;
  }

  /**
   * Менеджер договора.
   */
  @computed get modellingManager() {
    const participants = this.participants.get(this.MODELLING_MANAGER_ROLE) || [];
    return participants.length ? participants[0] : null;
  }

  // ---------------- Моделирование ----------

  /**
   * Все ли объекты смоделированы?
   */
  @computed get isAllFacilitiesModelled() {
    if (this.facilities.length < 1) return false;
    return this.facilities.every((f) => {
      if (f.modellingPlans.length < 1) return false; // .every() для пустого массива вернёт true
      return f.modellingPlans.every((mp) => mp.isFinished);
    });
  }

  /**
   * Дата завершения моделирования.
   */
  @computed get modellingFinishDatetime() {
    if (!this.isAllFacilitiesModelled) return null;
    let finishDate;
    this.facilities.forEach((f) => {
      f.modellingPlans.forEach((mp) => {
        if (finishDate === undefined || finishDate < mp.finish) finishDate = mp.finish;
      });
    });
    return finishDate;
  }

  /**
   * Отрисовщики договора.
   */
  @computed get modellingEmployees() {
    const modellers = [];
    this.facilities.forEach((f) => f.modellingPlans.forEach((mp) => modellers.push(mp.modeller)));
    return [...new Set(modellers)];
  }

  // --------------- Сканирования ------------

  /**
   * Сканировщики договора списком сущностей Employee (Manager?)
   *
   * Возьмём их списка facilities контракта, у низ scanningPlan и там сканировщик.
   */
  @computed get scannerEmployees() {
    const employees = [];
    this.facilities.forEach((f) => {
      if (f.scanningPlan?.employee) employees.push(f.scanningPlan.employee);
    });
    return [...new Set(employees)];
  }

  /**
   * Все ли объекты отсканированы.
   */
  @computed get isAllFacilitiesScanned() {
    if (!this.facilities.length) return false;
    return this.facilities.every((facility) => facility.scanningPlan?.isFinished);
  }

  /**
   * Дата завершения сканирования.
   *
   * Только если все отсканированы, иначе - null.
   */
  @computed get scanningFinishDatetime() {
    // если не все отсканированы - мы не знаем когда завершено.
    if (!this.isAllFacilitiesScanned) return null;
    // отсортировать по дате, взять наибольшую
    const sorted = this.facilities
      .toSorted((a, b) => a.scanningPlan.finished.datetime >= b.scanningPlan.started.datetime)
      .reverse();
    return sorted[0].scanningPlan.finished.datetime;
  }

  // ----------------

  @computed get currency() {
    return this.store.rootStore.branchStore.branch && this.store.rootStore.branchStore.branch.currency;
  }

  @computed
  get downloadInvoiceFormConfig() {
    const fields = [];
    fields.push({
      name: "newInvoiceId",
      type: "hidden",
      viewConfig: "input",
      initialValue: "payload",
    });

    return {
      submitText: "Download",
      cancelText: "Close",
      formTitle: "Download invoice",
      formText: "Invoice created successfully. Feel free to download the invoice or close this window",
      fields,
    };
  }

  @computed
  get addModelingManagerFormConfig() {
    const fields = [];
    fields.push({
      name: "contractId",
      type: "hidden",
      viewConfig: "input",
      initialValue: "payload",
    });

    fields.push({
      name: "managerId",
      fakeName: "manager",
      title: "Choose employee",
      type: "select",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: this.store.rootStore.employeesStore.isPending,
      options: this.store.rootStore.employeesStore.modellingManagersArray,
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Set modeling manager",
      formText: "To set modelling manager select employee",
      fields,
    };
  }

  @computed
  get addRendererFormConfig() {
    const fields = [];
    fields.push({
      name: "contractId",
      type: "hidden",
      viewConfig: "input",
      initialValue: "payload",
    });

    fields.push({
      name: "rendererId",
      fakeName: "renderer",
      title: "Choose employee",
      type: "select",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      loading: this.store.rootStore.employeesStore.isPending,
      options: this.store.rootStore.employeesStore.renderersArray,
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Set modeling manager",
      formText: "To set modelling manager select employee",
      fields,
    };
  }

  // TODO: не должно быть this.permissionsSet
  @computed get permissionsSet() {
    return this.store.rootStore.authStore.permissions;
  }

  @computed
  get canAddFacility() {
    if (this.state === "Linking") return false;
    if (this.state === "Modelling") return false;
    if (this.state === "Payment") return false;
    if (!this.permissionsSet.has("facilities.add_facility")) return false;
    return true;
  }

  @computed
  get canAddExpenses() {
    const expensesStates = new Set(["Created", "Measuring", "Linking"]);
    return expensesStates.has(this.state);
  }

  @computed
  get canAddInvoice() {
    const invoiceStates = new Set(["Created", "Payment", "Measuring", "Linking", "Modelling"]);
    return invoiceStates.has(this.state) && this.permissionsSet.has("payments.add_invoice");
  }

  @computed
  get canSetScanningDate() {
    return !this.allPlanned && this.state === "Measuring";
  }

  @computed
  get canSetModellingManager() {
    return !this.modellingManager && this.state !== "Created" && this.facilities.length > 0;
  }

  @computed get allPlanned() {
    this.facilities.forEach((facility) => {
      if (facility && !facility.scannigPlan) {
        return false;
      }
    });
    return true;
  }

  @computed get allModellingPlaned() {
    this.facilities.forEach((facility) => {
      if (facility && !facility.modellingPlans) {
        return false;
      }
    });
    return true;
  }

  @computed get allLinked() {
    this.facilities.forEach((facility) => {
      if (facility && (!facility.scanningPlan || !facility.scanningPlan.hasLinked)) {
        return false;
      }
    });
    return true;
  }

  /**
   * Сумма учтённых оплат по контракту.
   */
  @computed get paymentsValue() {
    let payed = 0;
    this.invoices.forEach((invoice) => {
      payed += invoice.payedAmountWithoutVAT;
    });
    return payed;
  }

  /**
   * Сколько в процентах оплачено по контракту.
   */
  @computed get paymentsPercentage() {
    if (this.valueTotal.amount <= 0) {
      return 0;
    }
    return Math.floor((this.paymentsValue * 100) / this.valueTotal.amount);
  }

  /**
   * Оплачен ли контракт полностью.
   *
   * Сумма платежей должна быть не меньше суммы контракта что эквиваленто требованию
   * "процент оплаты должен быть больше либо равен ста".
   */
  @computed get isFullyPayed() {
    return this.paymentsPercentage >= 100;
  }

  @computed
  get allPayed() {
    console.warn("Deprecated method call Contract.allPayed");
    return this.paymentData.invoiceTotal <= this.paymentData.payedTotal;
  }

  /**
   * Сумма расходов по контракту.
   */
  @computed get expensesFactTotal() {
    let total = 0;
    this.expenses.forEach((e) => {
      total += e.value.amount;
    });
    this.facilities.forEach((f) => {
      f.expenses.forEach((e) => {
        total += e.value.amount;
      });
    });
    return total;
  }

  @computed get actionNote() {
    if (this.state === "Created") {
      if (!this.facilities.length) return "Need to create facilities (manager).";
      if (!this.paymentData.invoiceTotal) return "Need to create invoice (manager).";
    }
    if (this.state === "Measuring") {
      if (!this.facilities.length || !this.allPlanned || !this.isAllFacilitiesScanned) {
        return "Waiting for facilities scanning finishes (scanner).";
      }
    }
    if (this.state === "Linking") {
      if (!this.allLinked) {
        return "Waiting for facilities linking results (scanner).";
      }
      if (!this.paymentData.payedTotal) {
        return "Waiting for payment.";
      }
    }
    if (this.state === "Modelling") {
      // console.log(this.number, this.facilities.length, this.isAllFacilitiesModelled, this.allModellingPlaned);
      if (!this.facilities.length || !this.isAllFacilitiesModelled || !this.allModellingPlaned) {
        return "Waiting for modelling results (modeller).";
      }
    }
    if (this.state === "Payment") {
      if (this.paymentData.invoiceTotal < Number.parseFloat(this.valueTotal.amount)) {
        return "Need to create invoice (manager).";
      }
      if (!this.isFullyPayed) {
        return "Waiting for payment.";
      }
    }
    return null;
  }

  @computed get isError() {
    if (this.state === "Created") {
      return !this.facilities.length || !this.paymentData.invoiceTotal;
    }
    if (this.state === "Measuring") {
      return !this.facilities.length || !this.allPlanned || !this.isAllFacilitiesScanned;
    }
    if (this.state === "Linking") {
      return !this.allLinked || !this.paymentData.payedTotal;
    }
    if (this.state === "Modelling") {
      return !this.facilities.length || !this.isAllFacilitiesModelled || !this.allModellingPlaned;
    }
    if (this.state === "Payment") {
      return !this.facilities.length;
    }
    if (this.state === "Cancelled") {
      return false;
    }
    if (this.state === "Closed") {
      return false;
    }
    return false;
  }

  @computed get isWaiting() {
    if (this.state === "Created") {
      return !this.paymentData.invoiceTotal;
    }
    if (this.state === "Measuring") {
      return !!this.facilities.length && !!this.allPlanned && !this.isAllFacilitiesScanned;
    }
    if (this.state === "Linking") {
      return !this.allLinked;
    }
    if (this.state === "Modelling") {
      return !this.isAllFacilitiesModelled;
    }
    if (this.state === "Payment") {
      return !this.isFullyPayed;
    }
    if (this.state === "Cancelled") {
      return false;
    }
    if (this.state === "Closed") {
      return false;
    }
    return false;
  }

  @computed get canSwitch() {
    return {
      cancelled: this.status.nextSet.has("Cancelled"),
      created: !this.isError && this.status.nextSet.has("Created"),
      measuring: !this.isWaiting && !this.isError && this.status.nextSet.has("Measuring"),
      linking: !this.isError && this.status && this.status.nextSet.has("Linking"),
      modelling: !this.isError && this.status && this.status.nextSet.has("Modelling"),
      payment: !this.isError && this.status && this.status.nextSet.has("Payment"),
      closed: this.status.nextSet.has("Closed") && this.paymentsPercentage >= 100,
    };
  }

  @computed
  get currentBranch() {
    return this.store.rootStore.branchStore.branch;
  }

  @computed get status() {
    return this.store.getStatus(this.state);
  }

  /**
   * Есть ли активные запросы по данному контракту.
   */
  @computed get isPending() {
    return this.pendingRequests > 0;
  }

  /**
   * Список всех расходов по контракту.
   *
   * @ai
   */
  @computed get allExpenses() {
    return this.expenses.concat(
      ...this.facilities.map((facility) => facility.expenses)
    );
  }
  

  /**
   * Привязать расход к контракту.
   *
   * @ai
   */
  @action addExpense(expense) {
    this.expenses.push(expense);
  }

  /**
   * Adds Facility instance to Contraсt.
   *
   * @param {Facility} facility
   * @returns
   */
  @action
  addFacility(facility) {
    const index = this.facilities.findIndex((f) => f.id === facility.id);
    if (index === -1) {
      this.facilities.push(facility);
    } else {
      this.updateFacility(facility); // Если объект уже существует, обновляем его
    }
  }


  @computed get allFacilities() {
    return this.facilities.slice();
  }

  /**
   * Добавить инвойс к контракту.
   *
   * @param {Invoice} invoice экземпляр счёта.
   */
  @action addInvoice(invoice) {
    this.invoices.push(invoice);
    return invoice;
  }

  @action
  updateFacility(updatedFacility) {
    const index = this.facilities.findIndex((f) => f.id === updatedFacility.id);
    if (index !== -1) {
      this.facilities[index] = updatedFacility;
    } else {
      console.warn(`Facility with id ${updatedFacility.id} not found in contract ${this.id}`);
    }
  }

}

export default Contract;
