import { action, makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import AnalyticsAPI from "~/modules/analytics/api/analyticsApi";

class AnalyticsStore {
  analyticsData = [];
  isLoading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.api = new AnalyticsAPI(rootStore.authStore);
  }

  @action async fetchAnalyticsData(startDate, endDate) {
    runInAction(() => {
      this.isLoading = true;
      this.analyticsData = [];
    });

    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    try {
      const data = await this.api.getAnalyticsData(formattedStartDate, formattedEndDate);
      runInAction(() => {
        this.analyticsData = data;
      });
    } catch (error) {
      console.error("qwe", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default AnalyticsStore;
