import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, ButtonGroup, Divider, Typography } from "@mui/material";
import Translator from "../../components/Translator";

import moment from "moment";

import useStores from "~/hooks";

import ScheduleScale from "./ScheduleScale";
import { CalendarMonth, ChevronLeft, ChevronRight } from "@mui/icons-material";
import ScheduleColumn from "./ScheduleColumn";

/**
 * Базовый компонент расписания.
 */
const Schedule = observer(
  ({ start: planStart, finish: planFinish, role, employeeId, setPlanStart, setPlanFinish, setEmployeeId }) => {
    const { facilityStore, branchStore, employeesStore } = useStores();
    const { branch } = branchStore;
    const { employeesArray, scannersArray } = employeesStore; // TODO: тут должны быть сканеры
    const { isPendingScanningPlans, isPendingRenderingPlans } = facilityStore;
    // TODO: это вынести в настройки филиала
    const workHours = useMemo(() => {
      return { from: 8, to: 19 };
    }, []);
    const period = "week";

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment());
    const [now, setNow] = useState(moment());

    useEffect(() => {
      if (!branch) return;
      
      const adjustedNow = now.clone().add(1, 'day'); 
      setStart(adjustedNow.clone().startOf(period));
      setEnd(adjustedNow.clone().endOf(period));
      
      if (role === "scanner") {
        facilityStore.fetchScanningPlans(
          branch.id,
          adjustedNow.clone().startOf(period).utc(true).toISOString(),
          adjustedNow.clone().endOf(period).utc(true).toISOString()
        );
      } else if (role === "modeller") {
        facilityStore.fetchRenderingPlans(
          branch.id,
          adjustedNow.clone().startOf(period).utc(true).toISOString(),
          adjustedNow.clone().endOf(period).utc(true).toISOString()
        );
      }
    }, [now, branch, facilityStore, role]);
    
    const onNext = useCallback(() => {
      setNow(now.clone().add(1, 'week').add(1, 'day'));
    }, [now]);
    
    const onPrev = useCallback(() => {
      setNow(now.clone().add(-1, 'week').add(1, 'day')); 
    }, [now]);
    

    const onItemClick = useCallback(
      ({ time, newEmployeeId }) => {
        if (!setEmployeeId) return; // Если setEmployeeId не передан, ничего не делаем
        if (time === null) {
          setPlanStart("");
          setPlanFinish("");
          setEmployeeId("");
        } else {
          setEmployeeId(newEmployeeId);
          if (
            (!planStart && !employeeId) ||
            (!!planStart && time < planStart) ||
            `${employeeId}` !== `${newEmployeeId}`
          ) {
            setPlanStart(time.clone());
            setPlanFinish(time.clone().add(2, "hours"));
          } else if (!!planStart && employeeId === newEmployeeId) {
            setPlanFinish(time.clone().add(1, "hours"));
          }
        }
      },
      [planStart, planStart && planStart?.unix(), employeeId, setEmployeeId, setPlanFinish, setPlanStart]
    );

    const columns = useMemo(() => {
      const columns = [];
      employeesArray.forEach((employee) => {
        if (employee.rolesSet.has(role)) {
          columns.push(
            <ScheduleColumn
              onItemClick={onItemClick}
              isPending={isPendingScanningPlans || isPendingRenderingPlans}
              workHours={workHours}
              start={start}
              end={end}
              role={role}
              planStart={planStart}
              planFinish={planFinish}
              employeeId={employeeId}
              key={employee.id}
              employee={employee}
            />
          );
        }
      });
      return columns;
    }, [
      employeesArray,
      start,
      end,
      role,
      workHours,
      onItemClick,
      planStart,
      planFinish,
      employeeId,
      isPendingScanningPlans,
      isPendingRenderingPlans,
    ]);

    return (
      <Box sx={{ flexDirection: "column", display: "flex", overflow: "hidden" }}>
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            flex: 1,
            pt: "0.0rem",
            overflow: "hidden",
            maxHeight: "100%",
          }}
        >
          <Box sx={{ flexDirection: "row", pt: "0rem", pb: "0.5rem", display: "flex", justifyContent: "end" }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                sx={{ p: "0.5rem 0.75rem", ".MuiButton-startIcon": { margin: 0 } }}
                startIcon={<ChevronLeft />}
                onClick={onPrev}
              />
              <Button startIcon={<CalendarMonth />} variant="outlined">
                <Typography variant="body">
                  <Translator date={start} />
                  {" - "}
                  <Translator date={end} />
                </Typography>
              </Button>
              <Button
                sx={{
                  p: "0.5rem 0.75rem",
                  ".MuiButton-endIcon": {
                    margin: 0,
                  },
                }}
                endIcon={<ChevronRight />}
                onClick={onNext}
              />
            </ButtonGroup>
          </Box>
          <Divider />
          <Box sx={{ overflowX: "hidden", overflowY: "auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                overflow: "visible",
              }}
            >
              <ScheduleScale header={role} workHours={workHours} start={start} end={end} />
              <Box
                sx={{
                  flexDirection: "row",
                  overflow: "visible",
                  overflowX: "auto",
                  display: "flex",
                  flex: 1,
                }}
              >
                {columns}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Schedule;
