import { ApiBase } from "~/api";


export default class CommercialOffersApiV0 extends ApiBase {

  async makeNumber(branchId) {
    const url = `/api/v0/branches/${branchId}/commercial-offer-numbers`;
    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: this.authStore.token },
    });
    return await response.json();
  }

  async dropNumber(number) {
    const url = `/api/v0/commercial-offer-numbers/${number}`;
    await fetch(url, {
      method: "DELETE",
      headers: { Authorization: this.authStore.token },
    });
  }

  async createOffer(branchId, data) {
    const url = `/api/v0/branches/${branchId}/commercial-offers`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: this.authStore.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  async editOffer(offerId, data) {
    const url = `/api/v0/commercial-offers/${offerId}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: this.authStore.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  async loadOffers(branchId, params) {
    const url = `/api/v0/branches/${branchId}/commercial-offers?${params}`;
    const response = await fetch(url, {
      method: "GET",
      headers: { Authorization: this.authStore.token },
    });
    return [await response.json(), response.headers];
  }

  async changeOfferState(offerId, data) {
    const url = `/api/v0/commercial-offers/${offerId}/states`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: this.authStore.token,
        "uploadDocumentContent-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  /**
   * Загрузить документ.
   * 
   * @param {*} file файл документа.
   * @returns 
   */
  async uploadDocument(file) {
    const [data] = await this.postFormData("api/v0/documents", { file });
    return data;
  }

  /**
   * Загрузить документы.
   * 
   * @param {*} files документы к загрузке.
   * @returns список id загруженных документов.
   */
  async uploadDocuments(files) {
    const promises = files.map((file) => this.uploadDocument(file));
    const resolved = await Promise.all(promises);
    return resolved.map((doc) => doc.id);
  }

  async createClient(branchId, data) {
    const url = `api/v0/branches/${branchId}/clients`;
    const response = await this.post(url, data);
    return response.json();
  }
}
