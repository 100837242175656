import { computed, action, observable, makeObservable, runInAction } from "mobx";

import { WorkingShiftsApiV0 } from "../api";
import Resizer from "react-image-file-resizer";

import ScannerWorkingShift from "./models/ScannerWorkingShift";
import Shift from "~/models/Shift";

import { ConfigurationError } from "../errors";

/**
 * Стор рабочих смен сканировщиков.
 */
class WorkingShiftsStore {
  // Идет ли загрузка данных
  @observable isPending = false;

  @observable shiftMap = new Map();
  @observable totalCount = 0;

  @observable shift;
  @observable pendingOperations = 0;
  @observable pendingText = "";

  @observable errorText = "";

  constructor(rootStore) {
    makeObservable(this);

    this.root = rootStore;
    this.api = new WorkingShiftsApiV0(rootStore.authStore);
    this.oldApi = rootStore.api;
  }

  @action setPending(value) {
    this.isPending = !!value;
  }

  /**
   * Запросить открытую смену для роли.
   */
  @action async fetchOpenShift(branch) {
    this.errorText = "";
    const ROLE = "scanner";

    this.setPending(true);
    const employeeId = this.root.authStore.userId;
    let shift;
    try {
      shift = await this.api.getShiftForRole(branch.id, employeeId, ROLE); // Use new API
    } catch (err) {
      if (err instanceof ConfigurationError) {
        this.errorText = err.message;
        return;
      }
    }
    if (shift) {
      runInAction(() => {
        this.shift = new Shift(shift, this, branch); // Create a new shift model
      });
    }
    this.setPending(false);
  }

  resizeImage(file) {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });
  }

  /**
   * Открыть рабочую смену сканировщика.
   */
  @action async openWorkingShift(branch, data) {
    const ROLE = "scanner";

    // TODO: (ai) убрал все ключи без значений
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    runInAction(() => { this.setPending(true); this.pendingText = "Resizing photos"; });


    if (data.scannerPhoto?.length) {
      const resizedPhotos = await Promise.all(data.scannerPhoto.map(this.resizeImage));
      data.scannerPhotoIds = await this.api.uploadPhotos(resizedPhotos);
    }
    if (data.carPhoto?.length) {
      const resizedPhotos = await Promise.all(data.carPhoto.map(this.resizeImage));
      data.carPhotoIds = await this.api.uploadPhotos(resizedPhotos);
    }

    runInAction(() => { this.pendingText = "Opening shift"; });
    const result = await this.api.startShift(branch.id, ROLE, data);
    if (result && result.id) {
      runInAction(() => {
        this.shift = new Shift(result, this, branch);
        this.setPending(false);
        this.pendingText = "";
      });
      return [this.shift, null];
    } else {
      // А это считаем ошибкой
      runInAction(() => {
        this.setPending(false);
        this.pendingText = "";
      });
      return [this.shift, result];
    }
  }

  /**
   * Закрыть рабочую смену сканировщика.
   */
  @action async closeWorkingShift(shift, data) {
    // TODO: (ai) убрал все ключи без значений
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    runInAction(() => { this.setPending(true); this.pendingText = "Uploading photos"; });
    if (data.scannerPhoto?.length) {
      data.scannerPhotoIds = await this.api.uploadPhotos(data.scannerPhoto);
    }
    if (data.carPhoto?.length) {
      data.carPhotoIds = await this.api.uploadPhotos(data.carPhoto);
    }

    runInAction(() => { this.pendingText = "Closing shift"; });
    const result = await this.api.finishShift(shift.id, data);
    if (result && result.id) {
      runInAction(() => {
        this.shift = null; // не текущей смены
        this.setPending(false);
        this.pendingText = "";
      });
      return [true, null];
    } else {
      runInAction(() => {
        this.setPending(false);
        this.pendingText = "";
      });
      return [false, result];
    }
  }

  /**
   * Fetches scanner's working shifts from backend.
   *
   * @param {integer} branchId
   * @returns {array} fetched scanner's working shifts
   */
  @action async fetchShifts(branchId, pagination, searchBy, daterange) {
    const { page, pageSize } = pagination;
    const backendPagination = { page: page + 1, perPage: pageSize };

    this.setPending(true); // TODO: вынести этот паттерн в декоратор?

    const result = await this.api.getBranchWorkingShifts(
      branchId,
      backendPagination,
      searchBy,
      daterange || [null, null]
    );
    const { shifts, totalCount } = result;

    // TODO: Сделать моделей, положить их в стор.
    const resultShifts = [];
    for (let i = 0; i < shifts.length; i++) {
      const json = shifts[i];
      const shift = await ScannerWorkingShift.create(this, json);
      resultShifts.push(shift);
    }

    runInAction(() => {
      this.shiftMap.clear();
      this.totalCount = totalCount;
      resultShifts.forEach((s) => {
        this.shiftMap.set(`${s.id}`, s);
      });
    });

    this.setPending(false);
    return resultShifts;
  }

  /**
   * Вернуть все известные смены из стора.
   */
  @computed get shifts() {
    const array = [];
    this.shiftMap.forEach((shift) => array.push(shift));
    return array;
  }

  getShift(id) {
    return this.shiftMap.get(`${id}`);
  }
}

export default WorkingShiftsStore;
