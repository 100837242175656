import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { Box, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

import useStores from "~/hooks";
import { Preloader } from "~/components";

import GantLine from "../../rendering/GantLine";
import GantHeader from "../../rendering/GantHeader";

import { PeriodSizeSwitcher, PeriodSwitcher } from "../components/schedule";
import { AddingModellingPlanDialog } from "../components/dialogs";
import ModellingCards from "~/modules/rendering/ModellingCards";

/**
 * Диаграмма Ганта расписания моделирования.
 *
 */
const GantSchedule = observer(() => {
  const [now, setNow] = useState(moment());
  const [period, setPeriod] = useState("month");
  const [facility, setFacility] = useState();
  const [dayWidth, setDayWidth] = useState(100);

  const [isPlanningDialogVisible, setIsPlanningDialogVisible] = useState(false);

  const { employeesStore, facilityStore } = useStores();
  const { renderersArray } = employeesStore;
  const { isPendingRenderingPlans } = facilityStore;

  const [start, end] = useMemo(() => {
    return [now.clone().startOf(period), now.clone().endOf(period)];
  }, [now, period]);

  const openPlanningDialog = useCallback(
    (facility) => {
      setFacility(facility);
      setIsPlanningDialogVisible(true);
    },
    [setIsPlanningDialogVisible]
  );

  const closePlanningDialog = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setIsPlanningDialogVisible(false);
      setFacility();
    },
    [setIsPlanningDialogVisible]
  );

  useEffect(() => {
    const fetch = async () => {
      facilityStore.fetchRenderings(0); // которые нужно планировать
      facilityStore.fetchRenderingPlans(0, start, end); // уже в плане
    };
    fetch();
  }, [facilityStore, start, end]);

  const lines = useMemo(() => {
    let i = 0;
    return renderersArray.map((modeller) => {
      i += 1;
      return (
        <GantLine
          key={`${modeller.id}`}
          dayWidth={dayWidth}
          startOfPeriod={start}
          endOfPeriod={end}
          modeller={modeller}
          borderTop={i === 1}
        />
      );
    });
  }, [renderersArray, start, end, dayWidth]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <PeriodSizeSwitcher period={period} setPeriod={setPeriod} />
        <PeriodSwitcher daterange={[start, end]} now={now} period={period} setNow={setNow} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", mt: 1, flex: 1, overflow: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "column", mr: "1rem", flex: 1, overflow: "auto" }}>
          <Preloader isPending={isPendingRenderingPlans} />
          {!isPendingRenderingPlans && (
            <>
              <GantHeader key="headerTop" startOfPeriod={start} endOfPeriod={end} setDayWidth={setDayWidth} />
              {lines}
              <GantHeader key="headerBottom" startOfPeriod={start} endOfPeriod={end} borderTop={false} />
            </>
          )}
        </Box>

        <ModellingCards onCreatingPlanPressed={openPlanningDialog} />
      </Box>

      <Fab
        onClick={openPlanningDialog}
        key="fab"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        color="primary"
        aria-label="AddPlan"
      >
        <Add />
      </Fab>

      <AddingModellingPlanDialog
        isOpen={isPlanningDialogVisible}
        onClose={closePlanningDialog}
        branchId={0}
        facility={facility}
      />
    </>
  );
});

export default GantSchedule;
