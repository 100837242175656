import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, Tooltip, Typography } from "@mui/material";

import { PermPhoneMsg, ViewInAr } from "@mui/icons-material";
import Translator from "../../components/Translator";

/**
 * Виджет отображение работника-менеджера.
 */
const ManagerWidget = observer(({ manager, role = "sales-manager" }) => {
  const roleIcons = useMemo(() => {
    if (role === "sales-manager") {
      return {
        toolTip: <PermPhoneMsg color="white" />,
        render: <PermPhoneMsg color="info" />,
      };
    } else if (role === "modelling-manager") {
      return {
        toolTip: <ViewInAr color="white" />,
        render: <ViewInAr color="info" />,
      };
    }
    return {
      toolTip: <PermPhoneMsg color="white" />,
      render: <PermPhoneMsg color="info" />,
    };
  }, [role]);
  if (!manager) return null;

  const { email } = manager;
  let { name } = manager;
  if (!name) {
    const { firstname, lastname } = manager;
    name = `${lastname} ${firstname}`;
  }

  const tooltip = (
    <Box sx={{ flexDirection: "row", display: "flex" }}>
      <Box sx={{ flexDirection: "column", display: "flex", m: 0.5 }}>{roleIcons.toolTip}</Box>
      <Box>
        {name && <Typography variant="h6">{name}</Typography>}
        {role && (
          <Typography variant="body2">
            <Translator text={role} />
          </Typography>
        )}
        {email && (
          <Typography variant="body2">
            <a href={`mailto:${email}`}>{email}</a>
          </Typography>
        )}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "end", mt: "0.75rem" }}>
      <Box sx={{ flexDirection: "column", display: "flex", mr: 0.5 }}>
        <Tooltip arrow title={tooltip}>
          {roleIcons.render}
        </Tooltip>
      </Box>
      <Box>
        {name && (
          <Typography sx={{ fontWeight: 500 }} variant="body">
            {name}
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default ManagerWidget;
