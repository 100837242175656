import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { Box, Tooltip } from "@mui/material";
import { DataGrid, useGridApiRef } from "~/components/grid";

import useStores from "~/hooks";

import AddPayment from "../AddPayment";
import { Translator } from "~/components";
import ContractLink from "../../contracts/components/buttons/ContractLink";
import DownloadInvoice from "../DownloadInvoice";
import InvoiceContextMenu from "../components/menus/InvoiceContextMenu";

// TODO: унести в тему
import "./datagrid.css";

/**
 * Таблица инвойсов с пагинацией и опцией добавить каждому платёж.
 */
const Invoices = observer((props) => {
  const { invoiceStore, branchStore, languageStore } = useStores();
  const { branch } = branchStore;
  const { invoicesArray } = invoiceStore;
  const { daterange, searchBy, states: selectedStates, refreshPls } = props;
  const [isPending, setIsPending] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const gridApi = useGridApiRef();

  const [rowMenuIsOpen, setRowMenuIsOpen] = useState(false);
  const [rowMenuPosition, setRowMenuPosition] = useState([null, null]);
  const [selectedRow, setSelectedRow] = useState();

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      const id = Number(event.currentTarget.getAttribute("data-id"));

      // убираем выделение с прежних строк
      const selectedIds = gridApi.current.getSelectedRows();
      selectedIds.forEach((value, x) => {
        gridApi.current.selectRow(x, false, true);
      });

      // выделяем текущую строку
      gridApi.current.selectRow(id);
      setSelectedRow(id);

      setRowMenuPosition([event.clientX - 2, event.clientY - 4]);
      setRowMenuIsOpen(true);
    },
    [gridApi]
  );
  const closeContextMenu = useCallback(() => {
    setRowMenuPosition([null, null]);
    setRowMenuIsOpen(false);
  }, []);

  const fetchInvoices = useCallback(async () => {
    setIsPending(true);
    if (paginationModel.pageSize < 1) return;
    if (!!branch) {
      await invoiceStore.getInvoices(branch, searchBy, paginationModel, selectedStates, daterange);
    }
    setIsPending(false);
  }, [branch, searchBy, paginationModel, invoiceStore, selectedStates, daterange]);

  // Вызовем fetchData при изменении нужных данных
  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices, refreshPls]);

  const columns = useMemo(() => {
    const array = [
      {
        field: "contractNumber",
        headerName: languageStore.translate({ text: "Contract" }),
        type: "string",
        width: 180,
        renderCell: ({ row }) => <ContractLink contractId={row.contractId} contractNumber={row.contractNumber} />,
      },
      {
        field: "number",
        headerName: languageStore.translate({ text: "Number" }),
        type: "string",
        width: 150,
      },
      {
        field: "createdAt",
        type: "date",
        headerName: languageStore.translate({ text: "Created at" }),
        width: 100,
        valueGetter: (value) => value?.toDate(),
        renderCell: ({ row }) => {
          return <Translator date={row?.createdAt} />;
        },
      },
      {
        field: "clientInfo",
        headerName: languageStore.translate({ text: "Client" }),
        type: "string",
        width: 200,
        valueGetter: (value) => value?.name,
      },
      {
        field: "managerInfo",
        headerName: languageStore.translate({ text: "Manager" }),
        type: "string",
        width: 150,
        valueGetter: (value) => value?.name,
      },
      {
        field: "amount",
        type: "number",
        headerName: languageStore.translate({ text: "Value" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.amount} />;
        },
      },
      {
        field: "vatAmount",
        type: "number",
        headerName: languageStore.translate({ text: "VAT Amount" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.vatAmount} />;
        },
      },
      {
        field: "valueWithoutVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Value without VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.valueWithoutVAT} />;
        },
      },
      {
        field: "method",
        type: "string",
        headerName: languageStore.translate({ text: "Method" }),
        width: 150,
        renderCell: (params) => {
          return params.row.methodLabel;
        },
      },
      {
        field: "payedAmount",
        type: "number",
        headerName: languageStore.translate({ text: "Payed" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.payedAmount} />;
        },
      },
      {
        field: "valueVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Value VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.valueVAT} />;
        },
      },
      {
        field: "payedAmountWithoutVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Payed without VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.payedAmountWithoutVAT} />;
        },
      },
      {
        field: "payments",
        type: "string",
        headerName: languageStore.translate({ text: "Payment date" }),
        width: 150,
        valueGetter: (value) => {
          const dates = value.map((p) => languageStore.translate({ date: p.date }));
          return dates.join(" ");
        },
      },
    ];

    // if (permissionsSet.has(`payments.add_payment`)) {
    array.push({
      field: "actions",
      headerName: languageStore.translate({ text: "Actions" }),
      width: 200,
      renderCell: (params) => {
        return <AddPayment invoice={params.row} />;
      },
    });
    // }

    array.push({
      field: "downloadInvoice",
      headerName: <Translator text="Download invoice" />,
      width: 200,
      renderCell: (params) => {
        return <DownloadInvoice invoiceId={params.row.id} />;
      },
    });

    //   if (permissionsSet.has(`payments.delete_invoice`)) {
    //     array.push({
    //       field: "deleteInvoice",
    //       headerName: <Translator text="Delete invoice" />,
    //       width: 200,
    //       renderCell: (params) => {
    //         return <DeleteInvoice invoice={params.row} />;
    //       },
    //     });
    //   }

    return array;
  }, [languageStore]);

  // функция раскраски ячейки
  const getCellClassName = (params) => {
    const { field, value, row } = params;
    const { amount } = row;
    let className = "";
    if (field === "payedAmount") {
      if (value === amount) {
        className += "green-cell"; // оплачено точно
      } else if (value > amount) {
        className += "red-cell"; // переплата, надо разбираться
      } else if (value > 0) {
        className += "orange-cell"; // оплачен частично
      }
    }
    return className;
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", m: 1, justifyContent: "end" }}>
        <Tooltip arrow title={<Translator text="amount" />}>
          <Box sx={{ ml: 4, fontWeight: "bold", fontSize: "large", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.amount || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <Tooltip arrow title={<Translator text="paid" />}>
          <Box sx={{ ml: 4, fontWeight: "bold", fontSize: "large", color: "#428c00", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.paid || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <Tooltip arrow title={<Translator text="debt" />}>
          <Box sx={{ ml: 4, fontWeight: "bold", fontSize: "large", color: "#8f2212", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.debt || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
      </Box>

      <DataGrid
        apiRef={gridApi}
        rows={invoicesArray}
        key={invoicesArray.length}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 15, page: 0 } },
          //   sorting: {
          //     sortModel: [{ field: "createdAt", sort: "desc" }],
          //   },
        }}
        // apiRef={gridApi}
        disableColumnFilter // фильтры клиентские, убрать
        slotProps={{ row: { onContextMenu: handleContextMenu, style: { cursor: "context-menu" } } }}
        disableColumnSorting
        stickyHeader={true}
        autoPageSize={false}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={[10, 15, 20, 25, 50, 100]}
        onPaginationModelChange={setPaginationModel}
        rowCount={invoiceStore.invoiceTotalCount}
        loading={isPending}
        autoHeight={false}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        getCellClassName={getCellClassName}
        sx={{
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
      />
      <InvoiceContextMenu
        isOpen={rowMenuIsOpen}
        position={rowMenuPosition}
        handleClose={closeContextMenu}
        selectedInvoice={invoicesArray.find((invoice) => String(invoice.id) === String(selectedRow))}
      />
    </>
  );
});

export default Invoices;
