import { makeObservable, observable, computed } from "mobx";
import moment from "moment";

import ScanningStarted from "./ScanningStarted";
import ScanningFinished from "./ScanningFinished";
import ScanningResult from "./ScanningResult";

/**
 * План сканирования объекта.
 *
 * {
 *    "id": 18,
 *    "facilityId": 37,
 *    "employeeId": 10,
 *    "start": "2022-11-04T08:00:00",
 *    "finish": "2022-11-04T20:00:00",
 *    "started": null,
 *    "finished": null,
 *    "status": "created",
 *    "result": {
 *        "id": 18,
 *        "planId": 18,
 *        "createdAt": "2022-11-16T17:09:54.885571+00:00",
 *        "rawCloud": "https://disk.yandex.ru/?id=1231212sdfw11",
 *        "linkedCloud": "https://docs.google.com/spreadsheets/d/1q9ZUauFBCEnWygHnnTkBFfsKN767xa9XEGrAAtrklDs/edit#gid=2012497491",
 *        "linkedAt": "2022-12-09T13:35:28.443544+00:00"
 *    }
 * }
 *
 */

// TODO: result
export default class ScanningPlan {
  // данные
  @observable id; // идентификатор
  @observable status = ""; // строковый статус
  @observable start = null; // плановое время начала
  @observable finish = null; // планавое время окончания
  @observable started; // начат на самом деле
  @observable finished; // завершен на самом деле
  @observable result; // результат сканированияи
  @observable isRepeat = false; // является ли повторным визитом
  @observable repeatReason = ""; // причина повторного визита
  // связанные сущности
  @observable facility;
  @observable employee;

  constructor(facility, employee, props) {
    makeObservable(this);
    const { id, status, start, finish, started, finished, result, isRepeat, repeatReason } = props;

    // console.log(props);
    this.id = id;
    this.status = status;

    this.start = moment(start);
    this.finish = moment(finish);

    this.started = started == null ? null : new ScanningStarted(this, started);
    this.finished = finished == null ? null : new ScanningFinished(this, finished);
    this.result = result == null ? null : new ScanningResult(this, result);

    this.isRepeat = isRepeat ?? false;
    this.repeatReason = repeatReason || ""; // Используем значение из API

    this.facility = facility;
    this.employee = employee;
  }


  /**
   * Начато ли сканирования объекта.
   */
  @computed get isStarted() {
    return this.started != null;
  }

  /**
   * Завершено ли сканирования объекта.
   */
  @computed get isFinished() {
    return this.finished != null;
  }

  /**
   * Есть ли ссылка на исходное облако.
   */
  @computed get hasRaw() {
    return !!this.result?.rawCloud;
  }

  /**
   * Есть ли ссылка на сшитое облако.
   */
  @computed get hasLinked() {
    return !!this.result?.linkedCloud;
  }

  /**
   * Полностью ли отсканирован по плану?
   */
  @computed get isScanned() {
    return this.isFinished && this.hasLinked;
  }

  /**
   * Является ли это повторным визитом.
  */
  @computed get isRepeatVisit() {
    return this.isRepeat;
  }

  /**
   * Получить причину повторного визита.
   */
  @computed get getRepeatReason() {
    return this.isRepeat ? this.repeatReason : "Not a repeat visit";
  }
}
