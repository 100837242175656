import { computed, makeObservable, observable } from "mobx";

/**
 * Общая информация о пользователе.
 */
class UserInfo {
  @observable id;
  @observable email;
  @observable firstname;
  @observable lastname;

  constructor(data) {
    makeObservable(this);

    this.id = data.id;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
  }

  /**
   * Короткое имя "Иванов И."
   */
  @computed get name() {
    return `${this.lastname} ${this.firstname.charAt(0)}.`;
  }
}

export default UserInfo;