import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import ReportMeta from "./ReportMeta";
import ReportSection from "./ReportSection";

class Report {
  @observable store = null;
  @observable datetime = null;
  @observable name = null;
  @observable meta = [];
  @observable sections = [];

  constructor(props, store) {
    makeObservable(this);

    this.store = store;

    this.datetime = moment(props.datetime);
    this.name = props.name;

    this.setMeta(props.metadata);
    this.setSections(props.sections);
  }

  @action setMeta(metadata = {}) {
    Object.keys(metadata).forEach((key) => {
      this.meta.push(
        new ReportMeta({ ...metadata[key], name: key }, this.store)
      );
    });
  }

  @action setSections(sectionsArray = []) {
    this.sections.clear();
    sectionsArray.forEach((sectionData) => {
      const section = new ReportSection(sectionData, this.store);
      if (section) {
        this.sections.push(section);
      }
    });
  }
}

export default Report;
