import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Chip, Divider, Typography } from "@mui/material";

import useStores from "~/hooks";
import Translator from "../../components/Translator";
import Preloader from "../../components/Preloader";
import RenderingCard from "./RenderingCard";

/**
 * Список карточек объектов без плана моделирования.
 */
const ModellingCards = observer(({ onCreatingPlanPressed }) => {
  const { facilityStore } = useStores();
  const { isPending, isPendingRenderingPlans, facilitiesModellingNotPlanned } = facilityStore;
  const isPendingPlans = useMemo(() => {
    return isPending || isPendingRenderingPlans;
  }, [isPending, isPendingRenderingPlans]);

  const cards = useMemo(() => {
    if (isPendingPlans) {
      return null;
    }
    return facilitiesModellingNotPlanned.map((facility) => {
      return <RenderingCard key={facility.id} facility={facility} onCreatingPlanPressed={onCreatingPlanPressed} />;
    });
  }, [facilitiesModellingNotPlanned, isPendingPlans, onCreatingPlanPressed]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "27rem",
        pl: "0.125rem",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
      >
        <Translator text="Facilities to plan" />
        <Chip sx={{ m: "0.5rem" }} label={facilitiesModellingNotPlanned.length} />
      </Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          mb: 12,
        }}
      >
        <Preloader key="preloader" isPending={isPendingPlans} />
        {cards}
      </Box>
    </Box>
  );
});

export default ModellingCards;
