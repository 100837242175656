import { makeObservable, observable } from "mobx";

/**
 * Краткая информация о счёте.
 */
class InvoiceInfo {
  @observable id;
  @observable number = "";

  constructor(props) {
    makeObservable(this);

    this.id = props.id;
    this.number = props.number;
  }
}

export default InvoiceInfo;