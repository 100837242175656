import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Toolbar, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { DateRangePicker, SearchField, Translator } from "~/components";
import { StatesSelect } from "../components";
/**
 * Тулбар модуля счетов и платежей.
 */
const ModuleToolbar = observer(
  ({ selectedStates, setSelectedStates, setSearchBy, setDaterange, setRefreshPls, isPending, view }) => {
    // Изменился набор состояний
    const onSelectStates = useCallback(
      (e) => {
        const {
          target: { value },
        } = e;
        setSelectedStates(value);
      },
      [setSelectedStates]
    );

    // Изменилась строка поиска
    const onSearch = useCallback(
      (value) => {
        if (value.length > 2) setSearchBy(value);
        else setSearchBy("");
      },
      [setSearchBy]
    );

    const handleRefresh = useCallback(
      (e) => {
        setRefreshPls((prev) => !prev);
      },
      [setRefreshPls]
    );

    // Изменились даты
    const onAcceptDaterange = useCallback((value) => setDaterange(value), []);

    return (
      <Toolbar disableGutters={true}>
        <SearchField label={<Translator text={"Search invoices..."} />} onSearch={onSearch} />
        {view === "invoices" && (
          <StatesSelect
            options={["paid", "not-paid", "partially-paid", "overpaid"]}
            value={selectedStates}
            onSelect={onSelectStates}
          />
        )}
        <DateRangePicker onAccept={onAcceptDaterange} />
        <IconButton color="primary" onClick={handleRefresh} disabled={isPending}>
          <Tooltip title="Refresh">
            <RefreshIcon />
          </Tooltip>
        </IconButton>
      </Toolbar>
    );
  }
);

export default ModuleToolbar;
