import { action, computed, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";

import { ModellingPlan } from "../modules/modelling/models";

import { Expense } from "../modules/expenses/models";

/**
 * Объект сканирования и моделирования.
 */
class Facility {
  // данные
  @observable id = null;
  @observable name = null;
  @observable description = null;
  @observable area = null;
  @observable realArea = null;
  @observable kind = null;
  @observable address = null;

  // связанные сущности
  @observable branch = null; // филиал
  @observable contract = null;
  @observable scanningPlan = null; // план сканирования
  @observable scanningPlans = []; // план сканирования
  @observable modellingPlans = []; // планы моделирования
  @observable expenses = []; // расходы по объекту
  @observable contractInfo = null;
  @observable resultStatusUpdated = false;

  // участники
  @observable modellingManager = null;
  @observable salesManager = null;

  // моделирование
  @observable modellingBudget = 0;

  // служебные
  @observable _store = null;

  constructor(store, contract, props) {
    makeObservable(this);

    const { employeesStore, expenseStore, scanningPlansStore } = store.rootStore;
    const { id, name, kind, area, description, address, scanningPlan, scanningPlans, modellingPlans, contractInfo, realArea } = props;

    this.id = `${id}`; // TODO: строка вместо числа нужна стору, пусть он об этом и думает
    this.name = name;
    this.kind = kind;
    this.area = area;
    this.realArea = realArea;
    this.description = description;
    this.address = address;

    this.contractInfo = contractInfo
      ? contractInfo
      : { id: props.contractId, number: props.contractNumber };

    // Связанные сущности
    this.contract = contract;
    this.expenses = [];

    if (!!scanningPlan) {
      const scanningEmployee = employeesStore.getEmployeeById(scanningPlan.employeeId);
      this.scanningPlan = scanningPlansStore.addPlan(scanningPlan, this, scanningEmployee);
    }

    // Планы сканирования, в том числе повторные
    scanningPlans?.forEach((plan) => {
      const scanningEmployee = employeesStore.getEmployeeById(plan.employeeId);
      this.scanningPlans.push(scanningPlansStore.addPlan(plan, this, scanningEmployee));
    });

    // они не всегда есть, если начинаем с плана - внутри уже плана нет.
    if (modellingPlans) {
      this.modellingPlans = modellingPlans.map((p) => {
        const modeller = employeesStore.getEmployeeById(p.modellerId);
        return new ModellingPlan(this, modeller, p);
      }).sort((a, b) => a.finish - b.finish);
    }

    // Участники
    const participants = props?.participations || [];
    participants.forEach((participant) => {
      if (participant.roleCodename === "modelling-manager") {
        this.modellingManager = participant.userInfo;
      } else if (participant.roleCodename === "sales-manager") {
        this.salesManager = participant.userInfo;
      }
    });

    // бюджет моделирования
    const { amount, currency } = props.modellingBudget;
    this.modellingBudget = { amount, currency };

    const { id: branchId } = props.branchInfo;
    this.branch = store.root.branchStore.getBranchById(branchId);


    // служебные

    // Служебные данные
    this._store = store;

    // Создать все расходы к Объекту
    props.expenses.forEach((expense) => {
      employeesStore.ensureEmployee(expense.author);
      this.expenses.push(
        new Expense(
          {
            ...expense,
            scope: "facility",
            facility: this,
            documentsMeta: expense.documentsMeta || expense.documents,
          },
          expenseStore
        )
      );
    });
  }


  // TODO: это в модель плана моделирования объекта
  @action async editModellingPlan(data) {
    const updatedPlan = await this._store.api.editModellingPlan(data);

    runInAction(() => {
      const existingPlan = this.modellingPlans.find(plan => plan.id === updatedPlan.id);
      if (existingPlan) {
        existingPlan.start = moment(updatedPlan.start);
        existingPlan.finish = moment(updatedPlan.finish);
        existingPlan.modeller = this._store.rootStore.employeesStore.getEmployeeById(updatedPlan.modellerId);

        if (updatedPlan.result) {
          existingPlan.setResult(updatedPlan.result);
        } else {
          existingPlan.result = null;
        }
      }
    });

    this._store.processModellingPlan(updatedPlan);
  }


  @action addModellingPlan(planData) {
    const modeller = this._store.rootStore.employeesStore.getEmployeeById(planData.modellerId);
    const newPlan = new ModellingPlan(this, modeller, planData);
    this.modellingPlans.push(newPlan); // Реактивно добавляем план
  }


  // TODO: это в модель плана моделирования объекта
  @action async setModellingPlanResult(data) {
    const plan = await this._store.api.setModellingPlanResult(data);
    this._store.processModellingPlan(plan);
    runInAction(() => {
      const updatedPlan = this.modellingPlans.find((p) => p.id === data.planId);
      if (updatedPlan) {
        updatedPlan.setResult(data.result);
      }
      this.resultStatusUpdated = true;
    });
  }



  /**
   * Label нужен для совместимости интерфейса когда мы
   * отобоажаем один из объектов, например: Facility, Contract или Shift
   */
  @computed get label() {
    return this.name;
  }

  // не нужно, нужно сразу this.contract.state
  @computed get contractState() {
    return this.contract && this.contract.state;
  }

  @computed get services() {
    const array = [];
    this._store.rootStore.contractStore.servicesMap.forEach((service) => {
      if (service.facilityId === this.id) {
        array.push(service);
      }
    });
    return array;
  }

  @computed
  get scanPlan() {
    let plan = null;
    this.services.forEach((service) => {
      if (service.kind === "scanning") {
        plan = service;
      }
    });
    return plan;
  }

  /**
   * Adds expense to facility.
   * 
   * @param {*} expense 
   */
  @action addExpense(expense) {
    this.expenses.push(expense);
  }

  @computed
  get allModelled() {
    let modelled = true;
    this.modellingPlans.forEach((service) => {
      if (!service.hasResult) {
        modelled = false;
      }
    });
    return modelled;
  }



  /** 
   * Все ли выезды на сканирование выполнены?
   * 
   * @returns 
   */
  @computed get isScanned() {
    let isFullyScanned = true;
    let isAnyScanned = false;
    this.scanningPlans.forEach((plan) => {
      if (!plan.isScanned) {
        isFullyScanned = false;
      } else {
        isAnyScanned = true;
      }
    });
    return [isFullyScanned, isAnyScanned];
  }

  /**
   * Дата последнего сканирования.
   */
  @computed get scanDate() {
    let date;
    this.scanningPlans.forEach((plan) => {
      if (!date || plan.finish > date) { date = plan.finish; }
    });
    return date;
  }

  @computed get latestScanningPlan() {
    if (!this.scanningPlans || this.scanningPlans.length === 0) return null;
    return this.scanningPlans.reduce((latest, plan) =>
      new Date(plan.finish) > new Date(latest.finish) ? plan : latest
    );
  }

  // Есть ли повторные визиты.
  @computed get hasMultiplePlans() {
    return this.scanningPlans?.length > 2; //TODO: разобраться почему после регистрации первого выезда в scanningPlans сразу 2 регает
  }

  // Проверяем, есть ли незавершенные планы.
  @computed get hasUnfinishedPlans() {
    return this.scanningPlans.some(
      (plan) => !plan?.isFinished
    );
  }

  @computed
  get value() {
    return this.id;
  }

  // нахуй из модели
  @computed get resultFormConfig() {
    const fields = [];

    fields.push({
      name: "planId",
      type: "hidden",
      initialValue: "payload",
    });

    fields.push({
      name: "url",
      title: "url",
      type: "string",
      isRequired: true,
      isReadOnly: false,
      validate: true,
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Set modelling result",
      formText: "To set result fill in the form below",
      fields,
    };
  }


  // TODO: Убрать нахуй это отсюда
  @computed get editPlanFormConfig() {
    const fields = [];

    fields.push({
      name: "planId",
      type: "hidden",
      initialValue: "payload",
    });

    fields.push({
      name: "facilityId",
      fakeName: "facility",
      title: "Facility",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: this.id,
      loading: this._store.isPending || this._store.isPendingRenderingPlans,
      options: [...this._store.facilitiesToPlanModelling, this],
    });

    fields.push({
      name: "modellerId",
      fakeName: "modeller",
      title: "Modeller",
      type: "select",
      canCreate: false,
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
      loading: this._store.rootStore.employeesStore.isPending,
      options: this._store.rootStore.employeesStore.renderersArray,
    });

    fields.push({
      name: "start",
      title: "Start",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
    });

    fields.push({
      name: "finish",
      title: "finish",
      type: "date",
      isRequired: true,
      isReadOnly: false,
      validate: true,
      initialValue: "payload",
    });

    return {
      submitText: "Submit",
      cancelText: "Cancel",
      formTitle: "Edit modelling plan",
      formText: "To edit modelling plan fill in the form below",
      fields,
    };
  }
}

export default Facility;
