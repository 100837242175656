import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { DataGrid } from "~/components";
import useStores from "~/hooks";

const translations = {
  en: {
    name: "Name",
    commercialProposals: "Commercial Proposals",
    sumOfCommercialProposals: "Sum of Commercial Proposals",
    contracts: "Contracts",
    sumOfContracts: "Sum of Contracts",
    plan: "Plan",
    percentageOfPlan: "% of Plan",
  },
  ru: {
    name: "Название",
    commercialProposals: "Коммерческие предложения",
    sumOfCommercialProposals: "Сумма коммерческих предложений",
    contracts: "Контракты",
    sumOfContracts: "Сумма контрактов",
    plan: "План",
    percentageOfPlan: "% от плана",
  },
};

const getTranslation = (key, lang) => {
  return translations[lang] && translations[lang][key] ? translations[lang][key] : key;
};

const AnalyticsGrid = observer(({ startDate, endDate }) => {
  const { analyticsStore, languageStore } = useStores();

  useEffect(() => {
    if (startDate && endDate) {
      analyticsStore.fetchAnalyticsData(startDate, endDate);
    }
  }, [analyticsStore, startDate, endDate]);

  const currentLang = languageStore.lang || "en"; 

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: getTranslation("name", currentLang),
      flex: 1,
      renderCell: ({ value }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {value}
        </div>
      ),
    },
    {
      field: "kpi",
      headerName: getTranslation("commercialProposals", currentLang),
      type: "number",
      flex: 1,
      renderCell: ({ value }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {value}
        </div>
      ),
    },
    {
      field: "sumKpi",
      headerName: getTranslation("sumOfCommercialProposals", currentLang),
      type: "number",
      flex: 1.5,
      renderCell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {row.sumKpi.toLocaleString(currentLang)}
        </div>
      ),
    },
    {
      field: "contracts",
      headerName: getTranslation("contracts", currentLang),
      type: "number",
      flex: 1,
      renderCell: ({ value }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {value}
        </div>
      ),
    },
    {
      field: "sumContracts",
      headerName: getTranslation("sumOfContracts", currentLang),
      type: "number",
      flex: 1.5,
      renderCell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {row.sumContracts.toLocaleString(currentLang)}
        </div>
      ),
    },
    {
      field: "plan",
      headerName: getTranslation("plan", currentLang),
      type: "number",
      flex: 1,
      renderCell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {row.plan.toLocaleString(currentLang)}
        </div>
      ),
    },
    {
      field: "planCompletion",
      headerName: getTranslation("percentageOfPlan", currentLang),
      type: "string",
      flex: 1,
      renderCell: ({ row }) => {
        const rawValue = row.planCompletion;
        const value = typeof rawValue === "number" ? rawValue : parseFloat(rawValue) || 0;

        let backgroundColor;
        if (value < 50) {
          backgroundColor = "rgba(255, 0, 0, 0.2)";
        } else if (value <= 100) {
          backgroundColor = "rgba(255, 255, 0, 0.2)";
        } else {
          backgroundColor = "rgba(0, 128, 0, 0.2)";
        }

        return (
          <div style={{ backgroundColor, padding: "8px", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
            {`${value}%`}
          </div>
        );
      },
    },
  ], [currentLang]);

  return (
    <DataGrid
      columns={columns}
      rows={analyticsStore.analyticsData}
      loading={analyticsStore.isLoading}
      autoHeight
      disableSelectionOnClick
      style={{ width: "100%" }}
    />
  );
});

export default AnalyticsGrid;
