import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import useStores from "~/hooks";
import Translator from "../../components/Translator";

/**
 * Кнопка изменения состояния контракта.
 */
const StateButton = observer(({ contract, state, sx }) => {
  const { contractStore } = useStores();
  const status = contractStore.getStatus(state);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const changeState = useCallback(async () => {
    contractStore.changeContractState(contract, status);
  }, [contractStore, contract, status]);

  const onClick = useCallback(() => {
    if (state === "Cancelled") {
      setOpenCancelDialog(true);
    } else {
      changeState();
    }
  }, [state, changeState]);

  const handleConfirmCancel = useCallback(() => {
    changeState();
    setOpenCancelDialog(false);
  }, [changeState]);

  const handleCloseCancelDialog = useCallback(() => {
    setOpenCancelDialog(false);
  }, []);

  return (
    <>
      <Tooltip
        arrow
        title={
          <>
            <Translator text="Change contract status to" />
            &nbsp;"
            <Translator text={state} />"
          </>
        }
      >
        <Box>
          <LoadingButton
            onClick={onClick}
            loading={contract.isPending}
            disabled={!contract.canSwitch[status.slug]}
            key={state.slug}
            variant="contained"
            color="info"
            sx={sx}
          >
            <Translator text={state} />
          </LoadingButton>
        </Box>
      </Tooltip>

      <Dialog
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            Are you sure you want to cancel this contract? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="error" autoFocus>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default StateButton;
