import { action, computed, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";
import Facility from "../models/Facility";
import { ScanningPlan } from "~/modules/scanning/data/models";


class FacilityStore {
  @observable rootStore = null;
  @observable root = null;
  @observable api = null;
  @observable facilitiesMap = new Map();
  @observable facilitiesByUserId = new Map();
  @observable pendingFacilitiesMap = new Map();
  @observable pending = false;
  @observable pendingScanningPlans = false;
  @observable pendingRenderingPlans = false;
  @observable kindsArray = ["building", "house", "flat", "premises", "townhouse", "facade", "other",];

  // new from @ai
  @observable contract = null;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore; // TODO: везде заменить на правильно
    this.root = rootStore; // так правильно.
    this.api = this.root.api;
  }

  @action addFacility(contract, data) {
    const facility = new Facility(this, contract, data);
    this.facilitiesMap.set(facility.id, facility);
    return facility;
  }

  @action getFacilityById(id) {
    return this.facilitiesMap.get(`${id}`);
  }

  @action clearFacilities() {
    this.pendingFacilitiesMap.clear();
    this.facilitiesMap.clear();
  }

  @action
  async getFacilityByIdAsync(id) {
    if (this.pendingFacilitiesMap.get(`${id}`)) {
      return this.pendingFacilitiesMap.get(`${id}`);
    }
    const promise = this.fetchFacilityByIdAsync(id);
    this.pendingFacilitiesMap.set(`${id}`, promise);
    return promise;
  }

  @action
  async fetchFacilityByIdAsync(id) {
    const facilityData = await this.api.getFacilityById(id);
    const facility = this.addFacility(null, facilityData);
    return facility;
  }


  /**
   * Запросить данные по
   * 
   * @param {*} branchId 
   */
  @action async fetchRenderings(branchId) {
    this.setPending(true);
    this.facilitiesMap.clear();
    this.rootStore.contractStore.clearServisesByType("modelling");

    const facilities = await this.api.getRenderFacilities({ branchId });
    if (facilities) {
      facilities.forEach((facilityData) => this.addFacility(null, facilityData));
    }
    this.setPending(false);
  }

  /**
   * Вызывается только из Schedule.jsx
   * 
   * @param {*} branchId 
   * @param {*} from 
   * @param {*} to 
   */
  @action async fetchScanningPlans(branchId, from, to) {
    this.setPendingScanningPlans(true);
    const facilities = await this.api.getScanningPlans({
      withFinished: true,
      withResult: true,
      branchId,
      from,
      to,
    });
    if (facilities) {
      this.processFacilitiesForPlans(facilities);
    }
    this.setPendingScanningPlans(false);
  }

  /**
   * Сложить в стор полученные планы моделирования.
   * 
   * @param {*} plans массив планов моделирования.
   */
  @action processModellingPlans(plans) {
    plans.forEach((plan) => {
      const { facility } = plan;
      facility.modellingPlans = [plan];
      this.addFacility(null, facility);
    });
  }


  /**
   * Запросить планы моделирования.
   * 
   * Отсюда строится диаграмма Ганта занятости архитекторов.
   * 
   * @param {*} branchId 
   * @param {*} from 
   * @param {*} to 
   */
  @action async fetchRenderingPlans(branchId, from, to) {
    this.setPendingRenderingPlans(true);
    const start = from.format("YYYY-MM-DD");
    const end = to.format("YYYY-MM-DD");
    this.rootStore.contractStore.clearServisesByType("scanning");
    const plans = await this.api.getRenderingPlans({
      withFinished: true,
      withResult: true,
      branchId: 0, // всегда спрашиваем все
      from: start,
      to: end,
    });
    if (!plans) return;

    // обработать планы
    this.processModellingPlans(plans);

    this.setPendingRenderingPlans(false);
  }

  @action async processModellingPlanArray(data) {
    if (data.modellingPlan && data.modellingPlan.length) {
      data.modellingPlan.forEach((plan) => {
        this.processModellingPlan(plan);
      });
    }
  }

  @action async processModellingPlan(plan) {
    if (!this.rootStore) {
      console.error("RootStore is not defined");
      return;
    }

    let facility = this.getFacilityById(plan.facilityId);
    if (!facility) {
      facility = await this.fetchFacilityByIdAsync(plan.facilityId);
    }

    if (facility) {
      const modeller = plan.modeller || this.rootStore.employeesStore.getEmployeeById(plan.modellerId);
      this.rootStore.contractStore.addService({
        ...plan,
        kind: "modelling",
        modeller: modeller || null,
      });

      // Дополнительная проверка на уникальность перед добавлением
      const isDuplicate = facility.modellingPlans.some(existingPlan => existingPlan.id === plan.id);

      if (!isDuplicate) {
        facility.modellingPlans.push(plan);
      }
    }
  }



  @action addFacilityScanningPlans(scanningPlans) {
    scanningPlans.forEach((planData) => {
      const facility = this.getFacilityById(planData.facilityId);

      if (facility) {
        const scanningEmployee = this.rootStore.employeesStore.getEmployeeById(planData.employee.id);

        const plan = this.rootStore.scanningPlansStore.addPlan(planData, this, scanningEmployee);

        facility.scanningPlans = facility.scanningPlans || [];
        facility.scanningPlans.push(plan);
      }
    });
  }

  @action processFacilitiesForPlans(facilities) {
    this.facilitiesByUserId.clear();

    facilities.forEach((facilityData) => {
      const contract = this.rootStore.contractStore.getContractById(facilityData.contractId);

      const facility = this.addFacility(contract, facilityData);

      facilityData.scanningPlans.forEach((planData) => {
        const scanningEmployee = this.rootStore.employeesStore.getEmployeeById(planData.employee.id);

        const plan = this.rootStore.scanningPlansStore.addPlan(planData, this, scanningEmployee);

        // Привязываем план к объекту
        facility.scanningPlans = facility.scanningPlans || [];
        facility.scanningPlans.push(plan);

        // Добавляем план в карту по сотруднику
        const employeePlans = this.facilitiesByUserId.get(`${planData.employee.id}`) || [];
        employeePlans.push(facility);
        this.facilitiesByUserId.set(`${planData.employee.id}`, employeePlans);
      });
    });
  }


  @action
  setPendingScanningPlans(pending = false) {
    this.pendingScanningPlans = pending;
  }
  @action
  setPendingRenderingPlans(pending = false) {
    this.pendingRenderingPlans = pending;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  editModellingPlan(plan) {
    this.rootStore.contractStore.updateServise(plan);
  }

  @action async createModellingPlan(branchId, data) {
    this.pending = true;
    const newPlanData = {
      ...data,
      start: moment(data.start, "L").format("YYYY-MM-DD"),
      finish: moment(data.finish, "L").format("YYYY-MM-DD"),
    };

    try {
      const savedPlan = await this.api.createModellingPlan(branchId, newPlanData);
      runInAction(() => {
        const facility = this.getFacilityById(data.facilityId);
        if (facility) {
          facility.addModellingPlan(savedPlan);
        }
      });
      return savedPlan; // Возвращаем план, если все прошло успешно
    } catch (error) {
      throw error;
    } finally {
      this.pending = false;
    }
  }



  @computed get isPending() {
    return this.pending;
  }

  @computed get isPendingScanningPlans() {
    return this.pendingScanningPlans;
  }

  @computed get isPendingRenderingPlans() {
    return this.pendingRenderingPlans;
  }

  @computed
  get hasStartedScanning() {
    let hasStarted = false;
    this.facilitiesArray.forEach((facility) => {
      if (facility.scanPlan && facility.scanPlan.isStarted && !facility.scanPlan.isFinished) {
        hasStarted = true;
      }
    });
    return hasStarted;
  }

  @computed get facilitiesModellingNotPlanned() {
    const facilities = [];
    this.facilitiesArray.forEach((facility) => {
      if (facility && (!facility.modellingPlans || facility.modellingPlans.length === 0)) {
        facilities.push(facility);
      }
    });
    facilities.sort((a, b) => {
      if (!a.scanDate) return 1;
      if (!b.scanDate) return -1;
      if (a.scanDate.isBefore(b.scanDate)) { return -1; }
      return 1;
    });

    return facilities;
  }

  @computed get facilitiesToPlanModelling() {
    const facilities = [];
    this.facilitiesArray.forEach((facility) => {
      const [isFullyScanned, _] = facility.isScanned;
      if (facility && (!facility.modellingPlans || facility.modellingPlans.length === 0) && isFullyScanned) {
        facilities.push(facility);
      }
    });

    return facilities;
  }

  @computed
  get facilitiesArray() {
    return Array.from(this.facilitiesMap.values());
  }

  @computed
  get facilityIdsArray() {
    return Array.from(this.facilitiesMap.keys());
  }

  //------ Renovated methods.

  /**
   * Creates new Facility.
   *
   * @param {Contract} contract Контракт к которому мы добавляем объект.
   * @param {object} facilityData Данные объекта.
   */
  @action async createFacility(contract, facilityData) {
    const payload = { ...facilityData, description: facilityData.description || "" };
    const created = await this.api.addFacility(payload);
    let facility;
    runInAction(() => {
      const facility = this.addFacility(contract, created);
      contract.addFacility(facility);
    });
    return facility;
  }

  /**
   * Creates new Scanning Plan for Facility.
   *
   * @param {}
   * @param {}
   */
  @action async createFacilityScanningPlan({
    facilityId,
    finish,
    scannerId,
    start,
    isRepeat = false,
    repeatReason = "",
  }) {
    const payload = {
      facilityId,
      employeeId: scannerId,
      start: moment(start).utcOffset(0, true).toISOString(),
      finish: moment(finish).utcOffset(0, true).toISOString(),
      isRepeat,
      repeatReason: isRepeat ? repeatReason : "That is first time visit",
    };

    const planData = await this.api.addScanPlan(payload);
    const facility = this.facilitiesMap.get(`${planData.facilityId}`);
    const scanningEmployee = this.rootStore.employeesStore.getEmployeeById(planData.employeeId);

    const plan = new ScanningPlan(facility, scanningEmployee, planData);
    runInAction(() => {
      if (!facility.scanningPlans) {
        facility.scanningPlans = observable.array([]);
      }
      facility.scanningPlans.push(plan);
    });
    return plan;
  }


  @action async addRepeatPlan(data, facility) {
    try {
      const planData = await this.api.addScanPlan({
        facilityId: data.facilityId || facility.id,
        employeeId: data.employeeId,
        start: data.start,
        finish: data.finish,
        isRepeat: data.isRepeat || false,
        repeatReason: data.repeatReason || "No reason provided",
      });

      const plan = this.addPlan(planData, facility, this.root.employeesStore.getEmployeeById(planData.employeeId));
      return plan;
    } catch (error) {
      console.error("Error creating repeat plan:", error);
      throw error;
    }
  }






  // Метод для обновления существующего плана сканирования
  @action async updateFacilityScanningPlan({ planId, facilityId, finish, scannerId, start }) {
    const updatedData = {
      planId,
      facilityId,
      employeeId: scannerId,
      start: moment(start).utcOffset(0, true).toISOString(),
      finish: moment(finish).utcOffset(0, true).toISOString(),
    };

    return await this.rootStore.scanningPlansStore.updatePlanAsPost(facilityId, updatedData);
  }

}

export default FacilityStore;
