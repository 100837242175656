import React, { useState, useMemo } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { observer } from "mobx-react";
import useStores from "~/hooks";
import moment from "moment";
import Translator from "~/components/Translator";
import ContractButton from "../ContractButton";
import FileList from "~/components/FileList";

const ContractLink = observer(({ contractId, contractNumber, contractDate, disableClick = false }) => {
  const { contractStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    setLoading(true);
    setOpen(true);
    setError(null);

    try {
      await contractStore.getContractByIdd(contractId);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError(<Translator text="You do not have permission to view this contract." />);
      } else {
        setError(<Translator text="An error occurred while loading the contract." />);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    contractStore.clearCurrentContract();
    setError(null);
  };

  const contractData = contractStore.currentContract;

  // список всех расходов
  const expensesData = useMemo(() => {
    if (!contractData) return { expensesList: [], totalAmount: 0 };

    // начальные расходы из контракта
    const contractExpenses = contractData.expenses || [];

    // расходы из каждого объекта
    const facilityExpenses = contractData.facilities?.flatMap((facility) => facility.expenses || []) || [];

    // объединяем все расходы
    const allExpenses = [...contractExpenses, ...facilityExpenses];

    // считаем общую сумму расходов
    const totalAmount = allExpenses.reduce((sum, expense) => sum + parseFloat(expense?.value?.amount || 0), 0);

    return { expensesList: allExpenses, totalAmount };
  }, [contractData]);

  return (
    <>
      {/*  TODO: @ai: этот бокс не нужен, Илья. */}
      <Box
        onClick={handleClick}
        sx={{ cursor: disableClick ? "default" : "pointer", display: "flex", alignItems: "center" }}
      >
        <ContractButton number={contractNumber} />
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" sx={{ zIndex: 1500 }}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{`Contract ${contractNumber}`}</Typography>
            <Typography variant="h6">{moment(contractDate).format("YYYY-MM-DD")}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {error && <Typography color="error">{error}</Typography>}

              <Typography variant="body1" sx={{ fontSize: "1.1rem", mb: 2 }}>
                <strong>
                  <Translator text="Description:" />
                </strong>{" "}
                {contractData?.description}
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
                <Translator text="Client:" /> {contractData?.client?.name || "N/A"}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
                <Translator text="Manager:" /> {contractData?.manager?.name || "N/A"}
              </Typography>

              {/* Facilities Table */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                <Translator text="Facilities" />
              </Typography>
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Translator text="ID" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Name" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Description" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Area" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contractData?.facilities?.map((facility, index) => (
                      <TableRow key={index} sx={{ backgroundColor: getColorByStatus(facility.scanningPlan) }}>
                        <TableCell>{facility.id}</TableCell>
                        <TableCell>{facility.name}</TableCell>
                        <TableCell>{facility.description}</TableCell>
                        <TableCell>{facility.area}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Expenses Information */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                <Translator text="Expenses Information" />
              </Typography>
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Translator text="Expense ID" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Category" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Author" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Amount" />
                      </TableCell>
                      <TableCell>
                        <Translator text="Files" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expensesData.expensesList.map((expense, index) => (
                      <TableRow key={index}>
                        <TableCell>{expense.id}</TableCell>
                        <TableCell>{expense.category?.name || "N/A"}</TableCell>
                        <TableCell>{`${expense.author?.firstname || "Unknown"} ${
                          expense.author?.lastname || ""
                        }`}</TableCell>
                        <TableCell>
                          {expense.value ? `${expense.value.amount} ${expense.value.currency}` : "N/A"}
                        </TableCell>
                        <TableCell>
                          <FileList type="documents" files={expense.documents || []} noName />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3} align="right">
                        <strong>
                          <Translator text="Total" />
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>{expensesData.totalAmount} PLN</strong>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Translator text="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const getColorByStatus = (scanningPlan) => {
  if (!scanningPlan || scanningPlan.status !== "finished") {
    return "#F2DEDE"; // красный - не завершен или нет плана
  }
  if (scanningPlan.status === "finished" && scanningPlan.result) {
    return "#DFF0D8"; // зеленый - завершен и есть результат
  }
  return "#ADD8E6"; // синий - завершен, но результата нет
};

export default ContractLink;
