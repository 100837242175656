import { makeObservable, observable } from "mobx";
import moment from "moment";

import { Client } from "~/modules/clients/data/models";

/**
 * Stores Commercial Offer's data.
 */
class CommercialOffer {
  _store = null;

  @observable id = "";
  @observable number = "";
  @observable description = "";
  @observable createdAt = null;
  @observable date = null;
  @observable value = null;
  @observable state = "";
  @observable targetDate = null;
  @observable comment = "";
  @observable documents = [];
  @observable updatedAt = null;
  @observable crmLink = "";
  @observable isLead = false;

  @observable client = null;
  @observable author = null;

  constructor(store, props) {
    makeObservable(this);

    this._store = store;

    const { id, description, createdAt, date, number, value, documents, clientId, client, authorId, state, targetDate, crmLink } = props;
    const { historyRecords, isLead } = props;

    // в качестве комментария возьмём последний комментарий к исторической записи
    let comment = "";
    let updatedAt;
    if (historyRecords.length > 0) {
      comment = historyRecords[0].reason;
      updatedAt = historyRecords[0].datetime;
    }

    // values
    this.id = `${id}`;
    this.number = number;
    this.description = description;
    this.createdAt = moment(createdAt);
    this.date = moment(date);
    this.value = value;
    this.targetDate = moment(targetDate);
    this.state = state;
    this.comment = comment;
    this.documents = documents;
    this.updatedAt = moment(updatedAt);
    this.crmLink = crmLink;
    this.isLead = isLead;

    // relations
    let offerClient = this._store.root.clientStore.getClientById(clientId);
    if (!offerClient) {
      offerClient = new Client(client, this._store.root.clientStore);
    }
    this.client = offerClient;

    this.author = this._store.root.employeesStore.getEmployeeById(authorId);
  }
}

export default CommercialOffer;
