export const getAddPaymentFormConfig = (
  currency,
  invoiceId,
  contractNumber,
  clientName,
  amount,
  createdAt
) => ({
  submitText: "Create",
  cancelText: "Cancel",
  formTitle: "Creating payment",
  fields: [
    {
      name: "amount",
      title: "Amount with VAT",
      type: "number",
      viewConfig: "input",
      inputMode: "decimal",
      min: 1,
      step: 0.01,
      startAdornment: currency,
      isRequired: true,
      validate: true,
    },
    {
      name: "date",
      title: "Date",
      type: "date",
      isRequired: true,
      validate: true,
    },
    {
      name: "isPayerIndividualPerson",
      title: "Payer is individual person",
      type: "boolean",
      isRequired: true,
    },
    { name: "invoiceId", type: "hidden", initialValue: invoiceId },
    { name: "currency", type: "hidden", initialValue: currency },
    {
      name: "contractNumber",
      title: "Contract number",
      type: "string",
      initialValue: contractNumber,
      isReadOnly: true,
    },
    {
      name: "clientName",
      title: "Client name",
      type: "string",
      initialValue: clientName,
      isReadOnly: true,
    },
    {
      name: "totalAmount",
      title: "Amount",
      type: "number",
      initialValue: amount,
      isReadOnly: true,
      startAdornment: currency,
    },
    {
      name: "invoiceDate",
      title: "Invoice date",
      type: "date",
      initialValue: createdAt,
      isReadOnly: true,
    },
  ],
});

export const getEditInvoiceFormConfig = (
  currency,
  netAmount,
  createdAt,
  contractNumber,
  clientName,
  vat,
  branchId,
  paymentMethods,
  selectedInvoice
) => ({
  submitText: "Save",
  cancelText: "Cancel",
  formTitle: "Edit Invoice",
  fields: [
    {
      name: "reason",
      title: "Reason for Change",
      type: "string",
      viewConfig: "textarea",
      isRequired: true,
      validate: (value) =>
        value.trim().length > 0 ? null : "This field is required.",
    },
    {
      name: "number",
      title: "Invoice Number",
      type: "string",
      isRequired: true,
      // validate: (value) =>
      //   value.trim().length > 0 || "Invoice number is required",
      initialValue: selectedInvoice?.number,
    },
    {
      name: "methodId",
      title: "Payment Method",
      type: "select",
      options: paymentMethods.map((method) => ({
        value: method.id,
        label: `${method.name}, VAT=${method.vat}`,
      })),
      initialValue: selectedInvoice?.method?.id || "",
      isRequired: true,
      validate: (value) => !!value || "Payment method is required",
    },
    {
      name: "vatAmount",
      title: "VAT",
      type: "number",
      viewConfig: "input",
      inputMode: "decimal",
      startAdornment: "%",
      isReadOnly: true,
      countFunction: (data) => {
        if (!data.methodId) return vat || 0;
        const method = paymentMethods.find((m) => m.id === data.methodId);
        return method ? method.vat : vat || 0;
      },
    },
    {
      name: "amountWOVAT",
      title: "Amount without VAT",
      type: "number",
      viewConfig: "input",
      inputMode: "decimal",
      min: 1,
      step: 0.01,
      startAdornment: currency,
      isRequired: true,
      initialValue: netAmount,
    },
    {
      name: "grossAmount",
      title: "Amount with VAT",
      type: "number",
      viewConfig: "input",
      inputMode: "decimal",
      startAdornment: currency,
      isReadOnly: true,
      countFunction: (data) => {
        const net = Number(data.amountWOVAT || 0);
        const vatRate = Number(data.vatAmount || 0);
        return (net * (1 + vatRate / 100)).toFixed(2);
      },
    },

    {
      name: "invoiceDate",
      title: "Invoice Date",
      type: "date",
      isReadOnly: true,
      initialValue: createdAt,
    },
    {
      name: "contractNumber",
      title: "Contract Number",
      type: "string",
      isReadOnly: true,
      initialValue: contractNumber,
    },
    {
      name: "clientName",
      title: "Client Name",
      type: "string",
      isReadOnly: true,
      initialValue: clientName,
    },
  ],
});
