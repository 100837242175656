import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Fab, Dialog, Typography, Chip } from "@mui/material";
import { CalendarMonth, FilterList } from "@mui/icons-material";
import moment from "moment";

import useStores from "~/hooks";

import ScanningCard from "./components/cards/ScanningCard";
import Preloader from "../../components/Preloader";
import Form from "../../components/Form";
import Translator from "../../components/Translator";
import ShiftCard from "../scanners_working_shifts/components/cards/ShiftCard";

/**
 * Рабочий модуль сканировщика.
 */
const ScanningWorkspace = observer(() => {
  const {
    workingShiftsStore,
    branchStore,
    authStore,
    scanningPlansStore,
    employeesStore,
    equipmentStore,
    workspaceStore,
  } = useStores();
  const { branch } = branchStore;
  const { shift } = workingShiftsStore;

  const [pendingReason, setPendingReason] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const [withFinished, setWithFinished] = useState(true);
  const [withResult, setWithResult] = useState(true);
  const [from, setFrom] = useState(moment().startOf("day"));
  const [to, setTo] = useState(moment().endOf("day"));

  const employeeId = authStore.userId;

  const { theme } = workspaceStore;
  // Планы на сканирование
  const plans = scanningPlansStore.getPlansForEmployee;

  const isPending = useMemo(() => {
    return pendingReason !== "";
  }, [pendingReason]);

  // Грузится ли что из важного
  const isPendingOld = useMemo(() => {
    if (!branch) return true;
    // Стор scanningPlansStore.isPending на любой запрос будет пендить стор, да и все
    // остальные будут. Если мы кладём всю логику запросов в сторы то пендить надо в
    // компонентах!
    return scanningPlansStore.isPending || employeesStore.isPending || equipmentStore.isPending;
  }, [branch, scanningPlansStore.isPending, employeesStore.isPending, equipmentStore.isPending]);

  const onOpenModal = useCallback(() => setModalVisible(true), [setModalVisible]);

  const onCloseModal = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") return;
      setModalVisible(false);
    },
    [setModalVisible]
  );

  useEffect(() => {
    const effect = async () => {
      setPendingReason("shift");
      await workingShiftsStore.fetchOpenShift(branch);
      setPendingReason("");
    };
    if (branch) effect();
  }, [branch, equipmentStore, workingShiftsStore]);

  // Запросим сканирования при рендере. На старте запросим сканирования на сегодня.
  useLayoutEffect(() => {
    const effect = async () => {
      setPendingReason("plans");
      await scanningPlansStore.fetchFacilitiesWithScanningPlans(
        branch.id,
        employeeId,
        withFinished,
        withResult,
        from,
        to
      );
      setPendingReason("");
    };
    if (branch) effect();
  }, [employeeId, scanningPlansStore, branch, withFinished, withResult, from, to]);

  const onSubmit = useCallback(
    (data) => {
      if (data.withFinished) {
        setWithFinished(data.withFinished === "true");
      }
      if (data.withResult) {
        setWithResult(data.withResult === "true");
      }
      if (data.from) {
        setFrom(moment(data.from, "L"));
      }
      if (data.to) {
        setTo(moment(data.to, "L"));
      }
      onCloseModal();
    },
    [onCloseModal]
  );

  // Унести в компонент
  const filterScanningsFormConfig = useMemo(() => {
    const fields = [];

    fields.push({
      name: "from",
      title: "From",
      type: "date",
      isRequired: false,
      isReadOnly: false,
      validate: true,
      initialValue: from && from.toDate(),
    });
    fields.push({
      name: "to",
      title: "To",
      type: "date",
      isRequired: false,
      isReadOnly: false,
      validate: true,
      initialValue: to && to.toDate(),
    });

    fields.push({
      name: "withFinished",
      title: "Include finished scannings",
      type: "boolean",
      isRequired: false,
      isReadOnly: false,
      validate: true,
      initialValue: withFinished,
    });

    fields.push({
      name: "withResult",
      title: "Include scannings with result",
      type: "boolean",
      isRequired: false,
      isReadOnly: false,
      validate: true,
      initialValue: withResult,
    });

    return {
      formTitle: "Filters",
      formText: "Select to filter scannings.",
      cancelText: "Cancel",
      submitText: "Get scannings",
      fields,
    };
  }, [from, to, withFinished, withResult]);

  // для каждого сканирования покажем карточку
  const scannings = useMemo(() => {
    return plans.map((plan, index) => {
      return <ScanningCard key={plan.id} plan={plan} index={index} />;
    });
  }, [plans]);

  // есть ли планы в процессе сканирования?
  const isScanningInProgress = useMemo(() => plans.some((plan) => plan.isStarted & !plan.isFinished), [plans]);

  return (
    <Box sx={{ flexDirection: "column", display: "flex", flex: 1, padding: "0.5rem", overflow: "hidden" }}>
      <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", overflow: "hidden", p: "0.25rem 0" }}>
        <CalendarMonth color="primary" />
        <Typography variant="h6" sx={{ flex: 1, mt: 0.5, ml: 0.5, display: "flex", justifyContent: "center" }}>
          <Translator date={from} /> &mdash; <Translator date={to} />
        </Typography>
        <Chip label={scannings.length} sx={{ mr: "0.5rem" }} />
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          flex: 1,
          padding: "0",
          overflow: "hidden",
        }}
      >
        <Fab
          onClick={onOpenModal}
          key="fab"
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
          }}
          color="primary"
          aria-label="FilterList"
        >
          <FilterList />
        </Fab>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
            overflow: "auto",
            paddingBottom: "4rem",
            paddingTop: "0.5rem",
          }}
        >
          <ShiftCard
            shift={shift}
            isPending={pendingReason === "shift"}
            isScanningInProgress={isScanningInProgress}
            disabled={workingShiftsStore.errorText !== ""}
          />
          <Preloader isPending={pendingReason === "plans"} sx={{ mt: 5 }} />
          {workingShiftsStore.errorText !== "" && (
            <Box sx={{ p: 1, backgroundColor: theme.palette.red.main, mt: 1, border: 1, borderRadius: 1 }}>
              <Translator text="Configuration error. Please contact your administrator:" />
              <Typography variant="h5" sx={{ textAlign: "center", mt: 1 }}>
                <Translator text={workingShiftsStore.errorText} />
              </Typography>
            </Box>
          )}
          {pendingReason === "" && scannings.length === 0 && (
            <Typography variant="h5" sx={{ textAlign: "center", mt: 5, color: "#5b5b5b" }}>
              <Translator text={"There are no scanning plans on the selected dates."} />
            </Typography>
          )}
          {scannings}
        </Box>
        <Dialog open={modalVisible} onClose={onCloseModal}>
          <Form config={filterScanningsFormConfig} onSubmit={onSubmit} onCancel={onCloseModal} />
        </Dialog>
      </Box>
    </Box>
  );
});

export default ScanningWorkspace;
