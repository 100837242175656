import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import ModellingPlanGantLine from "../modelling/components/schedule/ModellingPlanGantLine";

/**
 * Блок архитектора-моделлера.
 */
const GantLine = observer(({ startOfPeriod, endOfPeriod, modeller, dayWidth, borderTop = true }) => {
  const { name: modellerName, modellingPlanFacilities } = modeller;

  const plans = useMemo(() => {
    const result = [];
    modellingPlanFacilities.forEach((facility) => {
      facility.modellingPlans.forEach((plan) => {
        // полоску все равно не нарисует, но строку добавит. Поэтому проверим.
        if (!(plan.finish <= startOfPeriod || plan.start >= endOfPeriod)) {
          result.push(
            <ModellingPlanGantLine
              key={`${facility.id}-${plan.id}`}
              startOfPeriod={startOfPeriod}
              endOfPeriod={endOfPeriod}
              plan={plan}
              facility={facility}
              dayWidth={dayWidth}
            />
          );
        }
      });
    });
    return result;
  }, [modellingPlanFacilities, startOfPeriod, endOfPeriod, dayWidth]);

  const days = useMemo(() => {
    const days = [];
    const start = startOfPeriod.clone().startOf("day");
    const end = endOfPeriod.clone().endOf("day");
    const cursor = start.clone().startOf("day").set("hour", 12);
    while (cursor <= end) {
      const isWeekend = cursor.isoWeekday() > 5;
      days.push(
        <Box
          key={cursor.clone().format("DD-MM")}
          sx={{
            flex: 1,
            flexShrink: 0,
            width: `${dayWidth}px`,
            minWidth: `${dayWidth}px`,
            maxWidth: `${dayWidth}px`,
            backgroundColor: (theme) => {
              if (plans.length === 0) return `${theme.palette.red.background}`;
              if (isWeekend) return `${theme.palette.grey[100]}`;
              return "none";
            },
            borderRight: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
            borderBottom: (theme) => {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            },
          }}
        ></Box>
      );
      cursor.add(1, "days");
    }
    return days;
  }, [startOfPeriod, endOfPeriod, dayWidth, plans]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flexShrink: 0,
        minHeight: "3rem",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          flexShrink: 0,
          p: "0.125rem 0.75rem",
          justifyContent: "flex-start",
          width: "12rem",
          borderTop: (theme) => {
            if (borderTop) {
              return `0.0625rem solid ${theme.palette.grey[300]}`;
            }
            return "none";
          },
          borderRight: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
          borderBottom: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
          borderLeft: (theme) => {
            return `0.0625rem solid ${theme.palette.grey[300]}`;
          },
          backgroundColor: (theme) => {
            return plans.length === 0 ? `${theme.palette.red.background}` : "";
          },
        }}
      >
        <Typography variant="body" sx={{ fontWeight: 600 }}>
          {modellerName}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexShrink: 0,
          width: `${days.length * dayWidth}px`,
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            zIndex: 1,
          }}
        >
          {days}
        </Box>
        <Box sx={{ flex: 1, zIndex: 2, overflow: "hidden" }}>{plans}</Box>
      </Box>
    </Box>
  );
});

export default GantLine;
