import { observer } from "mobx-react";

import { Box, Typography as T, Tooltip, Divider } from "@mui/material";
import FacilityIcon from "./FacilityIcon";

import PaymentIcon from "@mui/icons-material/Payment";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

import { Translator } from "~/components";
import ContractLink from "~/modules/contracts/components/buttons/ContractLink";
import ManagerWidget from "~/modules/employees/ManagerWidget";

/**
 * Карточка тултипа отображения информации об объекте.
 */
const FacilityInfoCard = observer(({ facility, buttons }) => {
  const { amount, currency } = facility.modellingBudget;
  return (
    <Box sx={{ padding: 0, borderRadius: 4, zIndex: 1000, width: "30rem", maxWidth: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          <T variant="h5" sx={{}}>
            {facility.branch?.icon}
          </T>
          <T variant="h5" sx={{ ml: 1, mb: 0.5 }}>
            {facility.branch?.name}
          </T>
        </Box>
        <ContractLink contractId={facility.contractInfo.id} contractNumber={facility.contractInfo.number} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <FacilityIcon kind={facility.kind} sx={{ mt: 0.25 }} />
          <T variant="h6" sx={{ ml: 1 }}>
            {facility.label}
          </T>
        </Box>
      </Box>
      <T variant="body" sx={{ ml: 1.75, mb: 0.5,  width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}>
        {facility.description}
      </T>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Tooltip arrow placement="bottom" title={"Area"}>
          <Box sx={{ display: "flex" }}>
            <SquareFootIcon sx={{ mt: 0.25 }} />
            <T variant="h6" sx={{ ml: 1 }}>
              {facility.area}
            </T>
          </Box>
        </Tooltip>
        <Tooltip arrow placement="bottom" title={"Modelling budget"}>
          <Box sx={{ display: "flex" }}>
            <PaymentIcon sx={{ mt: 0.45 }} />
            <T variant="h6" sx={{ ml: 1 }}>
              <Translator number={amount} currency={currency} />
            </T>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <ManagerWidget manager={facility.salesManager} />
        <ManagerWidget
          key={`modeling-manager-${facility.modellingManager?.id}`}
          manager={facility.modellingManager}
          role="modelling-manager"
        />
      </Box>
      <Tooltip arrow title={<Translator text={"Scanning date"} />}>
        <Box sx={{ flexDirection: "row", display: "flex", mt: "0.75rem" }}>
          <CameraswitchIcon color="info" sx={{ mr: 0.5 }} />
          <T sx={{ fontWeight: 500 }} variant="body">
            <Translator date={facility.scanDate} />
          </T>
        </Box>
      </Tooltip>
      {buttons && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
            mt: 1,
          }}
        >
          {buttons}
        </Box>
      )}
    </Box>
  );
});

export default FacilityInfoCard;
