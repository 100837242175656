import ApiBase from "~/api/ApiBase";

import { ConfigurationError } from "../errors";


/**
 * Scanner's branch working shifts.
 */
export default class WorkingShiftsApiV0 extends ApiBase {

  /**
   * Gets scanner's working shifts for Branch.
   *
   * @param {number} branchId
   * @returns JSON
   */
  async getBranchWorkingShifts(branchId, pagination, searchBy, daterange) {
    const token = this.authStore.token;

    const { page, perPage } = pagination;
    const params = { page, perPage, searchBy };

    if (!!daterange[0]) {
      params["from"] = daterange[0].format("YYYY-MM-DD");
    }

    if (!!daterange[1]) {
      params["to"] = daterange[1].format("YYYY-MM-DD");
    }

    const search = new URLSearchParams(params);

    const url = `../api/v0/branches/${branchId}/working-shifts/for-scanners?${search}`;

    const response = await fetch(url, { headers: { Authorization: token } });
    const totalCount = parseInt(response.headers.get("Total-Count"));

    return { shifts: await response.json(), totalCount };
  }

  /**
   * Download trip certificate for shift.
   *
   * @param {number} shiftId
   * @returns
   */
  async downloadTripCertificate(shiftId) {
    const token = this.authStore.token;
    const url = `/api/v0/working-shifts/${shiftId}/trip-certificate`;
    const response = await fetch(url, { headers: { Authorization: token } });
    return await response.blob();
  }

  /**
   * Загрузить фотографии
   */
  async uploadPhotos(files = []) {
    const uploadTasks = files.map((file) => this.uploadPhoto(file));
    const uploadResults = await Promise.all(uploadTasks);
    return uploadResults.map((x) => x.id);
  }

  /**
  * Загрузить одну фотографию
  */
  async uploadPhoto(file) {
    const [data] = await this.postFormData(`api/v0/photos`, { file });
    return data;
  }


  /**
  * Начать смену
  */
  async startShift(branchId, role, data) {
    const url = `/api/v0/branches/${branchId}/employees/${role}s/working-shifts`;
    const [result] = await this.post(url, data);
    return result;
  }


  /**
   * Закрыть смену
   */
  async finishShift(shiftId, data) {
    const url = `api/v0/working-shifts/${shiftId}/close.action`;
    const [result] = await this.post(url, data);
    return result;
  }

  /**
  * Get open shift for a role.
  */
  async getShiftForRole(branchId, employeeId, role) {
    // const token = this.authStore.token; // Authorization token
    const urlParams = new URLSearchParams();
    if (role) urlParams.set("role", role);

    const url = `/api/v0/branches/${branchId}/employees/${employeeId}/opened-working-shifts`;

    let result = [];
    try {
      [result] = await this.get(url, urlParams);
    } catch (err) {
      if (err.code === 404) throw new ConfigurationError(err.message);
    }
    return result.length > 0 ? result[0] : null; // Return the first shift if exists
  }
}
