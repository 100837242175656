import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { FileOpen, Image, PictureAsPdf } from "@mui/icons-material";
import { Box, ListItem, ListItemIcon, Tooltip, Typography } from "@mui/material";

import FileView from "./FileView";
import Translator from "./Translator";

const FileListItem = observer(({ file, noPreview, noDownload, type, noName }) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onShowPreview = useCallback(() => {
    if (!noPreview) {
      setIsPreviewVisible(true);
    }
  }, [setIsPreviewVisible, noPreview]);

  const icon = useMemo(() => {
    if (file && file.file) {
      if (file.file.mimetype && file.file.mimetype === "application/pdf") {
        return <PictureAsPdf color="info" />;
      } else if (file.file.mimetype && file.file.mimetype.split("/")[0] === "image") {
        return <Image color="info" />;
      }
    }
    return <FileOpen color="info" />;
  }, [file]);

  const onHidePreview = useCallback(
    (e) => {
      e.stopPropagation();
      setIsPreviewVisible(false);
    },
    [setIsPreviewVisible]
  );

  return (
    <ListItem sx={{ overflow: "hidden", cursor: "pointer" }} onClick={onShowPreview}>
      <ListItemIcon>{icon}</ListItemIcon>
      {!noName && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Tooltip arrow title={<Typography variant="body">{file.file.name}</Typography>}>
              <Typography
                sx={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden" }}
                variant="body"
                color="text.secondary"
              >
                {file.file.name}
              </Typography>
            </Tooltip>
          </Box>
          <Typography
            sx={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden" }}
            variant="body2"
            color="text.secondary"
          >
            <Translator datetime={file.createdAt} variant={"compact"} />
          </Typography>
        </Box>
      )}
      <FileView isPreviewVisible={isPreviewVisible} onHidePreview={onHidePreview} file={file} type={type} />
    </ListItem>
  );
});

export default FileListItem;
