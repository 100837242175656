import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

/**
 * Счёт на оплату.
 */
class Invoice {
  @observable store = null;
  @observable id = null;
  @observable number = "";
  @observable contractId = null;
  @observable amount = null;
  @observable createdAt = null;
  @observable currency = null;
  @observable pending = false;
  @observable valueWithoutVAT = null;

  @observable method = null;
  @observable payments = [];

  @observable _contractNumber = "";

  @observable clientInfo = {};
  @observable managerInfo = {};

  constructor(props, store) {
    makeObservable(this);

    this.store = store;
    this.id = `${props.id}`;
    this.contractId = `${props.contractId}`;
    this.amount = Number(props.value) || 0;
    this.valueWithoutVAT = Number(props.valueWithoutVAT) || 0;
    this.createdAt = moment(props.createdAt);
    this.currency = props.currency;
    this.number = props.number;

    this.methodId = `${props.methodId}`; // TODO: скастить method сюда
    this.method = store.getPaymentMethod(props.methodId);
    this.payments = props.payments.map((p) => this.addPayment(p));

    this.contractNumber = props.contractInfo?.number;

    this.clientInfo = props.clientInfo;
    this.managerInfo = props.managerInfo;
  }

  @action addPayment(paymentData) {
    const payment = this.store.addPayment(paymentData);
    this.payments.push(payment);
    return payment;
  }

  @action setPending(pending = false) {
    this.pending = pending;
  }

  @computed get isPending() {
    return this.pending;
  }

  @computed get value() {
    return this.id;
  }

  // @computed get method() {
  //   return (
  //     (this.store.rootStore.branchStore.currentBranch &&
  //       this.store.rootStore.branchStore.currentBranch.getPaymentMethodById(this.methodId)) || {
  //       label: "Unknown method",
  //     }
  //   );
  // }

  @computed get methodLabel() {
    return this.method?.label;
  }

  @computed get contract() {
    // if (this.store.rootStore.contractStore.isPending) {
    //   return {
    //     number: "...",
    //     client: {
    //       name: "...",
    //     },
    //   };
    // }
    return (
      this.store.root.contractStore.getContractById(this.contractId) || {
        number: "Unknown contract",
      }
    );
  }

  // @computed
  // get contractNumber() {
  //   return this.contract?.number || this._contractNumber;
  // }

  @computed
  get clientName() {
    return this.contract && this.contract.client && this.contract.client.name;
  }

  @computed
  get vatAmount() {
    return this.amount - this.valueWithoutVAT;
  }

  /** Сколько всего заплатили по счёту (всеми платежами) */
  @computed get payedAmount() {
    let amount = 0;
    this.paymentsArray.forEach((payment) => {
      amount += payment.amount;
    });
    return amount;
  }

  @computed
  get payedAmountWithoutVAT() {
    let amount = 0;
    this.paymentsArray.forEach((payment) => {
      amount += payment.valueWithoutVAT;
    });
    return amount;
  }

  /** Сколько VAT в счёте */
  @computed get valueVAT() {
    return this.payedAmount - this.payedAmountWithoutVAT;
  }

  @computed
  get toPayLeft() {
    return this.amount - this.payedAmount;
  }

  @computed get paymentsArray() {
    return this.payments.slice();
  }

  updateNestedObject(target, source) {
    for (const key of Object.keys(source)) {
      if (typeof source[key] === "object" && source[key] !== null) {
        if (!target[key]) {
          target[key] = {};
        }
        this.updateNestedObject(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  
  @action update(data) {
    this.number = data.number || this.number;
    this.amount = data.amount || this.amount;
    this.currency = data.currency || this.currency;
    this.createdAt = data.createdAt ? moment(data.createdAt) : this.createdAt;
    this.method = this.store.getPaymentMethod(data.methodId) || this.method;
    this.valueWithoutVAT = data.valueWithoutVAT || this.valueWithoutVAT;
  
    this.updateNestedObject(this.clientInfo, data.clientInfo || {});
    this.updateNestedObject(this.managerInfo, data.managerInfo || {});
  
    if (Array.isArray(data.payments)) {
      const newPayments = data.payments.map((p) => this.store.addPayment(p));
      this.payments = [...newPayments];
    }
  }
  
  
  

  @computed
  get chartArray() {
    const array = [];
    this.paymentIds.forEach((id) => {
      const payment = this.store.getPaymentById(id);
      if (payment) {
        array.push({
          id: payment.id,
          amount: payment.amount,
          currency: payment.currency,
          percent: (payment.amount / Math.max(this.amount, this.payedAmount)) * 100,
          type: "payment",
        });
      }
    });
    if (this.toPayLeft !== 0) {
      array.push({
        id: "odd-payment",
        currency: this.currency,
        amount: Math.abs(this.toPayLeft),
        percent: (Math.abs(this.toPayLeft) / Math.max(this.amount, this.payedAmount)) * 100,
        type: this.toPayLeft > 0 ? "credit" : "overpayed",
      });
    }
    return array;
  }

  @computed
  get managerName() {
    return this.contract.manager && this.contract.manager.fullName;
  }
}

export default Invoice;
