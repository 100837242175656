import React, { useState, useMemo, useCallback } from "react";
import { observer } from "mobx-react";

import { Module } from "~/components";
import Invoices from "./views/Invoices";
import Payments from "./views/Payments";
import SubmoduleSwitcher from "./components/InvoicesPaymentsViewSwitcher";
import ModuleToolbar from "./components/ModuleToolbar";

/**
 * Модуль счетов и платежей.
 */
const InvoicesPaymentsModule = observer(() => {
  const [view, setView] = useState("invoices");
  const [refreshPls, setRefreshPls] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [daterange, setDaterange] = useState([null, null]);

  const currentView = useMemo(() => {
    const invoices = (
      <Invoices searchBy={searchBy} states={selectedStates} daterange={daterange} refreshPls={refreshPls} />
    );
    switch (view) {
      case "invoices":
        return invoices;
      case "payments":
        return <Payments searchBy={searchBy} daterange={daterange} refreshPls={refreshPls} />;
      default:
        return invoices;
    }
  }, [view, searchBy, selectedStates, daterange, refreshPls]);

  return (
    <Module>
      <SubmoduleSwitcher view={view} setView={setView} />
      <ModuleToolbar
        view={view}
        selectedStates={selectedStates}
        setSelectedStates={setSelectedStates}
        setSearchBy={setSearchBy}
        setDaterange={setDaterange}
        setRefreshPls={setRefreshPls}
      />
      {currentView}
    </Module>
  );
});

export default InvoicesPaymentsModule;
