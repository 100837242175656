/**
 * Ошибка при попытке создания уже существующего клиента.
 */
class ClientAlreadyExistsError extends Error {
  constructor(code, clientName, errorMessage) {
    const message = `Client with name "${clientName}" already exists.`;
    super(message);
    this.code = code;
  }
}

export default ClientAlreadyExistsError;