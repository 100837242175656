import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";

import { FormDialog } from "~/components/dialogs";
import { Translator as T } from "~/components";
import Notify from "~/common/components/Notify";

import useStores from "~/hooks";

/**
 * Диалог добавления нового плана моделирования.
 */
const AddingModellingPlanDialog = observer(({ isOpen, onClose, branchId, facility }) => {
  const [errorText, setErrorText] = useState("");
  const [isNotifyOpen, setNotifyOpen] = useState(false);

  const { facilityStore, employeesStore } = useStores();
  const { facilitiesToPlanModelling, isPending } = facilityStore;
  const { renderersArray, isPending: isEmployeesPending } = employeesStore;

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await facilityStore.createModellingPlan(branchId, data);
        setNotifyOpen(true);
        onClose && onClose();
      } catch (error) {
        setErrorText(error.message || "Something went wrong!");
      }
    },
    [facilityStore, onClose, branchId]
  );

  const config = useMemo(() => {
    return {
      submitText: "Create the plan",
      cancelText: "Cancel",
      fields: [
        {
          name: "facilityId",
          fakeName: "facility",
          title: "Facility",
          type: "select",
          canCreate: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: isPending,
          options: facilitiesToPlanModelling,
          initialValue: facility?.id,
        },
        {
          name: "modellerId",
          fakeName: "modeller",
          title: "Modeller",
          type: "select",
          canCreate: false,
          isRequired: true,
          isReadOnly: false,
          validate: true,
          loading: isEmployeesPending,
          options: renderersArray,
        },
        {
          name: "dateRangePicker",
          title: "Date Range",
          type: "dateRangePicker",
          // isRequired: true, // TODO: @ai оно сломано, делает наоборот если включить!
          validate: (_, values) => {
            if (!values?.start || !values?.finish) {
              return "Both start and end dates are required.";
            }
            if (values.start > values.finish) {
              return "Start date cannot be after end date.";
            }
            return null;
          },
          helpText: "Select a date range for the modelling plan.",
        },
      ],
    };
  }, [facilitiesToPlanModelling, isPending, renderersArray, isEmployeesPending, facility?.id]);

  return (
    <>
      <FormDialog
        title={"Adding modelling plan"}
        formConfig={config}
        isVisible={isOpen}
        closeDialog={onClose}
        handleSubmit={handleSubmit}
        isPending={facilityStore.isPending}
        errorText={errorText}
      />
      <Notify isOpen={isNotifyOpen} onClose={() => setNotifyOpen(false)}>
        <T text="Modelling plan successfully created!" />
      </Notify>
    </>
  );
});

export default AddingModellingPlanDialog;
