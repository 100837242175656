import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Button, ButtonGroup } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { Translator as T } from "~/components";

/**
 * Переключатель между периодами.
 *
 * Работает на один вперёд и на один назад.
 */
const PeriodSwitcher = observer(({ daterange, now, period, setNow }) => {
  const [start, end] = daterange;

  const onNext = useCallback(() => {
    setNow(now.clone().add(1, period));
  }, [now, period, setNow]);

  const onPrev = useCallback(() => {
    setNow(now.clone().add(-1, period));
  }, [now, period, setNow]);

  return (
    <ButtonGroup disableElevation variant="contained" aria-label="outlined button group">
      <Button startIcon={<ChevronLeft />} onClick={onPrev} />
      <Button variant="outlined">
        <T date={start} />
        {" - "}
        <T date={end} />
      </Button>
      <Button endIcon={<ChevronRight />} onClick={onNext} />
    </ButtonGroup>
  );
});

export default PeriodSwitcher;
