import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import TableViewIcon from "@mui/icons-material/TableView";

import { Translator as T } from "~/components";

/**
 * Переключатель видов модуля моделирования.
 */
const ModellingViewsSwitcher = observer(({ view, setView }) => {
  const onViewChanged = useCallback(
    (event, newView) => {
      if (!newView) return;
      setView(newView);
    },
    [setView]
  );

  return (
    <ToggleButtonGroup
      fullWidth
      sx={{ margin: ".5rem 0 .5rem 0" }}
      value={view}
      exclusive
      onChange={onViewChanged}
      size="small"
      aria-label="text alignment"
    >
      <ToggleButton fullWidth value="schedule" aria-label="centered">
        <ViewTimelineIcon />
        <T text={"Schedule"} />
      </ToggleButton>
      {/* <ToggleButton fullWidth value="table" aria-label="left aligned">
        <TableViewIcon />
        <T text={"Table"} />
      </ToggleButton> */}
      <ToggleButton fullWidth value="report" aria-label="justified">
        <AssessmentIcon />
        <T text={"Report"} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

export default ModellingViewsSwitcher;
